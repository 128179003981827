import React from 'react'
import { css } from '@emotion/core'

type SelectProps = {
  label?: string
  width?: string
  data?: any
  name?: string
  value?: string
  headquarterId?: string
  onChange?: (e) => void
}

export const DepartmentsSelectField: React.FC<SelectProps> = ({
  label = '',
  width = '195px',
  name = '',
  data = [],
  value,
  headquarterId,
  onChange,
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle} htmlFor={label}>
        {label}
      </label>
      <select css={selectStyle} id={label} name={name} onChange={onChange} value={value}>
        <option key={0}>全て</option>
        {data
          .filter(departments => departments.headquarter_id === headquarterId)
          .map((item: any) => (
            <option key={item.department_id} value={item.department_id}>
              {item.alias_name}
            </option>
          ))}
      </select>
    </div>
  )
}

const wrapperStyle = css({
  width: '150px',
})

const labelStyle = css({
  width: '100%',
  fontSize: '12px',
})

const selectStyle = css({
  appearance: 'none',
  width: '100%',
  border: '1px solid #D9D9D9',
  borderRadius: '22px',
  backgroundColor: '#FFFFFF',
  color: '#151619',
  fontSize: '12px',
  margin: '4px 0 0',
  padding: '6px 14px',
  backgroundImage: `url(${require('@/static/images/arrow_down.svg')})`,
  backgroundPosition: 'right 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
})
