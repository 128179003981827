import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { BackButton } from '@/Components/Advanced/backButton'
import { Button } from '@/Components/Advanced/button'
import { ManagerCard } from '@/Components/Advanced/managerCard'
import { StatusCard } from '@/Components/Advanced/statusCard'
import { EvaluationRegisterCard } from '@/Components/Pages/Project/_EvaluationRegister/card'
import { CardTextAreaField } from '@/Components/Advanced/cardTextAreaField'
import { CardTextField } from '@/Components/Advanced/cardTextField'
import { CardSelectField } from '@/Components/Advanced/cardSelectField'
import ReactLoading from 'react-loading'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// apis
import { fetchProjectDetail, projectEdit } from '@/Apis/scopeProjectRecorder'

// contexts
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

// libs
import { accountLevel } from '@/libs/account-level'
import { totalPointCalculator, totalPointCompetitionBeforeCalculator, allMemberTotalPointCalculator, allTotalPointCalculator } from '@/libs/calculator-total-point'

const validationErrorInitial = {
  projectName: '',
  clientName: '',
  description: '',
  headquarterId: '',
  departmentId: '',
  evaluator: '',
  manager: '',
  evaluationIndex: '',
  inHouseCost: '',
  competition: '',
}

export const ProjectEvaluationRegister: React.FC<{}> = () => {
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationIndexs, masterInHouseCosts, masterEvaluationPoints, masterUsers } = useContext<any>(MasterContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validationError, setValidationError] = useState(validationErrorInitial)
  const [staffListValidationError] = useState('')
  const [project, setProject] = useState<any>([])
  const [projectMembers, setProjectMembers] = useState<any>([])
  const [projectName, setProjectName] = useState('')
  const [clientName, setClientName] = useState('')
  const [description, setDescription] = useState('')
  const [totalPoint, setTotalPoint] = useState('0')
  // プロジェクト責任者用
  const [evaluationIndex, setEvaluationIndex] = useState('')
  const [competition, setCompetition] = useState('0')
  const [competitionEdit, setCompetitionEdit] = useState('0')
  const [inHouseCost, setInHouseCost] = useState('0')
  const [isInHouseCost, setIsInHouseCost] = useState<boolean>(false)
  const [projectStatus, setProjectStatus] = useState('')
  const [evaluationStatus, setEvaluationStatus] = useState('')
  const [evaluationDate, setEvaluationDate] = useState<string>('')
  const [beginDate, setBeginDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(
    dayjs()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
  )

  const _changeRangeDate = (beginDate, endDate) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  const memberHandleChange = (e, index) => {
    const _projectMembers = [...projectMembers]

    if (e.target.name === 'headquarterId') {
      _projectMembers[index].headquarter_id = e.target.value
    }

    if (e.target.name === 'departmentId') {
      _projectMembers[index].department_id = e.target.value
    }

    if (e.target.name === 'positionId') {
      _projectMembers[index].position_id = e.target.value
    }

    if (e.target.name === 'userId') {
      _projectMembers[index].user_id = e.target.value
    }

    if (e.target.name === 'task_point') {
      _projectMembers[index].task_point = e.target.value
    }

    if (e.target.name === 'task_percent') {
      _projectMembers[index].task_percent = e.target.value
      _projectMembers[index].contribution_percent = 100 - parseFloat(e.target.value)
    }

    if (e.target.name === 'contribution_point') {
      _projectMembers[index].contribution_point = e.target.value
    }

    if (e.target.name === 'contribution_percent') {
      _projectMembers[index].contribution_percent = e.target.value
      _projectMembers[index].task_percent = 100 - parseFloat(e.target.value)
    }

    if (e.target.name === 'self_assessment_point') {
      _projectMembers[index].self_assessment_point = e.target.value
    }

    if (e.target.name === 'comment') {
      _projectMembers[index].comment = e.target.value
    }

    _projectMembers[index].total_point = totalPointCalculator(project, _projectMembers[index])
    _projectMembers[index].total_point_competition_before = totalPointCompetitionBeforeCalculator(_projectMembers[index])
    setProjectMembers(_projectMembers)
  }

  const setDate = date => {
    setEvaluationDate(dayjs(date).format('YYYY-MM-DD'))
  }

  const handleChange = e => {
    if (e.target.name === 'projectName') {
      setProjectName(e.target.value)
    }

    if (e.target.name === 'clientName') {
      setClientName(e.target.value)
    }

    if (e.target.name === 'description') {
      setDescription(e.target.value)
    }

    if (e.target.name === 'projectStatus') {
      setProjectStatus(e.target.value)
    }

    if (e.target.name === 'evaluationIndex') {
      setEvaluationIndex(e.target.value)
      const _projectEvaluationTarget = masterEvaluationIndexs.find(item => item.evaluation_index_id === e.target.value)
      // 自動的に倍率が変更される
      setCompetition(_projectEvaluationTarget.competition)
      // 倍率手動変更はリセット
      setCompetitionEdit('0')
      setIsInHouseCost(false)
      // 内製費ID
      if (e.target.value === '77a48080-b6da-d304-99a9-151564f97373') {
        setIsInHouseCost(true)
      }
    }

    if (e.target.name === 'competition') {
      // 倍率手動変更時
      setCompetitionEdit(e.target.value)
    }


    if (e.target.name === 'inHouseCost') {
      setInHouseCost(e.target.value)
    }
  }

  const goToProjectDetail = projectId => {
    history.push({
      pathname: `/project/evaluation/detail`,
      search: `?projectId=${projectId}`,
    })
  }

  const _fetchProjectDetail = async () => {
    await fetchProjectDetail(query.projectId).then(data => {
      // @ts-ignore
      setProject(data.data['projects'][0])
      // @ts-ignore
      setProjectMembers(data.data['project_members'])
      setIsLoading(false)
    })
  }

  const _validate = () => {
    let error = false
    const _validationError = { ...validationError }
    // プロジェクト名
    if (projectName === '') {
      _validationError.projectName = 'プロジェクト名を入力してください'
      error = true
    }
    if (projectName !== '') {
      _validationError.projectName = ''
    }

    // クライアント名
    if (clientName === '') {
      _validationError.clientName = 'クライアント名を入力してください'
      error = true
    }
    if (clientName !== '') {
      _validationError.clientName = ''
    }

    // プロジェクト概要
    if (description === '') {
      _validationError.description = 'プロジェクト概要を入力してください'
      error = true
    }
    if (description !== '') {
      _validationError.description = ''
    }

    // プロジェクト評価指標
    if (evaluationIndex === '') {
      _validationError.evaluationIndex = 'プロジェクト評価指標を入力してください'
      error = true
    }
    if (evaluationIndex !== '') {
      _validationError.evaluationIndex = ''
    }

    // プロジェクト評価指標(金額)
    if (evaluationIndex === '77a48080-b6da-d304-99a9-151564f97373') {
      if (inHouseCost === '' || inHouseCost === '0' || !inHouseCost.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
        _validationError.inHouseCost = '金額に正しい数値を入力してください'
        error = true
      }
      if (inHouseCost !== '' && inHouseCost !== '0' && inHouseCost.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
        _validationError.inHouseCost = ''
      }
    }

    // プロジェクト評価指標(倍率)
    if (competition === '' || !competition.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
      _validationError.competition = '倍率に正しい数値を入力してください'
      error = true
    }
    if (competition !== '' && competition.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
      _validationError.competition = ''
    }

    setValidationError(_validationError)
    return error
  }

  const _membersValidate = () => {
    let error = false
    const _validationError: string[] = []

    projectMembers.forEach((member, index) => {
      if (member.user_id === '' || member.position_id === '' || member.begin_date === '' || member.end_date === '') {
        _validationError[index] = 'スタッフ情報を正しく設定してください'
        error = true
      }
      if (member.user_id !== '' && member.position_id !== '' && member.begin_date !== '' && member.end_date !== '') {
        _validationError[index] = ''
      }
    })
    return error
  }

  //ユーザーIDからrateを取得
  const _accountLevel = userId => {
    if (masterUsers.length === 0) {
      return 1
    }
    const rate = masterUsers.find(user => user.user_id === userId)?.rate
    return accountLevel(rate ? rate : 1)
  }

  const _editProject = async () => {
    setIsLoading(true)
    if (_validate() || _membersValidate()) {
      alert('入力内容に誤りがあります')
      setIsLoading(false)
      return
    }

    await projectEdit(
      // @ts-ignore
      project?.project_id,
      // @ts-ignore
      projectName,
      // @ts-ignore
      clientName,
      // @ts-ignore
      description,
      // @ts-ignore
      beginDate,
      // @ts-ignore
      endDate,
      // @ts-ignore
      evaluationIndex,
      // @ts-ignore
      inHouseCost,
      // @ts-ignore
      competition,
      // @ts-ignore
      competitionEdit,
      evaluationDate,
      totalPoint,
      // @ts-ignore
      projectStatus,
      evaluationStatus,
      projectMembers,
    ).then(e => {
      // @ts-ignore
      goToProjectDetail(project?.project_id)
    })
  }

  useEffect(() => {
    let _totalPoint = 0
    let isNotProjectEvaluationRegister = false
    projectMembers.forEach(member => {
      if (!isNaN(parseFloat(member.total_point))) {
        _totalPoint += parseFloat(member.total_point)
      }
      // 未登録確認
      if (isNaN(parseFloat(member.total_point)) || member.total_point === 0) {
        isNotProjectEvaluationRegister = true
      }
    })
    if (!isNotProjectEvaluationRegister) {
      setEvaluationStatus('registered')
    }
    if (isNotProjectEvaluationRegister) {
      setEvaluationStatus('nonRegistered')
    }
    
    setTotalPoint(_totalPoint.toFixed(2))
  }, [projectMembers])

  useEffect(() => {
    // 入力した金額に合わせて倍率を変更する
    masterInHouseCosts.forEach(masterInHouseCost => {
      if (parseInt(inHouseCost) > parseInt(masterInHouseCost.begin_price)) {
        setCompetition(masterInHouseCost.competition)
      }
    })
  }, [inHouseCost])

  useEffect(() => {
    // ログイン済みか判定する
    if (project) {
      setProjectName(project?.project_name)
      setClientName(project?.client_name)
      setDescription(project?.description)
      setBeginDate(project?.begin_date)
      setEndDate(project?.end_date)
      setEvaluationIndex(project?.evaluation_index_id)
      setCompetition(project?.evaluation_index_competition)
      setCompetitionEdit(project?.evaluation_index_competition_edit)
      setProjectStatus(project?.status)
      setTotalPoint(project?.point)
      setEvaluationStatus(project?.evaluation_status)
      setEvaluationDate(project?.evaluation_date)
      if (project?.evaluation_index_id === '77a48080-b6da-d304-99a9-151564f97373') {
        setIsInHouseCost(true)
      }
      setInHouseCost(project?.evaluation_index_price)
    }
  }, [project])

  useEffect(() => {
    if (competition && projectMembers.length !== 0) {
      const _projectMembers = allMemberTotalPointCalculator(competitionEdit !== '0' ? competitionEdit : competition, projectMembers)
      const _totalPoint = allTotalPointCalculator(_projectMembers)
      setTotalPoint(_totalPoint)
      setProjectMembers(_projectMembers)
    }
  }, [competition])

  useEffect(() => {
    if (competitionEdit !== '0' && projectMembers.length !== 0) {
      const _projectMembers = allMemberTotalPointCalculator(competitionEdit, projectMembers)
      const _totalPoint = allTotalPointCalculator(_projectMembers)
      setTotalPoint(_totalPoint)
      setProjectMembers(_projectMembers)
    }
  }, [competitionEdit])

  useEffect(() => {
    setIsLoading(true)
    // ログイン済みか判定する
    if (auth) {
      // 3等級以下はリダイレクト
      if (auth.rate <= 3) {
        history.push({
          pathname: `/`,
        })
      }
      _fetchProjectDetail()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="project-evaluation" />
        <div css={containerStyle}>
          <PageTitle title={'プロジェクト評価'} />
          <div css={mainContentsStyle}>
            <div css={topButtonWrapperStyle}>
              <BackButton name={'前へ戻る'} onClick={() => goToProjectDetail(project?.project_id)} />
            </div>
            <div css={managerRowStyle}>
              <ManagerCard
                rate={projectMembers.length ? projectMembers[0].rate : 1}
                position={'評価者'}
                name={`${projectMembers.length ? projectMembers[0].last_name + ' ' + projectMembers[0].first_name : ''}`}
              />
              {projectMembers.length !== 0 && (
                <ManagerCard
                  rate={projectMembers[0].role === 'evaluatorAndManager' ? projectMembers[0].rate : projectMembers[1].rate}
                  position={'プロジェクト責任者'}
                  name={`${
                    projectMembers[0].role === 'evaluatorAndManager'
                      ? projectMembers[0].last_name + ' ' + projectMembers[0].first_name
                      : projectMembers[1].last_name + ' ' + projectMembers[1].first_name
                  }`}
                />
              )}
              <StatusCard status={project?.evaluation_status === 'registered' ? '完了' : '未登録 '} />
            </div>
            <EvaluationRegisterCard
              handleChange={handleChange}
              project={{
                project_id: project?.project_id,
                project_no: project?.project_no,
                projectName,
                clientName,
                description,
                projectStatus,
              }}
              isInHouseCost={isInHouseCost}
              evaluationIndex={evaluationIndex}
              competition={competitionEdit !== '0' ? competitionEdit : competition}
              inHouseCost={inHouseCost}
              beginDate={beginDate}
              endDate={endDate}
              evaluationDate={evaluationDate}
              setDate={date => setDate(date)}
              changeRangeDate={_changeRangeDate}
              validationError={validationError}
            />
            <div css={mainListContainerStyle}>
              <ul css={mainListHeaderStyle}>
                <li css={list1Style}>評価者名</li>
                <li css={list2Style}>役割</li>
                <li css={list3Style}>アサイン期間</li>
                <li css={list6Style}>自己評価</li>
                <li css={list4Style}>合計得点</li>
                <li css={list5Style}>コメント</li>
              </ul>
              <ul css={mainListContentsStyle}>
                {projectMembers.length !== 0 &&
                  projectMembers.map((member, index) => {
                    if (member.role !== 'other')
                      return (
                        <li key={index}>
                          <div css={[listItemStyle, list1Style]}>
                            <div css={listNameRowStyle}>
                              <img
                                css={accountIconStyle}
                                src={require(`@/static/images/account_icon_level${_accountLevel(member.user_id)}.svg`)}
                              />
                              {`${member.last_name} ${member.first_name}`}
                            </div>
                            <div css={listDepartmentRowStyle}>{`${member.headquarter_alias_name}　${member.department_alias_name}`}</div>
                          </div>
                          <div css={[listItemStyle, list2Style]}>{member.position_name}</div>
                          <div css={[listItemStyle, list3Style]}>{`${dayjs(member.begin_date).format('YYYY/MM/DD')}〜　${dayjs(
                            member.end_date,
                          ).format('YYYY/MM/DD')}　(${dayjs(member.end_date).diff(dayjs(member.begin_date), 'day')}日)`}</div>
                          <div css={[listItemStyle, list6Style]}>
                            <CardSelectField
                              label={''}
                              width={'100px'}
                              name={'self_assessment_point'}
                              onChange={e => memberHandleChange(e, index)}
                              value={member.self_assessment_point}
                              data={masterEvaluationPoints}
                            />
                          </div>
                          <div css={[listItemStyle, list4Style]}>
                            <CardTextField
                              label={''}
                              width={'100px'}
                              name={'total_point'}
                              onChange={e => memberHandleChange(e, index)}
                              value={member.total_point}
                            />
                          </div>
                          <div css={[listItemStyle, list5Style]}>
                            <CardTextAreaField
                              label={''}
                              value={member.comment}
                              name={'comment'}
                              onChange={e => memberHandleChange(e, index)}
                              validation={staffListValidationError}
                            />
                          </div>
                        </li>
                      )
                    return <></>
                  })}
              </ul>
            </div>
            <div css={mainListContainerStyle}>
              <ul css={mainListHeaderStyle}>
                <li css={list1Style}>スタッフ名</li>
                <li css={list2Style}>役割</li>
                <li css={list3Style}>アサイン期間</li>
                <li css={list6Style}>
                  個人タスク
                  <span>※ミッション設定</span>
                </li>
                <li css={list6Style}>
                  貢献度
                  <span>※ミッション設定</span>
                </li>
                <li css={list4Style}>合計得点</li>
                <li css={list5Style}>コメント</li>
              </ul>
              <ul css={mainListContentsStyle}>
                {projectMembers.length !== 0 &&
                  projectMembers.map((member, index) => {
                    if (member.role === 'other')
                      return (
                        <li key={index}>
                          <div css={[listItemStyle, list1Style]}>
                            <div css={listNameRowStyle}>
                              <img
                                css={accountIconStyle}
                                src={require(`@/static/images/account_icon_level${_accountLevel(member.user_id)}.svg`)}
                              />
                              {`${member.last_name} ${member.first_name}`}
                            </div>
                            <div css={listDepartmentRowStyle}>{`${member.headquarter_alias_name}　${member.department_alias_name}`}</div>
                          </div>
                          <div css={[listItemStyle, list2Style]}>{member.position_name}</div>
                          <div css={[listItemStyle, list3Style]}>{`${dayjs(member.begin_date).format('YYYY/MM/DD')}〜　${dayjs(
                            member.end_date,
                          ).format('YYYY/MM/DD')}　(${dayjs(member.end_date).diff(dayjs(member.begin_date), 'day')}日)`}</div>
                          <div css={[listItemStyle, list6Style]}>
                            <div css={listPointRegisterTopRowStyle}>
                              <CardSelectField
                                label={''}
                                width={'100px'}
                                name={'task_point'}
                                onChange={e => memberHandleChange(e, index)}
                                value={member.task_point}
                                data={masterEvaluationPoints}
                              />
                            </div>
                            <div css={listPointRegisterBottomRowStyle}>
                              <CardTextField
                                label={''}
                                width={'80px'}
                                name={'task_percent'}
                                onChange={e => memberHandleChange(e, index)}
                                value={member.task_percent}
                              />
                              <div css={listPointPercentStyle}>%</div>
                            </div>
                          </div>
                          <div css={[listItemStyle, list6Style]}>
                            <div css={listPointRegisterTopRowStyle}>
                              <CardSelectField
                                label={''}
                                width={'100px'}
                                name={'contribution_point'}
                                onChange={e => memberHandleChange(e, index)}
                                value={member.contribution_point}
                                data={masterEvaluationPoints}
                              />
                            </div>
                            <div css={listPointRegisterBottomRowStyle}>
                              <CardTextField
                                label={''}
                                width={'60px'}
                                name={'contribution_percent'}
                                onChange={e => memberHandleChange(e, index)}
                                value={member.contribution_percent}
                              />
                              <div css={listPointPercentStyle}>%</div>
                            </div>
                          </div>
                          <div css={[listItemStyle, list4Style]}>
                            <CardTextField
                              label={''}
                              name={'total_point'}
                              onChange={e => memberHandleChange(e, index)}
                              value={member.total_point}
                              width={'100px'}
                            />
                          </div>
                          <div css={[listItemStyle, list5Style]}>
                            <CardTextAreaField
                              label={''}
                              value={member.comment}
                              name={'comment'}
                              onChange={e => memberHandleChange(e, index)}
                              validation={staffListValidationError}
                            />
                          </div>
                        </li>
                      )
                    return <></>
                  })}
              </ul>
            </div>
            <div css={projectTotalPointWrapperStyle}>
              <div css={projectTotalPointStyle}>
                プロジェクト合計得点：<span>{totalPoint ? totalPoint : 0}</span>
              </div>
            </div>

            <div css={saveButtonWrapperStyle}>
              <Button label={'保存'} onClick={_editProject} />
            </div>
            {isLoading && (
              <div css={loadingOverlayStyle}>
                <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
  position: 'relative',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '24px 34px',
  backgroundColor: '#EDF2F9',
})

const topButtonWrapperStyle = css({
  marginBottom: '26px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const managerRowStyle = css({
  marginBottom: '54px',
  width: '582px',
  display: 'flex',
  justifyContent: 'space-between',
})

const mainListContainerStyle = css({
  display: 'grid',
  marginBottom: '50px',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  li: {
    padding: '20px 16px',
    borderBottom: '1px solid #DDDDDD',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    fontSize: '13px',
  },
})

const projectTotalPointWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '50px',
})

const projectTotalPointStyle = css({
  width: '364px',
  padding: '30px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#004CAD',
  span: {
    color: '#151619',
  },
})

const saveButtonWrapperStyle = css({
  margin: '0 0 80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const listNameRowStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const listDepartmentRowStyle = css({
  marginLeft: '38px',
  fontSize: '12px',
  display: 'flex',
  color: '#757575',
})

const list1Style = css({
  display: 'block',
  width: '300px',
})

const list2Style = css({
  width: '50px',
})

const list3Style = css({
  width: '150px',
  textAlign: 'center',
  whiteSpace: 'initial',
})

const list4Style = css({
  width: '80px',
})

const listPointRegisterTopRowStyle = css({
  display: 'flex',
})

const listPointRegisterBottomRowStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const listPointPercentStyle = css({
  marginLeft: '6px',
})

const list5Style = css({
  width: '300px',
  whiteSpace: 'initial',
})

const list6Style = css({
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100px',
  whiteSpace: 'initial',
  display: 'flex',
  span: {
    color: '#8E8E8F',
    fontSize: '10px',
    marginTop: '-5px',
  },
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})
