import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { SelectField } from '@/Components/Advanced/selectField'
import { SelectUsersField } from '@/Components/Advanced/selectUsersField'
import { CalenderField } from '@/Components/Advanced/calenderField'
import { RadioField } from '@/Components/Advanced/radioField'
import { SearchField } from '@/Components/Advanced/searchField'
import { ResetButton } from '@/Components/Advanced/resetButton'
import { RefineButton } from '@/Components/Advanced/refineButton'

import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

type Props = {
  projectsEvaluatorMembers?: any
  searchProjectName?: string
  searchEvaluationId?: string
  searchUserId?: string
  searchProjectStatus?: string
  searchEvaluationStatus?: string
  searchBeginDate: string
  searchEndDate: string
  searchEvaluationYear?: string
  searchEvaluationDate?: string
  targetDate?: string
  changeRangeDate: (beginDate: string, endDate: string) => void
  handleChange?: (e) => void
  handleEvaluationStatusChange?: (value) => void
  onSearch?: () => void
  onReset?: () => void
}

const targetDateOptions = [
  {
    id: 'evaluation_date',
    name: '評価対象期間',
  },
  {
    id: 'project_date',
    name: 'プロジェクト期間',
  },
]

export const ProjectEvaluationSearch: React.FC<Props> = ({
  projectsEvaluatorMembers,
  searchProjectName,
  searchEvaluationId,
  searchUserId,
  searchProjectStatus,
  searchEvaluationStatus,
  searchEvaluationYear,
  searchBeginDate,
  searchEndDate,
  searchEvaluationDate,
  targetDate,
  changeRangeDate,
  handleChange,
  handleEvaluationStatusChange,
  onSearch,
  onReset,
}) => {
  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationIndexs, masterProjectStatus, masterEvaluationYear, masterEvaluationDate2022, masterEvaluationDate2023, masterEvaluationDate2024 } = useContext<any>(MasterContext)
  let year;
  if (searchEvaluationYear == '2022') {
    year = masterEvaluationDate2022;
  } else if (searchEvaluationYear == '2023') {
    year = masterEvaluationDate2023;
  } else if (searchEvaluationYear == '2024') {
    year = masterEvaluationDate2024;
  }

  return (
    <div css={searchContainerStyle}>
      <div css={searchTitleStyle}>絞り込み</div>
      <div css={searchContentsStyle}>
        <div css={searchRowStyle}>
          <SelectUsersField
            label={'評価者'}
            width={'170px'}
            data={projectsEvaluatorMembers}
            onChange={handleChange}
            value={searchUserId}
            isExclusive={auth && auth.rate <= 5}
            exclusiveUserId={auth && auth.user_id}
          />
          <SelectField label={'対象期間'} data={targetDateOptions} name={'targetDate'} onChange={handleChange} value={targetDate} />
          {targetDate === 'evaluation_date' && (
            <>
              <SelectField
                label={'評価対象期間'}
                data={masterEvaluationYear}
                name={'evaluationYear'}
                onChange={handleChange}
                value={searchEvaluationYear}
                isAllSelect={false}
                isRequired={true}
                isEvaluationYear={true}
                width={'170px'}
              />
              <SelectField
                label={'　'}
                data={year}
                name={'evaluationDate'}
                onChange={handleChange}
                value={searchEvaluationDate}
                isAllSelect={false}
                isEvaluationDate={true}
              />
            </>
          )}
          {targetDate === 'project_date' && (
            <CalenderField
              label={'プロジェクト期間'}
              beginDate={searchBeginDate}
              endDate={searchEndDate}
              changeRangeDate={changeRangeDate}
            />
          )}
        </div>
        <div css={searchRowStyle}>
          <RadioField label={'評価登録'} name={'evaluationStatus'} onChange={handleEvaluationStatusChange} value={searchEvaluationStatus} />
          <SelectField
            label={'評価指標'}
            name={'evaluationId'}
            onChange={handleChange}
            data={masterEvaluationIndexs}
            idName={'evaluation_index_id'}
            value={searchEvaluationId}
            width={'200px'}
          />
          <SelectField
            label={'ステータス'}
            name={'projectStatus'}
            onChange={handleChange}
            value={searchProjectStatus}
            data={masterProjectStatus}
          />
        </div>
        <div css={searchRowStyle}>
          <SearchField label={'プロジェクト名検索'} name={'keyword'} onChange={handleChange} value={searchProjectName} />
          <ResetButton onReset={onReset} />
          <RefineButton onSearch={onSearch} />
        </div>
      </div>
    </div>
  )
}

const searchContainerStyle = css({
  display: 'flex',
  '.field': {
    marginRight: '20px',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

const searchTitleStyle = css({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#151619',
  marginRight: '25px',
  whiteSpace: 'nowrap',
})

const searchContentsStyle = css({
  width: '880px',
})

const searchRowStyle = css({
  display: 'flex',
  marginBottom: '23px',
  '&:last-child': {
    marginBottom: 0,
  },
})
