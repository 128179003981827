import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Signin } from '@/Components/Pages/Signin'
import { PasswordForget } from '@/Components/Pages/Signin/password-forget'
import { PasswordReset } from '@/Components/Pages/Signin/password-reset'
import { Maintenance } from '@/Components/Pages/Signin/maintenance'
import { Projects } from '@/Components/Pages/Project'
import { ProjectDetail } from '@/Components/Pages/Project/detail'
import { ProjectEdit } from '@/Components/Pages/Project/edit'
import { ProjectRegister } from '@/Components/Pages/Project/register'
import { ProjectRegisterStaff } from '@/Components/Pages/Project/registerStaff'
import { ProjectEvaluation } from '@/Components/Pages/Project/evaluation'
import { ProjectEvaluationDetail } from '@/Components/Pages/Project/evaluationDetail'
import { ProjectEvaluationEdit } from '@/Components/Pages/Project/evaluationEdit'
import { ProjectEvaluationRegister } from '@/Components/Pages/Project/evaluationRegister'
import { StaffEvaluation } from '@/Components/Pages/StaffEvaluation'
import { StaffEvaluationDetail } from '@/Components/Pages/StaffEvaluation/detail'
import { StaffEvaluationDownload } from '@/Components/Pages/StaffEvaluation/download'
import { FinalEvaluation } from '@/Components/Pages/FinalEvaluation'
import { Accounts } from '@/Components/Pages/Admin/account'
import { AccountDetail } from '@/Components/Pages/Admin/accountDetail'
import { MasterUsers } from '@/Components/Pages/Admin/masterUsers'
import { MasterHeadquarters } from '@/Components/Pages/Admin/masterHeadquarters'
import { MasterDepartments } from '@/Components/Pages/Admin/masterDepartments'
import { MasterPositions } from '@/Components/Pages/Admin/masterPositions'
import { MasterInHouseCosts } from '@/Components/Pages/Admin/masterInHouseCosts'
import { MasterEvaluationIndexs } from '@/Components/Pages/Admin/masterEvaluationIndexs'
import { MasterRoleSheets } from '@/Components/Pages/Admin/masterRoleSheets'

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        $
        {Number(process.env['REACT_APP_MAINTENANCE_FLAG']) == 0 ? (
          <>
            <Route exact path="/" component={Signin} />
            <Route exact path="/password-forget" component={PasswordForget} />
            <Route exact path="/password-reset" component={PasswordReset} />
            <Route exact path="/project" component={Projects} />
            <Route exact path="/project/detail" component={ProjectDetail} />
            <Route exact path="/project/edit" component={ProjectEdit} />
            <Route exact path="/project/register" component={ProjectRegister} />
            <Route exact path="/project/register/staff" component={ProjectRegisterStaff} />
            <Route exact path="/project/evaluation" component={ProjectEvaluation} />
            <Route exact path="/project/evaluation/detail" component={ProjectEvaluationDetail} />
            <Route exact path="/project/evaluation/edit" component={ProjectEvaluationEdit} />
            <Route exact path="/project/evaluation/register" component={ProjectEvaluationRegister} />
            <Route exact path="/staff-evaluation" component={StaffEvaluation} />
            <Route exact path="/staff-evaluation/detail" component={StaffEvaluationDetail} />
            <Route exact path="/staff-evaluation/download" component={StaffEvaluationDownload} />
            <Route exact path="/final-evaluation" component={FinalEvaluation} />
            <Route exact path="/admin/master-users" component={MasterUsers} />
            <Route exact path="/admin/account" component={Accounts} />
            <Route exact path="/admin/account/detail" component={AccountDetail} />
            <Route exact path="/admin/master-headquarters" component={MasterHeadquarters} />
            <Route exact path="/admin/master-departments" component={MasterDepartments} />
            <Route exact path="/admin/master-evaluation-indexs" component={MasterEvaluationIndexs} />
            <Route exact path="/admin/master-in-house-costs" component={MasterInHouseCosts} />
            <Route exact path="/admin/master-positions" component={MasterPositions} />
            <Route exact path="/admin/master-role-sheets" component={MasterRoleSheets} />
          </>
        ) : (
          <Route exact path="/*" component={Maintenance} />
        )}
      </Switch>
    </BrowserRouter>
  )
}
