import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { UploadButton } from '@/Components/Advanced/uploadButton'
import { DownloadButton } from '@/Components/Advanced/downloadButton'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { MasterDepartmentList } from '@/Components/Pages/Admin/_MasterDepartments/list'
import ReactLoading from 'react-loading'

// apis
import { fetchMasterDepartments, updateMasterDepartments } from '@/Apis/scopeProjectRecorder'

// libs
import { csvDownload } from '@/libs/csv-download'

// components
import { AuthContext } from '@/Contexts/auth'

export const MasterDepartments: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const history = useHistory()
  const [masterDepartments, setfetchMasterDepartments] = useState<any>([])
  const [masterDepartmentsForCSV, setfetchMasterDepartmentsForCSV] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _fetchMasterDepartments = async () => {
    await fetchMasterDepartments().then(data => {
      // @ts-ignore
      setfetchMasterDepartments(data.data.master)
      // @ts-ignore
      setListTotalCount(data.data.total)
      setIsLoading(false)
    })
  }

  const _onFileLoad = data => {
    setIsLoading(true)
    if (data) {
      updateMasterDepartments(data).then(res => {
        // @ts-ignore
        if (!res) {
          alert('CSVアップロードに失敗しました。')
          setIsLoading(false)
          return
        }

        // @ts-ignore
        if (res.success) {
          _fetchMasterDepartments()
          alert('CSVアップロード完了しました。')
          return
        } else {
          // @ts-ignore
          alert(res.message)
          setIsLoading(false)
          return
        }
      })
    }
  }

  const _csvDownload = () => {
    csvDownload(masterDepartmentsForCSV, 'master-departments.csv')
  }

  useEffect(() => {
    if (masterDepartments) {
      const csvHeader = ['システムID', '本部cd', '本部名称', '本部通称', '部・室cd', '部・室名称', '部・室通称', '所属コード', '並び順']
      const csvData = masterDepartments.map((masterDepartment: any) => {
        return [
          masterDepartment.department_id,
          masterDepartment.headquarter_cd,
          masterDepartment.headquarter_name,
          masterDepartment.headquarter_alias_name,
          masterDepartment.cd,
          masterDepartment.name,
          masterDepartment.alias_name,
          masterDepartment.belong_code,
          masterDepartment.order,
        ]
      })
      setfetchMasterDepartmentsForCSV([csvHeader, ...csvData])
    }
  }, [masterDepartments])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      setIsLoading(true)
      _fetchMasterDepartments()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="admin" />
        <div css={containerStyle}>
          <PageTitle title={'部署マスタ一覧'} />
          <div css={mainTopContentsStyle}>
            <div />
            <div css={searchSideButtonWrapperStyle}>
              <UploadButton onFileLoad={_onFileLoad} />
              <DownloadButton onClick={_csvDownload} />
            </div>
          </div>
          <div css={mainContentsStyle}>
            <ListPageCount pageCount={listTotalCount} />
            <MasterDepartmentList masterDepartments={masterDepartments} />
          </div>
        </div>
        {isLoading && (
          <div css={loadingOverlayStyle}>
            <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
          </div>
        )}
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const searchSideButtonWrapperStyle = css({
  display: 'flex',
  '.field': {
    marginRight: '10px',
  },
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
