import React from 'react'
import { css } from '@emotion/core'

type SelectUsersProps = {
  label?: string
  width?: string
  data?: any
  value?: string
  isExclusive?: boolean
  exclusiveUserId?: string
  onChange?: (e) => void
}

export const SelectUsersField: React.FC<SelectUsersProps> = ({
  label = '',
  width = '195px',
  data = [],
  value,
  isExclusive = false,
  exclusiveUserId = '',
  onChange,
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      {label && (
        <label css={labelStyle} htmlFor={label}>
          {label}
        </label>
      )}
      <select css={selectStyle} id={label} name={'userId'} onChange={onChange} value={value}>
        <option value={''} disabled={isExclusive}>
          全て
        </option>
        {data.map((user: any) => (
          <option key={user.user_id} value={user.user_id} disabled={isExclusive && user.user_id !== exclusiveUserId}>
            {user.last_name} {user.first_name}
          </option>
        ))}
      </select>
    </div>
  )
}

const wrapperStyle = css({
  width: '150px',
})

const labelStyle = css({
  width: '100%',
  fontSize: '12px',
})

const selectStyle = css({
  appearance: 'none',
  width: '100%',
  border: '1px solid #D9D9D9',
  borderRadius: '22px',
  backgroundColor: '#FFFFFF',
  color: '#151619',
  fontSize: '12px',
  margin: '4px 0 0',
  padding: '6px 14px',
  backgroundImage: `url(${require('@/static/images/arrow_down.svg')})`,
  backgroundPosition: 'right 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
})
