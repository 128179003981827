import React, { useEffect, useState, useContext } from 'react'
import { env } from '@/libs/env'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { StaffEvaluationSearchDownload } from '@/Components/Pages/StaffEvaluation/_StaffEvaluationDownload/search'
import ReactLoading from 'react-loading'

// apis
import { fetchStaffEvaluationsDownload } from '@/Apis/scopeProjectRecorder'

// libs
import { rankingTotalPointByRate } from '@/libs/calculator-evaluation-rate'

// components
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

export const StaffEvaluationDownload: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationDate } = useContext<any>(MasterContext)
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const [searchHeadquarterId, setSearchHeadquarterId] = useState<string>('')
  const [searchDepartmentId, setSearchDepartmentId] = useState<string>('')
  const [searchRate, setSearchRate] = useState<string>('')
  const [staffEvaluations, setStaffEvaluations] = useState<any>([])
  const [searchEvaluationYear, setSearchEvaluationYear] = useState<string>('')
  const [searchEvaluationDate, setSearchEvaluationDate] = useState<string>(
    query.searchEvaluationDate ? query.searchEvaluationDate : `${searchEvaluationYear ? searchEvaluationYear : '2022'}FirstHalf`,
  )
  const _masterEvaluationDate = masterEvaluationDate.find((_item, index) => _item.id === searchEvaluationDate)
  const [evaluationBeginDate, setEvaluationBeginDate] = useState<string>(_masterEvaluationDate.begin_date)
  const [evaluationEndDate, setEvaluationEndDate] = useState<string>(_masterEvaluationDate.end_date)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sortKey, setSortKey] = useState<string>('')
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC')

  const _sort = () => {
    const _staffEvaluations = [...staffEvaluations]
    _staffEvaluations.sort((a, b) => {
      if (isNaN(a[sortKey])) {
        if (sortType === 'ASC') {
          return a[sortKey] > b[sortKey] ? 1 : -1
        } else {
          return a[sortKey] < b[sortKey] ? 1 : -1
        }
      } else {
        if (sortType === 'ASC') {
          return a[sortKey] - b[sortKey]
        } else {
          return b[sortKey] - a[sortKey]
        }
      }
    })
    setStaffEvaluations(_staffEvaluations)
  }

  const handleChange = e => {
    if (e.target.name === 'headquarterId') {
      setSearchHeadquarterId(e.target.value)
    }

    if (e.target.name === 'departmentId') {
      setSearchDepartmentId(e.target.value)
    }

    if (e.target.name === 'rate') {
      setSearchRate(e.target.value)
    }

    if (e.target.name === 'evaluationYear') {
      setSearchEvaluationYear(e.target.value)
    }

    if (e.target.name === 'evaluationDate') {
      setSearchEvaluationDate(e.target.value)
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === e.target.value)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }

  const _onSearch = () => {
    setIsLoading(true)

    // 検索したらソートはリセットされる
    setSortKey('')
    setSortType('ASC')

    // スタッフ評価再取得
    _fetchStaffEvaluationsDownload()
    history.replace({
      pathname: `/staff-evaluation/download`,
      search: `?searchEvaluationYear=${searchEvaluationYear}&searchEvaluationDate=${searchEvaluationDate}`,
    })
  }

  const _onReset = () => {
    // 本部
    setSearchHeadquarterId('')
    if (auth) {
      if (auth.rate === 6) {
        setSearchHeadquarterId(auth.headquarter_id)
      }
    }
    // 部署
    setSearchDepartmentId('')
    // 等級
    setSearchRate('')
    // 評価年月日
    // 評価年月日(年度)
    setSearchEvaluationYear('')
    // 評価年月日(期間)
    setSearchEvaluationDate('2022FirstHalf')
    // 期間を選択した際の開始日時
    setEvaluationBeginDate('')
    // 期間を選択した際の終了日時
    setEvaluationEndDate('')
    // データのリセット
    setStaffEvaluations([])
  }

  const _fetchStaffEvaluationsDownload = async () => {
    // 評価計算用のデータを取得
    await fetchStaffEvaluationsDownload(
      '',
      '',
      '',
      searchEvaluationYear,
      searchEvaluationDate,
      evaluationBeginDate,
      evaluationEndDate,
      // 絞り込み検索をしたスタッフ一覧
    ).then(async _staffEvaluations => {
      // 評価データの計算
      // @ts-ignore
      const _staffEvaluationsRankingTotalPoint = rankingTotalPointByRate(_staffEvaluations.data.staffEvaluations)
      await fetchStaffEvaluationsDownload(
        searchHeadquarterId,
        searchDepartmentId,
        searchRate,
        searchEvaluationYear,
        searchEvaluationDate,
        evaluationBeginDate,
        evaluationEndDate,
      ).then(data => {
        // @ts-ignore
        const _staffEvaluations = data.data.staffEvaluations
        // @ts-ignore
        _staffEvaluations.forEach((staffEvaluation, index) => {
          // 絞り込み検索したデータに評価データをマッピング
          _staffEvaluations[index] = _staffEvaluationsRankingTotalPoint.find(item => item.user_id === staffEvaluation.user_id)
        })
        // @ts-ignore
        setStaffEvaluations(_staffEvaluations)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      })
    })
  }

  useEffect(() => {
    if (sortKey) {
      _sort()
    }
  }, [sortKey, sortType])

  useEffect(() => {
    const _searchEvaluationYear = searchEvaluationYear ? searchEvaluationYear : '2022'
    setSearchEvaluationDate(`${_searchEvaluationYear}FirstHalf`)
    if (masterEvaluationDate) {
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === `${_searchEvaluationYear}FirstHalf`)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }, [searchEvaluationYear])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      if (auth.rate === 6) {
        setSearchHeadquarterId(auth.headquarter_id)
      }
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="admin" />
        <div css={containerStyle}>
          <PageTitle title={'スタッフ評価一覧ダウンロード'} />
          <div css={mainTopContentsStyle}>
            <StaffEvaluationSearchDownload
              searchHeadquarterId={searchHeadquarterId}
              searchDepartmentId={searchDepartmentId}
              searchRate={searchRate}
              searchEvaluationYear={searchEvaluationYear}
              searchEvaluationDate={searchEvaluationDate}
              handleChange={handleChange}
              onSearch={_onSearch}
              onReset={_onReset}
              url={`${env('BASE_API')}/api/staff-evaluations/download?evaluation_begin_date=${evaluationBeginDate}&evaluation_end_date=${evaluationEndDate}`}
            />
          </div>
            {/* <a
              href={`${env('BASE_API')}/api/staff-evaluations/download?evaluation_begin_date=${evaluationBeginDate}&evaluation_end_date=${evaluationEndDate}`}
            >
              test
            </a> */}
          {isLoading && (
            <div css={loadingOverlayStyle}>
              <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
  position: 'relative',
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: 1000,
})
