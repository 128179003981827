import React, { useState } from 'react'
import { css } from '@emotion/core'
import { DateRangePicker, CalendarDay } from 'react-dates'
import moment from 'moment'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

import 'moment/locale/ja'

type CardCalenderProps = {
  label?: string
  width?: string
  beginDate?: string
  endDate?: string
  minDate?: string
  maxDate?: string
  validation?: string
  changeRangeDate?: (beginDate?, endDate?) => void
}

export const CardCalenderField: React.FC<CardCalenderProps> = ({
  label = '',
  width = '340px',
  beginDate,
  endDate,
  minDate,
  maxDate,
  validation,
  changeRangeDate,
}) => {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null)
  const _onClose = ({ endDate }) => {
    // 始点のみ選択後、離脱した場合はリセットする
    if (endDate === null) {
      // @ts-ignore
      changeRangeDate('', '')
    }
  }

  const _onChange = event => {
    // 始点選択で、日付変更を行った際は常に終点をリセットする
    if (focusedInput === 'startDate') {
      // @ts-ignore
      changeRangeDate(event.startDate.format('YYYY-MM-DD'), '')
      return
    }
    if (event.startDate && event.endDate) {
      // @ts-ignore
      changeRangeDate(event.startDate.format('YYYY-MM-DD'), event.endDate.format('YYYY-MM-DD'))
    }
  }

  const _onFocusChange = focusedInput => {
    setFocusedInput(focusedInput)
  }
  
  return (
    <div css={[validation ? wrapperErrorStyle : wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle}>{label}</label>
      <DateRangePicker
        displayFormat={'YYYY年MM月DD日'}
        startDate={beginDate ? moment(beginDate) : null}
        startDateId="startDate"
        startDatePlaceholderText={'年月日'}
        endDate={endDate ? moment(endDate) : null}
        endDateId="endDate"
        endDatePlaceholderText={'年月日'}
        focusedInput={focusedInput}
        onDatesChange={_onChange}
        onFocusChange={_onFocusChange}
        onClose={_onClose}
        isOutsideRange={date => minDate && maxDate ? date.isBefore(moment(minDate), 'day') || date.isAfter(moment(maxDate), 'day') : false}
        customArrowIcon={
          <span
            style={{
              color: beginDate !== null ? '#272727' : '#cccccc',
            }}
          >
            ~
          </span>
        }
        numberOfMonths={1}
        minimumNights={0}
        monthFormat={'YYYY年MM月'}
        hideKeyboardShortcutsPanel={true}
        firstDayOfWeek={1}
        daySize={42}
        customInputIcon={<img alt="calendar" src={require('@/static/images/calender.svg')} width={20} />}
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day.format('D')}</span>
              </div>
            )}
          />
        )}
      />
    </div>
  )
}

const wrapperStyle = css({
  width: '340px',
  '.DateRangePickerInput__withBorder': {
    borderRadius: '6px',
    backgroundColor: '#EDF2F9',
    padding: '14px 22px',
    border: '0px solid #FFFFFF',
  },
})

const wrapperErrorStyle = css(wrapperStyle, {
  '.DateRangePickerInput__withBorder': {
    border: '1px solid #FF0000',
  },
})

const labelStyle = css({
  width: '100%',
  fontSize: '12px',
  fontWeight: 'bold',
  margin: '0 0 6px 0',
  display: 'block',
  color: '#004CAD',
})
