import React, { useContext } from 'react'
import { css } from '@emotion/core'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// components
import { AuthContext } from '@/Contexts/auth'

// libs
import { accountLevel } from '@/libs/account-level'

type Props = {
  projectMembers?: any
}

export const EvaluationDetailList: React.FC<Props> = ({ projectMembers }) => {
  const { auth } = useContext<any>(AuthContext)
  const _visible = user_id => {
    // "メンバー権限（1/2/3等級）のアカウントの場合、他アサインメンバーの評価・コメントが表示されないようにする
    if (auth) {
      if (auth.rate === 0 || auth.rate === 1 || auth.rate === 2 || auth.rate === 3) {
        if (user_id !== auth.user_id) {
          return false
        }
      }
      return true
    }
    // デフォルトは非表示 (authがない場合は非表示)
    return false
  }

  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  return (
    <>
      <div css={mainListContainerStyle}>
        <ul css={mainListHeaderStyle}>
          <li css={list1Style}>評価者名</li>
          <li css={list2Style}>役割</li>
          <li css={list3Style}>アサイン期間</li>
          <li css={list4Style}>自己評価</li>
          <li css={list4Style}>合計得点</li>
          <li css={list5Style}>コメント</li>
        </ul>
        <ul css={mainListContentsStyle}>
          {projectMembers.length !== 0 &&
            projectMembers.map((member, index) => {
              if (member.role !== 'other')
                return (
                  <li css={member.self_assessment_point === '0' || member.total_point === '0' ? listNonRegisterStyle : ''} key={index}>
                    <div css={[listItemStyle, list1Style]}>
                      <div css={listNameRowStyle}>
                        <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(member.rate)}.svg`)} />
                        {`${member.last_name} ${member.first_name}`}
                      </div>
                      <div css={listDepartmentRowStyle}>{`${member.headquarter_alias_name}　${member.department_alias_name}`}</div>
                    </div>
                    <div css={[listItemStyle, list2Style]}>{member.position_name}</div>
                    <div css={[listItemStyle, list3Style]}>{`${dayjs(member.begin_date).format('YYYY/MM/DD')}〜　${dayjs(
                      member.end_date,
                    ).format('YYYY/MM/DD')}`}</div>
                    <div css={[listItemStyle, list4Style]}>
                      {_visible(member.user_id) ? (member.self_assessment_point === '0' ? '未登録' : member.self_assessment_point) : '-'}
                    </div>
                    <div css={[listItemStyle, list4Style]}>
                      {_visible(member.user_id) ? (member.total_point === '0' ? '未登録' : member.total_point) : '-'}
                    </div>
                    <div css={[listItemStyle, list5Style]}>{_visible(member.user_id) ? member.comment : '-'}</div>
                  </li>
                )
              return (<></>)
          })}
        </ul>
      </div>
      <div css={mainListContainerStyle}>
        <ul css={mainListHeaderStyle}>
          <li css={list1Style}>スタッフ名</li>
          <li css={list2Style}>役割</li>
          <li css={list3Style}>アサイン期間</li>
          <li css={list4Style}>個人タスク</li>
          <li css={list4Style}>貢献度</li>
          <li css={list4Style}>合計得点</li>
          <li css={list4Style}>ミッション設定</li>
          <li css={list5Style}>コメント</li>
        </ul>
        <ul css={mainListContentsStyle}>
          {projectMembers.length !== 0 &&
            projectMembers.map((member, index) => {
              if (member.role === 'other')
                return (
                  <li
                    css={
                      member.task_point === '0' || member.contribution_point === '0' || member.total_point === '0'
                        ? listNonRegisterStyle
                        : ''
                    }
                    key={index}
                  >
                    <div css={[listItemStyle, list1Style]}>
                      <div css={listNameRowStyle}>
                        <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(member.rate)}.svg`)} />
                        {`${member.last_name} ${member.first_name}`}
                      </div>
                      <div css={listDepartmentRowStyle}>{`${member.headquarter_alias_name}　${member.department_alias_name}`}</div>
                    </div>
                    <div css={[listItemStyle, list2Style]}>{member.position_name}</div>
                    <div css={[listItemStyle, list3Style]}>{`${dayjs(member.begin_date).format('YYYY/MM/DD')}〜　${dayjs(
                      member.end_date,
                    ).format('YYYY/MM/DD')}`}</div>
                    <div css={[listItemStyle, list4Style]}>
                      {_visible(member.user_id) ? (member.task_point === '0' ? '未登録' : member.task_point) : '-'}
                    </div>
                    <div css={[listItemStyle, list4Style]}>
                      {_visible(member.user_id) ? (member.contribution_point === '0' ? '未登録' : member.contribution_point) : '-'}
                    </div>
                    <div css={[listItemStyle, list4Style]}>
                      {_visible(member.user_id) ? (member.total_point === '0' ? '未登録' : member.total_point) : '-'}
                    </div>
                    <div css={[listItemStyle, list4Style]}>
                      {_visible(member.user_id) ? `${member.task_percent}% / ${member.contribution_percent}%` : '-'}
                    </div>
                    <div css={[listItemStyle, list5Style]}>{_visible(member.user_id) ? member.comment : '-'}</div>
                  </li>
                )
              return (<></>)
            })}
        </ul>
      </div>
    </>
  )
}

const mainListContainerStyle = css({
  display: 'grid',
  marginBottom: '50px',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  li: {
    padding: '20px 16px',
    borderBottom: '1px solid #DDDDDD',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    fontSize: '13px',
  },
})

const listNonRegisterStyle = css({
  backgroundColor: '#FEF2F1',
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const listNameRowStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const listDepartmentRowStyle = css({
  marginLeft: '38px',
  fontSize: '12px',
  display: 'flex',
  color: '#757575',
})

const list1Style = css({
  display: 'block',
  width: '300px',
})

const list2Style = css({
  width: '50px',
})

const list3Style = css({
  width: '150px',
  textAlign: 'center',
  whiteSpace: 'initial',
})

const list4Style = css({
  width: '100px',
})

const list5Style = css({
  width: '300px',
  whiteSpace: 'initial',
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})
