import React from 'react'
import { css } from '@emotion/core'

type Props = {
  masterEvaluationIndexs?: any
}

export const MasterEvaluationIndexsList: React.FC<Props> = ({ masterEvaluationIndexs }) => {
  const _competition = (competition) => {
    const _competition = parseInt(competition)
    return _competition.toFixed(2)
  }
  return (
    <div css={mainListContainerStyle}>
      <ul css={mainListHeaderStyle}>
        <li css={list1Style}>評価指標</li>
        <li css={list2Style}>倍率</li>
      </ul>
      <ul css={mainListContentsStyle}>
        {masterEvaluationIndexs.map((masterEvaluationIndex: any) => (
          <li key={masterEvaluationIndex.evaluation_index_id}>
            <div css={[listItemStyle, list1Style]}>
              <div css={overflowText}>{masterEvaluationIndex.name}</div>
            </div>
            <div css={[listItemStyle, list2Style]}>
              <div css={overflowText}>{_competition(masterEvaluationIndex.competition)}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const mainListContainerStyle = css({
  display: 'grid',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  height: '480px',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  height: '420px',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  li: {
    padding: '20px 16px',
    borderBottom: '1px solid #DDDDDD',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const overflowText = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const list1Style = css({
  width: '40%',
})

const list2Style = css({
  width: '40%',
})
