import React from 'react'
import { css } from '@emotion/core'

type ButtonProps = {
  label?: string
  onClick?: () => void
}

export const TransitionButton: React.FC<ButtonProps> = ({ label, onClick }) => {
  return (
    <>
      <button css={[buttonStyle]} onClick={onClick}>
        {label}
      </button>
    </>
  )
}

const buttonStyle = css({
  border: 'none',
  outoline: 'none',
  appearance: 'none',
  whiteSpace: 'nowrap',
  padding: '9px 13px 9px 13px',
  color: '#FFFFFF',
  borderRadius: '100px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundColor: '#004CAD',
  '&:hover': {
    opacity: 0.8,
  },
})
