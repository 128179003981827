import React from 'react'
import { css } from '@emotion/core'

type SelectProps = {
  label?: string
  width?: string
  name?: string
  value?: string
  onChange?: (value) => void
}

export const RadioField: React.FC<SelectProps> = ({ label = '', width = '230px', name = '', value = '', onChange = () => {} }) => {
  const _onClick = (value: string = 'all') => {
    onChange(value)
  }

  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle} htmlFor={label}>
        {label}
      </label>
      <div css={rowStyle} className={'field'}>
        <div onClick={() => _onClick('all')} css={value === 'all' ? radioActiveStyle : radioStyle}>
          すべて
        </div>
        <div onClick={() => _onClick('nonRegistered')} css={value === 'nonRegistered' ? radioActiveStyle : radioStyle}>
          未登録
        </div>
        <div onClick={() => _onClick('registered')} css={value === 'registered' ? radioActiveStyle : radioStyle}>
          完了
        </div>
      </div>
    </div>
  )
}

const wrapperStyle = css({
  width: '230px',
  display: 'block',
})

const rowStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const labelStyle = css({
  width: '100%',
  fontSize: '12px',
  margin: '0 0 6px 0',
  display: 'block',
})

const radioStyle = css({
  textAlign: 'center',
  minWidth: '70px',
  cursor: 'pointer',
  appearance: 'none',
  border: '1px solid #004CAD',
  borderRadius: '30px',
  color: '#004CAD',
  fontSize: '12px',
  padding: '2px 14px',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#004CAD',
    color: '#FFFFFF',
  },
})

const radioActiveStyle = css(radioStyle, {
  backgroundColor: '#004CAD',
  color: '#FFFFFF',
})
