import React from 'react'
import { css } from '@emotion/core'

type SearchFieldProps = {
  label?: string
  width?: string
  name?: string
  placeholder?: string
  value?: string
  onChange?: (e) => void
}

export const SearchField: React.FC<SearchFieldProps> = ({
  label = '',
  placeholder = 'プロジェクト名を入力',
  width = '550px',
  name = '',
  value = '',
  onChange,
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle} htmlFor={name}>
        {label}
      </label>
      <input type={'text'} id={name} name={name} css={inputStyle} placeholder={placeholder} onChange={onChange} value={value} />
    </div>
  )
}

const wrapperStyle = css({})

const labelStyle = css({
  width: '100%',
  fontSize: '12px',
})

const inputStyle = css({
  width: '100%',
  border: '1px solid #D6D6D6',
  borderRadius: '22px',
  backgroundColor: '#FFFFFF',
  color: '#151619',
  fontSize: '12px',
  margin: '6px 0 0',
  padding: '6px 20px 6px 40px',
  backgroundImage: `url(${require('@/static/images/search.svg')})`,
  backgroundPosition: 'left 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
  '&::placeholder': {
    color: '#B1B1B1',
  },
})
