import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import ReactLoading from 'react-loading'

// apis
import { passwordEdit } from '@/Apis/scopeProjectRecorder'

export const PasswordReset: React.FC<{}> = () => {
  const query = require('query-string').parse(window.location.search)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoginError, setIsLoginError] = useState(false)
  const history = useHistory()

  const handleChange = e => {
    if (e.target.name === 'password') {
      setPassword(e.target.value)
    }

    if (e.target.name === 'passwordConfirm') {
      setPasswordConfirm(e.target.value)
    }
  }

  const _passwordReset = async () => {
    setIsLoading(true)
    if (password !== passwordConfirm || password === '' || passwordConfirm === '' || password.length < 8) {
      setIsLoginError(true)
      setIsLoading(false)
      alert('入力データが不正です。')
      return false
    }
    await passwordEdit(query.userId, password).then(data => {
      setIsLoading(false)
      // @ts-ignore
      if (data && data.success) {
        alert('パスワード再設定完了しました。')
        goToSignin()
      } else {
        setIsLoginError(true)
      }
    })
  }

  const goToSignin = () => {
    history.push({
      pathname: `/`,
    })
  }

  return (
    <>
      <Header isLogin={false} />
      <div css={mainWrapperStyle}>
        <div css={loginContainerStyle}>
          <h2>パスワード再設定</h2>
          <div css={borderStyle} />
          <div css={loginGuideStyle}>再設定したいパスワードをご入力して下さい。</div>
          <div css={inputWrapperStyle}>
            <label css={inputLabelStyle} htmlFor="password">
              パスワード
            </label>
            <input
              type={'password'}
              id={'password'}
              name={'password'}
              css={isLoginError ? inputErrorStyle : inputStyle}
              placeholder={'半角英数字8文字以上'}
              onChange={handleChange}
            />
          </div>
          <div css={inputWrapperStyle}>
            <label css={inputLabelStyle} htmlFor="passwordConfirm">
              パスワード(確認)
            </label>
            <input
              type={'password'}
              id={'passwordConfirm'}
              name={'passwordConfirm'}
              css={isLoginError ? inputErrorStyle : inputStyle}
              placeholder={'半角英数字8文字以上'}
              onChange={handleChange}
            />
          </div>
          {isLoginError && <div css={loginErrorTextStyle}>入力データが不正です。</div>}
          <div css={buttonWrapperStyle}>
            <button onClick={_passwordReset} css={isLoading ? disabledLoginButton : loginButton}>
              パスワードを再設定する
            </button>
          </div>
          <div css={passwordResetLinkStyle} onClick={() => goToSignin()}>
            ログイン画面へ
          </div>
        </div>
      </div>
      {isLoading && (
        <div css={loadingOverlayStyle}>
          <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
        </div>
      )}
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#004CAD',
  display: 'flex',
  alignItems: 'center',
})

const borderStyle = css({
  margin: '15px 0 30px',
  borderBottom: '1px solid #D9D9D9',
  width: '100%',
})

const loginContainerStyle = css({
  margin: '0 auto',
  padding: '50px',
  width: '500px',
  borderRadius: '15px',
  backgroundColor: '#FFFFFF',
  color: '#3e3e3e',
  h2: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
})

const loginGuideStyle = css({
  fontSize: '13px',
})

const inputWrapperStyle = css({
  margin: '30px 0 0',
})

const inputLabelStyle = css({
  width: '100%',
  fontSize: '14px',
})

const inputStyle = css({
  width: '100%',
  border: '1px solid #D9D9D9',
  borderRadius: '6px',
  backgroundColor: '#F9F9F9',
  color: '#151619',
  fontSize: '16px',
  margin: '10px 0 0',
  padding: '12px 20px',
  '&::placeholder': {
    color: '#C4C4C4',
  },
})

const inputErrorStyle = css(inputStyle, {
  border: '1px solid rgb(255, 39, 0)',
})

const loginErrorTextStyle = css({
  color: 'rgb(255, 39, 0)',
  fontSize: '12px',
  fontWeight: 'bold',
})

const buttonWrapperStyle = css({
  margin: '50px 0 0',
})

const loginButton = css({
  border: 'none',
  padding: '14px 0',
  borderRadius: '24px',
  width: '100%',
  backgroundColor: '#004CAD',
  color: '#FFFFFF',
  display: 'block',
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})

const disabledLoginButton = css(loginButton, {
  backgroundColor: '#ccc',
  '&:hover': {
    opacity: 1,
  },
})

const passwordResetLinkStyle = css({
  margin: '20px 0 0',
  textAlign: 'center',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
