import React from 'react'
import { css } from '@emotion/core'

type Props = {
  onClick?: () => void
}

export const DownloadButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <button css={buttonStyle} onClick={onClick}>
        データのダウンロード
      </button>
    </div>
  )
}

const wrapperStyle = css({})

const buttonStyle = css({
  appearance: 'none',
  whiteSpace: 'nowrap',
  border: 'none',
  padding: '9px 13px 9px 38px',
  color: '#FFFFFF',
  backgroundColor: '#EBAE17',
  borderRadius: '22px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundImage: `url(${require('@/static/images/download.svg')})`,
  backgroundPosition: 'left 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
  '&:hover': {
    opacity: 0.8,
  },
})
