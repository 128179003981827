import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'

// components
import { AuthContext } from '@/Contexts/auth'

export const MasterRoleSheets: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const history = useHistory()

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />

      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="admin" />
        <div css={containerStyle}>
          <PageTitle title={'権限一覧（権限表）'} />
          <div css={mainContentsStyle}>
            <div css={rolesHeaderStyle}>
              <ul css={rolesStyle}>
                <li>
                  <div css={roleNameStyle}>メンバー</div>
                  <div css={roleRatetyle}>１・２・３等級</div>
                </li>
                <li>
                  <div css={roleNameStyle}>マネージャ</div>
                  <div css={roleRatetyle}>４・５等級</div>
                </li>
                <li>
                  <div css={roleNameStyle}>管理者</div>
                  <div css={roleRatetyle}>６・７・８等級</div>
                </li>
              </ul>
            </div>
            <ul css={roleMenuStyle}>
              <li>
                <div css={roleMenuLeftStyle}>
                  <div css={menuStyle}>①プロジェクト一覧</div>
                  <div css={menuGuideStyle}>プロジェクトの詳細を閲覧する</div>
                </div>
                <div css={roleMenuRightStyle}>
                  <div css={itemStyle}>〇</div>
                  <div css={itemStyle}>〇</div>
                  <div css={itemStyle}>〇</div>
                </div>
              </li>
              <li>
                <div css={roleMenuLeftStyle}>
                  <div css={menuStyle}>②プロジェクト登録</div>
                  <div css={menuGuideStyle}>プロジェクトの登録・編集をする</div>
                </div>
                <div css={roleMenuRightStyle}>
                  <div css={itemStyle}>×</div>
                  <div css={itemStyle}>〇</div>
                  <div css={itemStyle}>〇</div>
                </div>
              </li>
              <li>
                <div css={roleMenuLeftStyle}>
                  <div css={menuStyle}>③プロジェクト評価登録</div>
                  <div css={menuGuideStyle}>プロジェクトごとに評価を登録する</div>
                </div>
                <div css={roleMenuRightStyle}>
                  <div css={itemStyle}>×</div>
                  <div css={itemStyle}>〇</div>
                  <div css={itemStyle}>〇</div>
                </div>
              </li>
              <li>
                <div css={roleMenuLeftStyle}>
                  <div css={menuStyle}>④スタッフ評価一覧</div>
                  <div css={menuGuideStyle}>スタッフごとに評価集計が閲覧できる</div>
                </div>
                <div css={roleMenuRightStyle}>
                  <div css={itemStyle}>
                    〇<div>※自分のみ閲覧ができる</div>
                  </div>
                  <div css={itemStyle}>
                    〇<div>※自分のみ閲覧ができる</div>
                  </div>
                  <div css={itemStyle}>
                    〇<div>※６等級は所属本部のみ閲覧</div>
                  </div>
                </div>
              </li>
              <li>
                <div css={roleMenuLeftStyle}>
                  <div css={menuStyle}>⑤最終評価</div>
                  <div css={menuGuideStyle}>スタッフの評価を調整できる</div>
                </div>
                <div css={roleMenuRightStyle}>
                  <div css={itemStyle}>×</div>
                  <div css={itemStyle}>×</div>
                  <div css={itemStyle}>
                    〇<div>※６等級は所属本部のみ閲覧</div>
                  </div>
                </div>
              </li>
              <li>
                <div css={roleMenuLeftStyle}>
                  <div css={menuStyle}>⑥管理者メニュー</div>
                  <div css={menuGuideStyle}>マスタやテーブルの変更ができる</div>
                </div>
                <div css={roleMenuRightStyle}>
                  <div css={itemStyle}>×</div>
                  <div css={itemStyle}>×</div>
                  <div css={itemStyle}>〇</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const rolesHeaderStyle = css({
  width: '900px',
  display: 'flex',
  justifyContent: 'flex-end',
})

const rolesStyle = css({
  width: '600px',
  display: 'flex',
  li: {
    background: '#ffffff',
    width: '190px',
    padding: '20px',
    margin: '0 20px 20px 0',
    textAlign: 'center',
    ':last-child': {
      marginRight: 0,
    },
  },
})

const roleNameStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#004CAD',
})

const roleRatetyle = css({
  fontSize: '12px',
  color: '#888',
})

const roleMenuStyle = css({
  width: '900px',
  li: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    marginBottom: '20px',
    padding: '20px 0px 20px 20px',
  },
})

const roleMenuLeftStyle = css({
  width: '280px',
})

const roleMenuRightStyle = css({
  width: '600px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const itemStyle = css({
  width: '190px',
  fontSize: '24px',
  textAlign: 'center',
  color: '#333',
  div: {
    marginTop: '4px',
    marginBottom: '-4px',
    fontSize: '10px',
    color: '#888',
  },
})

const menuStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#004CAD',
  marginBottom: '6px',
})

const menuGuideStyle = css({
  fontSize: '12px',
  color: '#888',
})
