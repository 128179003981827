import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { SelectField } from '@/Components/Advanced/selectField'

// components
import { MasterContext } from '@/Contexts/master'

// libs
import { accountLevel } from '@/libs/account-level'

type Props = {
  staffEvaluations?: any
  sortKey: string
  sortType: 'ASC' | 'DESC'
  handleLastEvaluationChange: (e, user_id) => void
  sort: (key: string) => void
}

export const StaffEvaluationList: React.FC<Props> = ({ staffEvaluations, sortKey, sortType, handleLastEvaluationChange, sort }) => {
  const { masterFinalEvaluationIndexs } = useContext<any>(MasterContext)

  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  return (
    <div css={mainListContainerStyle}>
      <ul css={mainListHeaderStyle}>
        <li css={list1Style} onClick={() => sort('headquarter_name')}>
          本部
          {sortKey === 'headquarter_name' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list2Style} onClick={() => sort('department_name')}>
          部署
          {sortKey === 'department_name' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list3Style} onClick={() => sort('rate')}>
          等級
          {sortKey === 'rate' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list4Style} onClick={() => sort('last_name')}>
          スタッフ名
          {sortKey === 'last_name' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list6Style} onClick={() => sort('project_count')}>
          プロジェクト数
          {sortKey === 'project_count' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list5Style} onClick={() => sort('task_point_total')}>
          個人タスク合計
          {sortKey === 'task_point_total' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list5Style} onClick={() => sort('task_point_total_avg')}>
          個人タスク平均
          {sortKey === 'task_point_total_avg' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list8Style} onClick={() => sort('contribution_point_total')}>
          貢献度合計
          {sortKey === 'contribution_point_total' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list9Style} onClick={() => sort('contribution_point_total_avg')}>
          貢献度平均
          {sortKey === 'contribution_point_total_avg' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list10Style} onClick={() => sort('total_point')}>
          合計点数
          {sortKey === 'total_point' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list11Style} onClick={() => sort('total_point_avg')}>
          合計平均
          {sortKey === 'total_point_avg' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list12Style} onClick={() => sort('averagebeforeMagnificationAddition')}>
          倍率加算前平均
          {sortKey === 'averagebeforeMagnificationAddition' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list13Style} onClick={() => sort('averagebeforeMagnificationAdditionPj')}>
          <>
            倍率加算前平均
            <br />
            ×評価済みPJ数
          </>
          {sortKey === 'averagebeforeMagnificationAdditionPj' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list14Style} onClick={() => sort('rankingEvaluationEquivalentGrade')}>
          <>
            順位評価
            <br />
            （同等級）
          </>
          {sortKey === 'rankingEvaluationEquivalentGrade' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list15Style} onClick={() => sort('rankingEvaluationBeforeAndAfterGrade')}>
          <>
            順位評価
            <br />
            （前後等級）
          </>
          {sortKey === 'rankingEvaluationBeforeAndAfterGrade' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list16Style} onClick={() => sort('deviationValueEvaluationEquivalentGrade')}>
          <>
            偏差値評価
            <br />
            （同等級）
          </>
          {sortKey === 'deviationValueEvaluationEquivalentGrade' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list17Style} onClick={() => sort('deviationValueEvaluationBeforeAndAfterGrade')}>
          <>
            偏差値評価
            <br />
            （前後等級）
          </>
          {sortKey === 'deviationValueEvaluationBeforeAndAfterGrade' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list18Style}>最終評価</li>
      </ul>
      <ul css={mainListContentsStyle}>
        {staffEvaluations.map((staffEvaluation, index) => (
          <li key={staffEvaluation.user_id}>
            <div css={[listItemStyle, list1Style]}>
              <div css={overflowText}>{staffEvaluation.headquarter_name}</div>
            </div>
            <div css={[listItemStyle, list2Style]}>
              <div css={overflowText}>{staffEvaluation.department_name}</div>
            </div>
            <div css={[listItemStyle, list3Style]}>
              <div css={overflowText}>{staffEvaluation.rate}</div>
            </div>
            <div css={[listItemStyle, list4Style]}>
              <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(staffEvaluation.rate)}.svg`)} />
              {staffEvaluation.last_name} {staffEvaluation.first_name}
            </div>
            <div css={[listItemStyle, list6Style]}>
              <div>{staffEvaluation.project_count}</div>
              <div css={list6SubtextStyle}>
                評価済み {staffEvaluation.project_count_registered}
                <br />
                <span>未登録 {staffEvaluation.project_count_nonRegistered}</span>
              </div>
            </div>
            <div css={[listItemStyle, list5Style]}>{staffEvaluation.task_point_total_csv}</div>
            <div css={[listItemStyle, list5Style]}>{staffEvaluation.task_point_total_avg_csv}</div>
            <div css={[listItemStyle, list8Style]}>{staffEvaluation.contribution_point_total_csv}</div>
            <div css={[listItemStyle, list9Style]}>{staffEvaluation.contribution_point_total_avg_csv}</div>
            <div css={[listItemStyle, list10Style]}>{staffEvaluation.total_point_csv}</div>
            <div css={[listItemStyle, list11Style]}>{staffEvaluation.total_point_avg_csv}</div>
            <div css={[listItemStyle, list12Style]}>{staffEvaluation.averagebeforeMagnificationAddition}</div>
            <div css={[listItemStyle, list13Style]}>{staffEvaluation.averagebeforeMagnificationAdditionPj}</div>
            <div css={[listItemStyle, list14Style]}>{staffEvaluation.rankingEvaluationEquivalentGrade}</div>
            <div css={[listItemStyle, list15Style]}>{staffEvaluation.rankingEvaluationBeforeAndAfterGrade}</div>
            <div css={[listItemStyle, list16Style]}>{staffEvaluation.deviationValueEvaluationEquivalentGradePoint}</div>
            <div css={[listItemStyle, list17Style]}>{staffEvaluation.deviationValueEvaluationBeforeAndAfterGradePoint}</div>
            <div css={[listItemStyle, list18Style]}>
              {staffEvaluation.isEvaluation ? (
                staffEvaluation.evaluation_point
              ) : (
                <SelectField
                  width="100%"
                  label={''}
                  data={masterFinalEvaluationIndexs}
                  name={'evaluation'}
                  isAllSelect={false}
                  onChange={e => handleLastEvaluationChange(e, staffEvaluation.user_id)}
                  value={staffEvaluation.evaluation_point}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const mainListContainerStyle = css({
  display: 'grid',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'visible',
  overflowX: 'scroll',
  height: '480px',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  // alignItems: 'center',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '0 16px 0 0',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  position: 'sticky',
  top: '0',
  left: '0',
  zIndex: 99,
  li: {
    padding: '20px 20px 20px 0',
    whiteSpace: 'nowrap',
    fontSize: '13px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    '&:nth-child(1)': {
      position: 'sticky',
      left: '0',
      backgroundColor: '#E6E6E6',
      paddingLeft: '16px',
    },
    '&:nth-child(2)': {
      position: 'sticky',
      left: '100px',
      backgroundColor: '#E6E6E6',
    },
    '&:nth-child(3)': {
      position: 'sticky',
      left: '280px',
      backgroundColor: '#E6E6E6',
    },
    '&:nth-child(4)': {
      position: 'sticky',
      left: '360px',
      backgroundColor: '#E6E6E6',
      borderRight: '1px solid #CCCCCC',
      paddingRight: '0',
      marginRight: '20px',
    },
    img: {
      margin: '6px 0 0 0',
      width: '28px',
    },
  },
})

const mainListContentsStyle = css({
  width: '100%',
  height: '420px',
  //overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  li: {
    padding: '0 16px 0 0',
    borderBottom: '1px solid #DDDDDD',
    display: 'flex',
    whiteSpace: 'nowrap',
    // alignItems: 'center',
    fontSize: '13px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      '> div': {
        backgroundColor: '#EEEEEE !important',
      },
    },
    '> div': {
      padding: '20px 0',
      '&:nth-child(1)': {
        position: 'sticky',
        left: '0',
        backgroundColor: '#FFFFFF',
        paddingLeft: '16px',
      },
      '&:nth-child(2)': {
        position: 'sticky',
        left: '100px',
        backgroundColor: '#FFFFFF',
      },
      '&:nth-child(3)': {
        position: 'sticky',
        left: '280px',
        backgroundColor: '#FFFFFF',
      },
      '&:nth-child(4)': {
        position: 'sticky',
        left: '360px',
        backgroundColor: '#FFFFFF',
        borderRight: '1px solid #CCCCCC',
        paddingRight: '0',
        marginRight: '20px',
      },
    },
  },
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const overflowText = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const list1Style = css({
  width: '100px',
})

const list2Style = css({
  width: '180px',
})

const list3Style = css({
  width: '80px',
})

const list4Style = css({
  width: '150px',
})

const list5Style = css({
  width: '120px',
})

const list6Style = css({
  width: '120px',
  display: 'flex',
  alignItems: 'center',
  span: {
    color: '#F3362D',
  },
})

const list6SubtextStyle = css({
  marginLeft: '12px',
  fontSize: '10px',
})

const list8Style = css({
  width: '100px',
})

const list9Style = css({
  width: '100px',
})

const list10Style = css({
  width: '100px',
})

const list11Style = css({
  width: '100px',
})

const list12Style = css({
  width: '120px',
})

const list13Style = css({
  width: '120px',
})

const list14Style = css({
  width: '100px',
})

const list15Style = css({
  width: '100px',
})

const list16Style = css({
  width: '100px',
})

const list17Style = css({
  width: '100px',
})

const list18Style = css({
  width: '100px',
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})
