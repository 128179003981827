import React from 'react'
import { css } from '@emotion/core'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// libs
import { accountLevel } from '@/libs/account-level'

type Props = {
  projectMembers?: any
}

export const ProjectDetailList: React.FC<Props> = ({ projectMembers }) => {
  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  return (
    <div css={mainListContainerStyle}>
      <ul css={mainListHeaderStyle}>
        <li css={list2Style}>本部</li>
        <li css={list3Style}>部署</li>
        <li css={list1Style}>スタッフ名</li>
        <li css={list4Style}>役割</li>
        <li css={list5Style}>アサイン期間</li>
      </ul>
      <ul css={mainListContentsStyle}>
        {projectMembers.length !== 0 &&
          projectMembers.map((member, index) => (
            <li key={index}>
              <div css={[listItemStyle, list2Style]}>{member.headquarter_name}</div>
              <div css={[listItemStyle, list3Style]}>{member.department_name}</div>
              <div css={[listItemStyle, list1Style]}>
                <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(member.rate)}.svg`)} />
                {`${member.last_name} ${member.first_name}`}
              </div>
              <div css={[listItemStyle, list4Style]}>{member.position_name}</div>
              <div css={[listItemStyle, list5Style]}>{`${dayjs(member.begin_date).format('YYYY/MM/DD')}〜${dayjs(member.end_date).format(
                'YYYY/MM/DD',
              )}`}</div>
            </li>
          ))}
      </ul>
    </div>
  )
}

const mainListContainerStyle = css({
  display: 'grid',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  li: {
    padding: '20px 16px',
    borderBottom: '1px solid #DDDDDD',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    fontSize: '13px',
  },
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const list1Style = css({
  width: '250px',
})

const list2Style = css({
  width: '250px',
})

const list3Style = css({
  width: '250px',
})

const list4Style = css({
  width: '150px',
})

const list5Style = css({
  width: '300px',
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})
