import React from 'react'
import { css } from '@emotion/core'

type CompareButtonProps = {
  onClick?: () => void
}

export const CompareButton: React.FC<CompareButtonProps> = ({ onClick }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <button onClick={onClick} css={buttonStyle}>
        最終評価登録
      </button>
    </div>
  )
}

const wrapperStyle = css({})

const buttonStyle = css({
  marginTop: '20px',
  width: '120px',
  border: 'none',
  outoline: 'none',
  appearance: 'none',
  whiteSpace: 'nowrap',
  padding: '9px 13px 9px 30px',
  color: '#FFFFFF',
  borderRadius: '22px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundColor: '#004CAD',
  backgroundImage: `url(${require('@/static/images/check_white.svg')})`,
  backgroundPosition: 'left 10px center',
  backgroundSize: '14px',
  backgroundRepeat: 'no-repeat',
  '&:hover': {
    opacity: 0.8,
  },
})
