import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { UploadButton } from '@/Components/Advanced/uploadButton'
import { DownloadButton } from '@/Components/Advanced/downloadButton'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { MasterInHouseCostsList } from '@/Components/Pages/Admin/_MasterInHouseCosts/list'
import ReactLoading from 'react-loading'

// apis
import { fetchMasterInHouseCosts, updateMasterInHouseCosts, fetchCalculatorTargetProjects, updateAllTotalPoint } from '@/Apis/scopeProjectRecorder'

// libs
import { csvDownload } from '@/libs/csv-download'
import { allMemberTotalPointCalculator, allTotalPointCalculator } from '@/libs/calculator-total-point'

// components
import { AuthContext } from '@/Contexts/auth'

export const MasterInHouseCosts: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const history = useHistory()
  const [masterInHouseCosts, setMasterInHouseCosts] = useState<any>([])
  const [masterInHouseCostsForCSV, setMasterInHouseCostsForCSV] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _fetchMasterInHouseCosts = async () => {
    await fetchMasterInHouseCosts().then(data => {
      // @ts-ignore
      setMasterInHouseCosts(data.data.master)
      // @ts-ignore
      setListTotalCount(data.data.total)
      setIsLoading(false)
    })
  }

  const _onFileLoad = csvData => {
    setIsLoading(true)
    if (csvData) {
      updateMasterInHouseCosts(csvData).then(res => {
        // @ts-ignore
        if (!res) {
          alert('CSVアップロードに失敗しました。')
          setIsLoading(false)
          return
        }

        // @ts-ignore
        if (res.success) {
          fetchCalculatorTargetProjects().then(res => {
            // @ts-ignore
            const _projects = res.data.projects
            _projects.forEach((project, index) => {
              // 内製費以外は除外対象
              if (project.evaluation_index_name !== '内制費') return
              // 最新のcsvデータから金額に合う倍率の取得
              let _competition = 0
              csvData.forEach(csv => {
                if (parseInt(project.evaluation_index_price) > parseInt(csv['開始金額'])) {
                  _competition = parseInt(csv['倍率'])
                }
              })
              // 倍率の更新
              _projects[index].evaluation_index_competition = _competition.toFixed(2)
              // 倍率更新に合わせて、評価値を更新
              _projects[index]['project_members'] = allMemberTotalPointCalculator(project.evaluation_index_competition_edit !== '0' ? project.evaluation_index_competition_edit : _competition.toFixed(2), project['project_members'])
              _projects[index].point = allTotalPointCalculator(project['project_members'])
            })
            updateAllTotalPoint(_projects).then(res => {
              _fetchMasterInHouseCosts()
              alert('CSVアップロード完了しました。')
            })
          })
        } else {
          // @ts-ignore
          alert(res.message)
          setIsLoading(false)
          return
        }
      })
    }
  }

  const _csvDownload = () => {
    csvDownload(masterInHouseCostsForCSV, 'master-in-house-costst.csv')
  }

  useEffect(() => {
    if (masterInHouseCosts) {
      const csvHeader = ['システムID', '開始金額', '倍率', '並び順']
      const csvData = masterInHouseCosts.map((masterInHouseCost: any) => {
        return [masterInHouseCost.in_house_cost_id, masterInHouseCost.begin_price, masterInHouseCost.competition, masterInHouseCost.order]
      })
      setMasterInHouseCostsForCSV([csvHeader, ...csvData])
    }
  }, [masterInHouseCosts])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      setIsLoading(true)
      _fetchMasterInHouseCosts()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="admin" />
        <div css={containerStyle}>
          <PageTitle title={'内制費マスタ一覧'} />
          <div css={mainTopContentsStyle}>
            <div />
            <div css={searchSideButtonWrapperStyle}>
              <UploadButton onFileLoad={_onFileLoad} />
              <DownloadButton onClick={_csvDownload} />
            </div>
          </div>
          <div css={mainContentsStyle}>
            <ListPageCount pageCount={listTotalCount} />
            <MasterInHouseCostsList masterInHouseCosts={masterInHouseCosts} />
          </div>
        </div>
        {isLoading && (
          <div css={loadingOverlayStyle}>
            <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
          </div>
        )}
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const searchSideButtonWrapperStyle = css({
  display: 'flex',
  '.field': {
    marginRight: '10px',
  },
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
