// 同一等級で合計得点ランキング
export const rankingTotalPointByRate = staffEvaluations => {
  const _staffEvaluations = staffEvaluations.filter(staffEvaluation => staffEvaluation.rate !== null)
  const calculatorEvaluationRate = []
  // 順位評価（同等級）
  // 等級1で合計得点高い順
  const _rate1 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '1').sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate1Top1Percent = _rate1.slice(0, Math.round(_rate1.length * 0.05))
  calculatorEvaluationRate['rate1Top1Percent'] = _rate1Top1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate1Top5Percent = _rate1
    .filter(staffEvaluation => !_rate1Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate1.length * 0.15))
  calculatorEvaluationRate['rate1Top5Percent'] = _rate1Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate1Top20Percent = _rate1
    .filter(staffEvaluation => !_rate1Top1Percent.includes(staffEvaluation) && !_rate1Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate1.length * 0.6))
  calculatorEvaluationRate['rate1Top20Percent'] = _rate1Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate1Top80Percent = _rate1
    .filter(
      staffEvaluation =>
        !_rate1Top1Percent.includes(staffEvaluation) &&
        !_rate1Top5Percent.includes(staffEvaluation) &&
        !_rate1Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate1.length * 0.15))
  calculatorEvaluationRate['rate1Top80Percent'] = _rate1Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate1Top95Percent = _rate1
    .filter(
      staffEvaluation =>
        !_rate1Top1Percent.includes(staffEvaluation) &&
        !_rate1Top5Percent.includes(staffEvaluation) &&
        !_rate1Top20Percent.includes(staffEvaluation) &&
        !_rate1Top80Percent.includes(staffEvaluation),
    )
  calculatorEvaluationRate['rate1Top95Percent'] = _rate1Top95Percent

  // 等級2
  const _rate2 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '2').sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate2Top1Percent = _rate2.slice(0, Math.round(_rate2.length * 0.05))
  calculatorEvaluationRate['rate2Top1Percent'] = _rate2Top1Percent
  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate2Top5Percent = _rate2
    .filter(staffEvaluation => !_rate2Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate2.length * 0.15))
  calculatorEvaluationRate['rate2Top5Percent'] = _rate2Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate2Top20Percent = _rate2
    .filter(staffEvaluation => !_rate2Top1Percent.includes(staffEvaluation) && !_rate2Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate2.length * 0.6))
  calculatorEvaluationRate['rate2Top20Percent'] = _rate2Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate2Top80Percent = _rate2
    .filter(
      staffEvaluation =>
        !_rate2Top1Percent.includes(staffEvaluation) &&
        !_rate2Top5Percent.includes(staffEvaluation) &&
        !_rate2Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate2.length * 0.15))
  calculatorEvaluationRate['rate2Top80Percent'] = _rate2Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate2Top95Percent = _rate2
    .filter(
      staffEvaluation =>
        !_rate2Top1Percent.includes(staffEvaluation) &&
        !_rate2Top5Percent.includes(staffEvaluation) &&
        !_rate2Top20Percent.includes(staffEvaluation) &&
        !_rate2Top80Percent.includes(staffEvaluation),
    )
  calculatorEvaluationRate['rate2Top95Percent'] = _rate2Top95Percent

  // 等級3
  const _rate3 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '3').sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate3Top1Percent = _rate3.slice(0, Math.round(_rate3.length * 0.05))
  calculatorEvaluationRate['rate3Top1Percent'] = _rate3Top1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate3Top5Percent = _rate3
    .filter(staffEvaluation => !_rate3Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate3.length * 0.15))
  calculatorEvaluationRate['rate3Top5Percent'] = _rate3Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate3Top20Percent = _rate3
    .filter(staffEvaluation => !_rate3Top1Percent.includes(staffEvaluation) && !_rate3Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate3.length * 0.6))
  calculatorEvaluationRate['rate3Top20Percent'] = _rate3Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate3Top80Percent = _rate3
    .filter(
      staffEvaluation =>
        !_rate3Top1Percent.includes(staffEvaluation) &&
        !_rate3Top5Percent.includes(staffEvaluation) &&
        !_rate3Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate3.length * 0.15))
  calculatorEvaluationRate['rate3Top80Percent'] = _rate3Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate3Top95Percent = _rate3
    .filter(
      staffEvaluation =>
        !_rate3Top1Percent.includes(staffEvaluation) &&
        !_rate3Top5Percent.includes(staffEvaluation) &&
        !_rate3Top20Percent.includes(staffEvaluation) &&
        !_rate3Top80Percent.includes(staffEvaluation),
    )
  calculatorEvaluationRate['rate3Top95Percent'] = _rate3Top95Percent

  // 等級4
  const _rate4 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '4').sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate4Top1Percent = _rate4.slice(0, Math.round(_rate4.length * 0.05))
  calculatorEvaluationRate['rate4Top1Percent'] = _rate4Top1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate4Top5Percent = _rate4
    .filter(staffEvaluation => !_rate4Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate4.length * 0.15))
  calculatorEvaluationRate['rate4Top5Percent'] = _rate4Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate4Top20Percent = _rate4
    .filter(staffEvaluation => !_rate4Top1Percent.includes(staffEvaluation) && !_rate4Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate4.length * 0.6))
  calculatorEvaluationRate['rate4Top20Percent'] = _rate4Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate4Top80Percent = _rate4
    .filter(
      staffEvaluation =>
        !_rate4Top1Percent.includes(staffEvaluation) &&
        !_rate4Top5Percent.includes(staffEvaluation) &&
        !_rate4Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate4.length * 0.15))
  calculatorEvaluationRate['rate4Top80Percent'] = _rate4Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate4Top95Percent = _rate4
    .filter(
      staffEvaluation =>
        !_rate4Top1Percent.includes(staffEvaluation) &&
        !_rate4Top5Percent.includes(staffEvaluation) &&
        !_rate4Top20Percent.includes(staffEvaluation) &&
        !_rate4Top80Percent.includes(staffEvaluation),
    )
  calculatorEvaluationRate['rate4Top95Percent'] = _rate4Top95Percent

  // 等級5
  const _rate5 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '5').sort((a, b) => b.total_point - a.total_point)
  // 上位1%で全体の割合5%から抽出(既に含まれているユーザーは除く)
  const _rate5Top1Percent = _rate5.slice(0, Math.round(_rate5.length * 0.05))
  calculatorEvaluationRate['rate5Top1Percent'] = _rate5Top1Percent
  
  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate5Top5Percent = _rate5
    .filter(staffEvaluation => !_rate5Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate5.length * 0.15))
  calculatorEvaluationRate['rate5Top5Percent'] = _rate5Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate5Top20Percent = _rate5
    .filter(staffEvaluation => !_rate5Top1Percent.includes(staffEvaluation) && !_rate5Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate5.length * 0.6))
    calculatorEvaluationRate['rate5Top20Percent'] = _rate5Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate5Top80Percent = _rate5
    .filter(
      staffEvaluation =>
        !_rate5Top1Percent.includes(staffEvaluation) &&
        !_rate5Top5Percent.includes(staffEvaluation) &&
        !_rate5Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate5.length * 0.15))
  calculatorEvaluationRate['rate5Top80Percent'] = _rate5Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate5Top95Percent = _rate5
    .filter(
      staffEvaluation =>
        !_rate5Top1Percent.includes(staffEvaluation) &&
        !_rate5Top5Percent.includes(staffEvaluation) &&
        !_rate5Top20Percent.includes(staffEvaluation) &&
        !_rate5Top80Percent.includes(staffEvaluation),
    )
  calculatorEvaluationRate['rate5Top95Percent'] = _rate5Top95Percent

  // 等級6
  const _rate6 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '6').sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate6Top1Percent = _rate6.slice(0, Math.round(_rate6.length * 0.05))
  calculatorEvaluationRate['rate6Top1Percent'] = _rate6Top1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate6Top5Percent = _rate6
    .filter(staffEvaluation => !_rate6Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate6.length * 0.15))
  calculatorEvaluationRate['rate6Top5Percent'] = _rate6Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate6Top20Percent = _rate6
    .filter(staffEvaluation => !_rate6Top1Percent.includes(staffEvaluation) && !_rate6Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate6.length * 0.6))
  calculatorEvaluationRate['rate6Top20Percent'] = _rate6Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate6Top80Percent = _rate6
    .filter(
      staffEvaluation =>
        !_rate6Top1Percent.includes(staffEvaluation) &&
        !_rate6Top5Percent.includes(staffEvaluation) &&
        !_rate6Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate6.length * 0.15))
  calculatorEvaluationRate['rate6Top80Percent'] = _rate6Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate6Top95Percent = _rate6
    .filter(
      staffEvaluation =>
        !_rate6Top1Percent.includes(staffEvaluation) &&
        !_rate6Top5Percent.includes(staffEvaluation) &&
        !_rate6Top20Percent.includes(staffEvaluation) &&
        !_rate6Top80Percent.includes(staffEvaluation),
    )
  calculatorEvaluationRate['rate6Top95Percent'] = _rate6Top95Percent

  // 等級7
  const _rate7 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '7').sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate7Top1Percent = _rate7.slice(0, Math.round(_rate7.length * 0.05))
  calculatorEvaluationRate['rate7Top1Percent'] = _rate7Top1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate7Top5Percent = _rate7
    .filter(staffEvaluation => !_rate7Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate7.length * 0.15))
  calculatorEvaluationRate['rate7Top5Percent'] = _rate7Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate7Top20Percent = _rate7
    .filter(staffEvaluation => !_rate7Top1Percent.includes(staffEvaluation) && !_rate7Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate7.length * 0.6))
  calculatorEvaluationRate['rate7Top20Percent'] = _rate7Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate7Top80Percent = _rate7
    .filter(
      staffEvaluation =>
        !_rate7Top1Percent.includes(staffEvaluation) &&
        !_rate7Top5Percent.includes(staffEvaluation) &&
        !_rate7Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate7.length * 0.15))
  calculatorEvaluationRate['rate7Top80Percent'] = _rate7Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate7Top95Percent = _rate7
    .filter(
      staffEvaluation =>
        !_rate7Top1Percent.includes(staffEvaluation) &&
        !_rate7Top5Percent.includes(staffEvaluation) &&
        !_rate7Top20Percent.includes(staffEvaluation) &&
        !_rate7Top80Percent.includes(staffEvaluation),
    )
  calculatorEvaluationRate['rate7Top95Percent'] = _rate7Top95Percent

  // 等級8
  const _rate8 = _staffEvaluations.filter(staffEvaluation => staffEvaluation.rate === '8').sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate8Top1Percent = _rate8.slice(0, Math.round(_rate8.length * 0.05))
  calculatorEvaluationRate['rate8Top1Percent'] = _rate8Top1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate8Top5Percent = _rate8
    .filter(staffEvaluation => !_rate8Top1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate8.length * 0.15))
    calculatorEvaluationRate['rate8Top5Percent'] = _rate8Top5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate8Top20Percent = _rate8
    .filter(staffEvaluation => !_rate8Top1Percent.includes(staffEvaluation) && !_rate8Top5Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate8.length * 0.6))
    calculatorEvaluationRate['rate8Top20Percent'] = _rate8Top20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate8Top80Percent = _rate8
    .filter(
      staffEvaluation =>
        !_rate8Top1Percent.includes(staffEvaluation) &&
        !_rate8Top5Percent.includes(staffEvaluation) &&
        !_rate8Top20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate8.length * 0.15))
    calculatorEvaluationRate['rate8Top80Percent'] = _rate8Top80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate8Top95Percent = _rate8
    .filter(
      staffEvaluation =>
        !_rate8Top1Percent.includes(staffEvaluation) &&
        !_rate8Top5Percent.includes(staffEvaluation) &&
        !_rate8Top20Percent.includes(staffEvaluation) &&
        !_rate8Top80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate8Top95Percent'] = _rate8Top95Percent

  // 順位評価（前後等級）
  // 等級1(前後) = 等級1, 等級2
  const _rate1BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '1' || staffEvaluation.rate === '2')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate1BeforeAndAfterTop1Percent = _rate1BeforeAndAfter.slice(0, Math.round(_rate1BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate1BeforeAndAfterTop1Percent'] = _rate1BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate1BeforeAndAfterTop5Percent = _rate1BeforeAndAfter
    .filter(staffEvaluation => !_rate1BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate1BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate1BeforeAndAfterTop5Percent'] = _rate1BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate1BeforeAndAfterTop20Percent = _rate1BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate1BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate1BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate1BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate1BeforeAndAfterTop20Percent'] = _rate1BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate1BeforeAndAfterTop80Percent = _rate1BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate1BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate1BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate1BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate1BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate1BeforeAndAfterTop80Percent'] = _rate1BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate1BeforeAndAfterTop95Percent = _rate1BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate1BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate1BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate1BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate1BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate1BeforeAndAfterTop95Percent'] = _rate1BeforeAndAfterTop95Percent

  // 等級2(前後) = 等級1, 等級2, 等級3
  const _rate2BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '1' || staffEvaluation.rate === '2' || staffEvaluation.rate === '3')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate2BeforeAndAfterTop1Percent = _rate2BeforeAndAfter.slice(0, Math.round(_rate2BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate2BeforeAndAfterTop1Percent'] = _rate2BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate2BeforeAndAfterTop5Percent = _rate2BeforeAndAfter
    .filter(staffEvaluation => !_rate2BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate2BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate2BeforeAndAfterTop5Percent'] = _rate2BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate2BeforeAndAfterTop20Percent = _rate2BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate2BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate2BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate2BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate2BeforeAndAfterTop20Percent'] = _rate2BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate2BeforeAndAfterTop80Percent = _rate2BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate2BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate2BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate2BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate2BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate2BeforeAndAfterTop80Percent'] = _rate2BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate2BeforeAndAfterTop95Percent = _rate2BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate2BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate2BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate2BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate2BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate2BeforeAndAfterTop95Percent'] = _rate2BeforeAndAfterTop95Percent

  // 等級3(前後) = 等級2, 等級3, 等級4
  const _rate3BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '2' || staffEvaluation.rate === '3' || staffEvaluation.rate === '4')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate3BeforeAndAfterTop1Percent = _rate3BeforeAndAfter.slice(0, Math.round(_rate3BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate3BeforeAndAfterTop1Percent'] = _rate3BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate3BeforeAndAfterTop5Percent = _rate3BeforeAndAfter
    .filter(staffEvaluation => !_rate3BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate3BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate3BeforeAndAfterTop5Percent'] = _rate3BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate3BeforeAndAfterTop20Percent = _rate3BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate3BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate3BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate3BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate3BeforeAndAfterTop20Percent'] = _rate3BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate3BeforeAndAfterTop80Percent = _rate3BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate3BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate3BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate3BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate3BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate3BeforeAndAfterTop80Percent'] = _rate3BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate3BeforeAndAfterTop95Percent = _rate3BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate3BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate3BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate3BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate3BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate3BeforeAndAfterTop95Percent'] = _rate3BeforeAndAfterTop95Percent

  // 等級4(前後) = 等級3, 等級4, 等級5
  const _rate4BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '3' || staffEvaluation.rate === '4' || staffEvaluation.rate === '5')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate4BeforeAndAfterTop1Percent = _rate4BeforeAndAfter.slice(0, Math.round(_rate4BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate4BeforeAndAfterTop1Percent'] = _rate4BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate4BeforeAndAfterTop5Percent = _rate4BeforeAndAfter
    .filter(staffEvaluation => !_rate4BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate4BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate4BeforeAndAfterTop5Percent'] = _rate4BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate4BeforeAndAfterTop20Percent = _rate4BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate4BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate4BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate4BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate4BeforeAndAfterTop20Percent'] = _rate4BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate4BeforeAndAfterTop80Percent = _rate4BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate4BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate4BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate4BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate4BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate4BeforeAndAfterTop80Percent'] = _rate4BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate4BeforeAndAfterTop95Percent = _rate4BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate4BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate4BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate4BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate4BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate4BeforeAndAfterTop95Percent'] = _rate4BeforeAndAfterTop95Percent

  // 等級5(前後) = 等級4, 等級5, 等級6
  const _rate5BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '4' || staffEvaluation.rate === '5' || staffEvaluation.rate === '6')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate5BeforeAndAfterTop1Percent = _rate5BeforeAndAfter.slice(0, Math.round(_rate5BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate5BeforeAndAfterTop1Percent'] = _rate5BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate5BeforeAndAfterTop5Percent = _rate5BeforeAndAfter
    .filter(staffEvaluation => !_rate5BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate5BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate5BeforeAndAfterTop5Percent'] = _rate5BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate5BeforeAndAfterTop20Percent = _rate5BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate5BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate5BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate5BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate5BeforeAndAfterTop20Percent'] = _rate5BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate5BeforeAndAfterTop80Percent = _rate5BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate5BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate5BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate5BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate5BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate5BeforeAndAfterTop80Percent'] = _rate5BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate5BeforeAndAfterTop95Percent = _rate5BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate5BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate5BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate5BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate5BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate5BeforeAndAfterTop95Percent'] = _rate5BeforeAndAfterTop95Percent

  // 等級6(前後) = 等級5, 等級6, 等級7
  const _rate6BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '5' || staffEvaluation.rate === '6' || staffEvaluation.rate === '7')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate6BeforeAndAfterTop1Percent = _rate6BeforeAndAfter.slice(0, Math.round(_rate6BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate6BeforeAndAfterTop1Percent'] = _rate6BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate6BeforeAndAfterTop5Percent = _rate6BeforeAndAfter
    .filter(staffEvaluation => !_rate6BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate6BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate6BeforeAndAfterTop5Percent'] = _rate6BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate6BeforeAndAfterTop20Percent = _rate6BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate6BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate6BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate6BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate6BeforeAndAfterTop20Percent'] = _rate6BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate6BeforeAndAfterTop80Percent = _rate6BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate6BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate6BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate6BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate6BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate6BeforeAndAfterTop80Percent'] = _rate6BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate6BeforeAndAfterTop95Percent = _rate6BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate6BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate6BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate6BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate6BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate6BeforeAndAfterTop95Percent'] = _rate6BeforeAndAfterTop95Percent

  // 等級7(前後) = 等級6, 等級7, 等級8
  const _rate7BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '6' || staffEvaluation.rate === '7' || staffEvaluation.rate === '8')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate7BeforeAndAfterTop1Percent = _rate7BeforeAndAfter.slice(0, Math.round(_rate7BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate7BeforeAndAfterTop1Percent'] = _rate7BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate7BeforeAndAfterTop5Percent = _rate7BeforeAndAfter
    .filter(staffEvaluation => !_rate7BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate7BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate7BeforeAndAfterTop5Percent'] = _rate7BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate7BeforeAndAfterTop20Percent = _rate7BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate7BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate7BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate7BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate7BeforeAndAfterTop20Percent'] = _rate7BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate7BeforeAndAfterTop80Percent = _rate7BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate7BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate7BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate7BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate7BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate7BeforeAndAfterTop80Percent'] = _rate7BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate7BeforeAndAfterTop95Percent = _rate7BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate7BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate7BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate7BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate7BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate7BeforeAndAfterTop95Percent'] = _rate7BeforeAndAfterTop95Percent

  // 等級8(前後) = 等級7, 等級8
  const _rate8BeforeAndAfter = staffEvaluations
    .filter(staffEvaluation => staffEvaluation.rate === '7' || staffEvaluation.rate === '8')
    .sort((a, b) => b.total_point - a.total_point)
  // 上位1% 5段階評価(5) 既に含まれているユーザーは除く
  const _rate8BeforeAndAfterTop1Percent = _rate8BeforeAndAfter.slice(0, Math.round(_rate8BeforeAndAfter.length * 0.05))
  calculatorEvaluationRate['rate8BeforeAndAfterTop1Percent'] = _rate8BeforeAndAfterTop1Percent

  // 上位5% 5段階評価(4) 既に含まれているユーザーは除く
  const _rate8BeforeAndAfterTop5Percent = _rate8BeforeAndAfter
    .filter(staffEvaluation => !_rate8BeforeAndAfterTop1Percent.includes(staffEvaluation))
    .slice(0, Math.round(_rate8BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate8BeforeAndAfterTop5Percent'] = _rate8BeforeAndAfterTop5Percent

  // 上位20% 5段階評価(3) 既に含まれているユーザーは除く
  const _rate8BeforeAndAfterTop20Percent = _rate8BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate8BeforeAndAfterTop1Percent.includes(staffEvaluation) && !_rate8BeforeAndAfterTop5Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate8BeforeAndAfter.length * 0.6))
    calculatorEvaluationRate['rate8BeforeAndAfterTop20Percent'] = _rate8BeforeAndAfterTop20Percent

  // 上位80% 5段階評価(2) 既に含まれているユーザーは除く
  const _rate8BeforeAndAfterTop80Percent = _rate8BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate8BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate8BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate8BeforeAndAfterTop20Percent.includes(staffEvaluation),
    )
    .slice(0, Math.round(_rate8BeforeAndAfter.length * 0.15))
    calculatorEvaluationRate['rate8BeforeAndAfterTop80Percent'] = _rate8BeforeAndAfterTop80Percent

  // 上位95% 5段階評価(1) 既に含まれているユーザーは除く
  const _rate8BeforeAndAfterTop95Percent = _rate8BeforeAndAfter
    .filter(
      staffEvaluation =>
        !_rate8BeforeAndAfterTop1Percent.includes(staffEvaluation) &&
        !_rate8BeforeAndAfterTop5Percent.includes(staffEvaluation) &&
        !_rate8BeforeAndAfterTop20Percent.includes(staffEvaluation) &&
        !_rate8BeforeAndAfterTop80Percent.includes(staffEvaluation),
    )
    calculatorEvaluationRate['rate8BeforeAndAfterTop95Percent'] = _rate8BeforeAndAfterTop95Percent

  // 偏差値評価（同等級）
  // _rate1の平均
  const _rate1Average = _rate1.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate1.length
  calculatorEvaluationRate['rate1Average'] = _rate1Average
  // _rate1の標準偏差
  const _rate1StandardDeviation = Math.sqrt(
    _rate1.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate1Average, 2)
    }, 0) / _rate1.length,
  )
  calculatorEvaluationRate['rate1StandardDeviation'] = _rate1StandardDeviation
  // rate1の前後等級の平均
  const _rate1BeforeAndAfterAverage =
    _rate1BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate1BeforeAndAfter.length
  calculatorEvaluationRate['rate1BeforeAndAfterAverage'] = _rate1BeforeAndAfterAverage
  // rate1の前後等級の標準偏差
  const _rate1BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate1BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate1BeforeAndAfterAverage, 2)
    }, 0) / _rate1BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate1BeforeAndAfterStandardDeviation'] = _rate1BeforeAndAfterStandardDeviation

  // _rate2の平均
  const _rate2Average = _rate2.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate2.length
  calculatorEvaluationRate['rate2Average'] = _rate2Average
  // _rate2の標準偏差
  const _rate2StandardDeviation = Math.sqrt(
    _rate2.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate2Average, 2)
    }, 0) / _rate2.length,
  )
  calculatorEvaluationRate['rate2StandardDeviation'] = _rate2StandardDeviation
  // rate2の前後等級の平均
  const _rate2BeforeAndAfterAverage =
    _rate2BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate2BeforeAndAfter.length
  calculatorEvaluationRate['rate2BeforeAndAfterAverage'] = _rate2BeforeAndAfterAverage
  // rate2の前後等級の標準偏差
  const _rate2BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate2BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate2BeforeAndAfterAverage, 2)
    }, 0) / _rate2BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate2BeforeAndAfterStandardDeviation'] = _rate2BeforeAndAfterStandardDeviation

  // _rate3の平均
  const _rate3Average = _rate3.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate3.length
  calculatorEvaluationRate['rate3Average'] = _rate3Average
  // _rate3の標準偏差
  const _rate3StandardDeviation = Math.sqrt(
    _rate3.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate3Average, 2)
    }, 0) / _rate3.length,
  )
  calculatorEvaluationRate['rate3StandardDeviation'] = _rate3StandardDeviation
  // rate3の前後等級の平均
  const _rate3BeforeAndAfterAverage =
    _rate3BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate3BeforeAndAfter.length
  calculatorEvaluationRate['rate3BeforeAndAfterAverage'] = _rate3BeforeAndAfterAverage
  // rate3の前後等級の標準偏差
  const _rate3BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate3BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate3BeforeAndAfterAverage, 2)
    }, 0) / _rate3BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate3BeforeAndAfterStandardDeviation'] = _rate3BeforeAndAfterStandardDeviation

  // _rate4の平均
  const _rate4Average = _rate4.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate4.length
  calculatorEvaluationRate['rate4Average'] = _rate4Average
  // _rate4の標準偏差
  const _rate4StandardDeviation = Math.sqrt(
    _rate4.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate4Average, 2)
    }, 0) / _rate4.length,
  )
  calculatorEvaluationRate['rate4StandardDeviation'] = _rate4StandardDeviation
  // rate4の前後等級の平均
  const _rate4BeforeAndAfterAverage =
    _rate4BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate4BeforeAndAfter.length
  calculatorEvaluationRate['rate4BeforeAndAfterAverage'] = _rate4BeforeAndAfterAverage
  // rate4の前後等級の標準偏差
  const _rate4BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate4BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate4BeforeAndAfterAverage, 2)
    }, 0) / _rate4BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate4BeforeAndAfterStandardDeviation'] = _rate4BeforeAndAfterStandardDeviation

  // _rate5の平均
  const _rate5Average = _rate5.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate5.length
  calculatorEvaluationRate['rate5Average'] = _rate5Average
  // _rate5の標準偏差
  const _rate5StandardDeviation = Math.sqrt(
    _rate5.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate5Average, 2)
    }, 0) / _rate5.length,
  )
  calculatorEvaluationRate['rate5StandardDeviation'] = _rate5StandardDeviation
  // rate5の前後等級の平均
  const _rate5BeforeAndAfterAverage =
    _rate5BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate5BeforeAndAfter.length
  calculatorEvaluationRate['rate5BeforeAndAfterAverage'] = _rate5BeforeAndAfterAverage
  // rate5の前後等級の標準偏差
  const _rate5BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate5BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate5BeforeAndAfterAverage, 2)
    }, 0) / _rate5BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate5BeforeAndAfterStandardDeviation'] = _rate5BeforeAndAfterStandardDeviation

  // _rate6の平均
  const _rate6Average = _rate6.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate6.length
  calculatorEvaluationRate['rate6Average'] = _rate6Average
  // _rate6の標準偏差
  const _rate6StandardDeviation = Math.sqrt(
    _rate6.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate6Average, 2)
    }, 0) / _rate6.length,
  )
  calculatorEvaluationRate['rate6StandardDeviation'] = _rate6StandardDeviation
  // rate6の前後等級の平均
  const _rate6BeforeAndAfterAverage =
    _rate6BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate6BeforeAndAfter.length
  calculatorEvaluationRate['rate6BeforeAndAfterAverage'] = _rate6BeforeAndAfterAverage
  // rate6の前後等級の標準偏差
  const _rate6BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate6BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate6BeforeAndAfterAverage, 2)
    }, 0) / _rate6BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate6BeforeAndAfterStandardDeviation'] = _rate6BeforeAndAfterStandardDeviation

  // _rate7の平均
  const _rate7Average = _rate7.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate7.length
  calculatorEvaluationRate['rate7Average'] = _rate7Average
  // _rate7の標準偏差
  const _rate7StandardDeviation = Math.sqrt(
    _rate7.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate7Average, 2)
    }, 0) / _rate7.length,
  )
  calculatorEvaluationRate['rate7StandardDeviation'] = _rate7StandardDeviation
  // rate7の前後等級の平均
  const _rate7BeforeAndAfterAverage =
    _rate7BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate7BeforeAndAfter.length
  calculatorEvaluationRate['rate7BeforeAndAfterAverage'] = _rate7BeforeAndAfterAverage
  // rate7の前後等級の標準偏差
  const _rate7BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate7BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate7BeforeAndAfterAverage, 2)
    }, 0) / _rate7BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate7BeforeAndAfterStandardDeviation'] = _rate7BeforeAndAfterStandardDeviation

  // _rate8の平均
  const _rate8Average = _rate8.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate8.length
  calculatorEvaluationRate['rate8Average'] = _rate8Average
  // _rate8の標準偏差
  const _rate8StandardDeviation = Math.sqrt(
    _rate8.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate8Average, 2)
    }, 0) / _rate8.length,
  )
  calculatorEvaluationRate['rate8StandardDeviation'] = _rate8StandardDeviation
  // rate8の前後等級の平均
  const _rate8BeforeAndAfterAverage =
    _rate8BeforeAndAfter.reduce((acc, staffEvaluation) => acc + staffEvaluation.total_point, 0) / _rate8BeforeAndAfter.length
  calculatorEvaluationRate['rate8BeforeAndAfterAverage'] = _rate8BeforeAndAfterAverage
  // rate8の前後等級の標準偏差
  const _rate8BeforeAndAfterStandardDeviation = Math.sqrt(
    _rate8BeforeAndAfter.reduce((acc, staffEvaluation) => {
      const _totalPoint = staffEvaluation.total_point
      return acc + Math.pow(_totalPoint - _rate8BeforeAndAfterAverage, 2)
    }, 0) / _rate8BeforeAndAfter.length,
  )
  calculatorEvaluationRate['rate8BeforeAndAfterStandardDeviation'] = _rate8BeforeAndAfterStandardDeviation

  // 各データのセット
  // データ取得後に加工な必要なデータ
  if (staffEvaluations) {
    staffEvaluations.forEach(staffEvaluation => {
      staffEvaluation.task_point_total_csv = !isNaN(staffEvaluation.task_point_total) ? staffEvaluation.task_point_total.toFixed(2) : '-'
      staffEvaluation.task_point_total_avg_csv = !isNaN(staffEvaluation.task_point_total_avg)
        ? staffEvaluation.task_point_total_avg.toFixed(2)
        : '-'
      staffEvaluation.contribution_point_total_csv = !isNaN(staffEvaluation.contribution_point_total)
        ? staffEvaluation.contribution_point_total.toFixed(2)
        : '-'
      staffEvaluation.contribution_point_total_avg_csv = !isNaN(staffEvaluation.contribution_point_total_avg)
        ? staffEvaluation.contribution_point_total_avg.toFixed(2)
        : '-'
      staffEvaluation.total_point_csv = !isNaN(staffEvaluation.total_point) ? staffEvaluation.total_point.toFixed(2) : '-'
      staffEvaluation.total_point_avg_csv = !isNaN(staffEvaluation.total_point_avg) ? staffEvaluation.total_point_avg.toFixed(2) : '-'
      staffEvaluation.averagebeforeMagnificationAddition = _averagebeforeMagnificationAddition(staffEvaluation)
      staffEvaluation.averagebeforeMagnificationAdditionPj = _averagebeforeMagnificationAdditionPj(staffEvaluation)
      staffEvaluation.rankingEvaluationEquivalentGrade = _rankingEvaluationEquivalentGrade(staffEvaluation, calculatorEvaluationRate)
      staffEvaluation.rankingEvaluationBeforeAndAfterGrade = _rankingEvaluationBeforeAndAfterGrade(
        staffEvaluation,
        calculatorEvaluationRate,
      )
      staffEvaluation.deviationValueEvaluationEquivalentGrade = _deviationValueEvaluationEquivalentGrade(
        staffEvaluation,
        calculatorEvaluationRate,
      ).deviationValue
      staffEvaluation.deviationValueEvaluationEquivalentGradePoint = _deviationValueEvaluationEquivalentGrade(
        staffEvaluation,
        calculatorEvaluationRate,
      ).deviationValueEvaluationEquivalentGrade
      staffEvaluation.deviationValueEvaluationBeforeAndAfterGrade = _deviationValueEvaluationBeforeAndAfterGrade(
        staffEvaluation,
        calculatorEvaluationRate,
      ).deviationValue
      staffEvaluation.deviationValueEvaluationBeforeAndAfterGradePoint = _deviationValueEvaluationBeforeAndAfterGrade(
        staffEvaluation,
        calculatorEvaluationRate,
      ).deviationValueEvaluationEquivalentGrade
      staffEvaluation.evaluation_point = staffEvaluation.deviationValueEvaluationBeforeAndAfterGradePoint
    })
    return staffEvaluations
  } else {
    return []
  }
}

// 倍率加算前平均
export const _averagebeforeMagnificationAddition = staffEvaluation => {
  let averagebeforeMagnificationAddition = staffEvaluation.total_point_competition_before_avg
    ? parseFloat(staffEvaluation.total_point_competition_before_avg)
    : '-'
  // @ts-ignore
  averagebeforeMagnificationAddition = !isNaN(averagebeforeMagnificationAddition) ? averagebeforeMagnificationAddition.toFixed(2) : '-'
  return averagebeforeMagnificationAddition
}

// 倍率加算前平均 × PJ数
export const _averagebeforeMagnificationAdditionPj = staffEvaluation => {
  let averagebeforeMagnificationAdditionPj = staffEvaluation.total_point_competition_before_avg * staffEvaluation.project_count_registered
  // @ts-ignore
  averagebeforeMagnificationAdditionPj = averagebeforeMagnificationAdditionPj ? parseFloat(averagebeforeMagnificationAdditionPj) : '-'
  // @ts-ignore
  averagebeforeMagnificationAdditionPj = !isNaN(averagebeforeMagnificationAdditionPj)
    ? averagebeforeMagnificationAdditionPj.toFixed(2)
    : '-'
  return averagebeforeMagnificationAdditionPj
}

// 順位評価（同等級）
export const _rankingEvaluationEquivalentGrade = (staffEvaluation, calculatorEvaluationRate) => {
  if (staffEvaluation.rate === '1') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate1Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate1Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate1Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate1Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate1Top95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '2') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate2Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate2Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate2Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate2Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate2Top95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '3') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate3Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate3Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate3Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate3Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate3Top95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '4') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate4Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate4Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate4Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate4Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate4Top95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '5') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate5Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate5Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate5Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate5Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate5Top95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '6') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate6Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate6Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate6Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate6Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate6Top95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '7') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate7Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate7Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate7Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate7Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate7Top95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '8') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate8Top1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate8Top5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate8Top20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate8Top80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate8Top95Percent'].includes(staffEvaluation)) return 1
  }

  return '-'
}

// 順位評価（前後等級）
export const _rankingEvaluationBeforeAndAfterGrade = (staffEvaluation, calculatorEvaluationRate) => {
  if (staffEvaluation.rate === '1') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate1BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate1BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate1BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate1BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate1BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '2') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate2BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate2BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate2BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate2BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate3BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '3') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate3BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate3BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate3BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate3BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate3BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '4') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate4BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate4BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate4BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate4BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate4BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '5') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate5BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate5BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate5BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate5BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate5BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '6') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate6BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate6BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate6BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate6BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate6BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '7') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate7BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate7BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate7BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate7BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate7BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  if (staffEvaluation.rate === '8') {
    // 上位1%の場合 5段階評価は5
    if (calculatorEvaluationRate['rate8BeforeAndAfterTop1Percent'].includes(staffEvaluation)) return 5
    // 上位5%の場合 5段階評価は4
    if (calculatorEvaluationRate['rate8BeforeAndAfterTop5Percent'].includes(staffEvaluation)) return 4
    // 上位20%の場合 5段階評価は3
    if (calculatorEvaluationRate['rate8BeforeAndAfterTop20Percent'].includes(staffEvaluation)) return 3
    // 上位80%の場合 5段階評価は2
    if (calculatorEvaluationRate['rate8BeforeAndAfterTop80Percent'].includes(staffEvaluation)) return 2
    // 上位95%の場合 5段階評価は1
    if (calculatorEvaluationRate['rate8BeforeAndAfterTop95Percent'].includes(staffEvaluation)) return 1
  }

  return '-'
}

// 偏差値評価（同等級）
export const _deviationValueEvaluationEquivalentGrade = (staffEvaluation, calculatorEvaluationRate) => {
  let avarage = 0
  let standardDeviation = 0
  if (staffEvaluation.rate === '1') {
    avarage = calculatorEvaluationRate['rate1Average']
    standardDeviation = calculatorEvaluationRate['rate1StandardDeviation']
  }
  if (staffEvaluation.rate === '2') {
    avarage = calculatorEvaluationRate['rate2Average']
    standardDeviation = calculatorEvaluationRate['rate2StandardDeviation']
  }
  if (staffEvaluation.rate === '3') {
    avarage = calculatorEvaluationRate['rate3Average']
    standardDeviation = calculatorEvaluationRate['rate3StandardDeviation']
  }
  if (staffEvaluation.rate === '4') {
    avarage = calculatorEvaluationRate['rate4Average']
    standardDeviation = calculatorEvaluationRate['rate4StandardDeviation']
  }
  if (staffEvaluation.rate === '5') {
    avarage = calculatorEvaluationRate['rate5Average']
    standardDeviation = calculatorEvaluationRate['rate5StandardDeviation']
  }
  if (staffEvaluation.rate === '6') {
    avarage = calculatorEvaluationRate['rate6Average']
    standardDeviation = calculatorEvaluationRate['rate6StandardDeviation']
  }
  if (staffEvaluation.rate === '7') {
    avarage = calculatorEvaluationRate['rate7Average']
    standardDeviation = calculatorEvaluationRate['rate7StandardDeviation']
  }
  if (staffEvaluation.rate === '8') {
    avarage = calculatorEvaluationRate['rate8Average']
    standardDeviation = calculatorEvaluationRate['rate8StandardDeviation']
  }
  // 基準値を算出（STANDARDIZE(x (該当スタッフの合計得点), 平均, 標準偏差)）
  const _standardValue = _standardize(staffEvaluation.total_point, avarage, standardDeviation)
  // 偏差値
  const _deviationValue = Math.round(_standardValue * 10 + 50)
  let _deviationValueEvaluationBeforeAndAfterGrade = '0'
  if (!_deviationValue) _deviationValueEvaluationBeforeAndAfterGrade = '-'
  // 偏差値が0から33未満の場合、評価は1
  if (_deviationValue < 33) _deviationValueEvaluationBeforeAndAfterGrade = '1'
  // 偏差値が33から42未満の場合、評価は2
  if (_deviationValue >= 33 && _deviationValue < 42) _deviationValueEvaluationBeforeAndAfterGrade = '2'
  // 偏差値が42から58未満の場合、評価は3
  if (_deviationValue >= 42 && _deviationValue < 58) _deviationValueEvaluationBeforeAndAfterGrade = '3'
  // 偏差値が58から66未満の場合、評価は4
  if (_deviationValue >= 58 && _deviationValue < 66) _deviationValueEvaluationBeforeAndAfterGrade = '4'
  // 偏差値が66以上の場合、評価は5
  if (_deviationValue >= 66) _deviationValueEvaluationBeforeAndAfterGrade = '5'
  return {
    deviationValue: _deviationValue ? _deviationValue : '-',
    deviationValueEvaluationEquivalentGrade: _deviationValueEvaluationBeforeAndAfterGrade,
  }
}

// 偏差値評価（前後等級）
export const _deviationValueEvaluationBeforeAndAfterGrade = (staffEvaluation, calculatorEvaluationRate) => {
  let avarage = 0
  let standardDeviation = 0
  if (staffEvaluation.rate === '1') {
    avarage = calculatorEvaluationRate['rate1BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate1BeforeAndAfterStandardDeviation']
  }
  if (staffEvaluation.rate === '2') {
    avarage = calculatorEvaluationRate['rate2BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate2BeforeAndAfterStandardDeviation']
  }
  if (staffEvaluation.rate === '3') {
    avarage = calculatorEvaluationRate['rate3BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate3BeforeAndAfterStandardDeviation']
  }
  if (staffEvaluation.rate === '4') {
    avarage = calculatorEvaluationRate['rate4BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate4BeforeAndAfterStandardDeviation']
  }
  if (staffEvaluation.rate === '5') {
    avarage = calculatorEvaluationRate['rate5BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate5BeforeAndAfterStandardDeviation']
  }
  if (staffEvaluation.rate === '6') {
    avarage = calculatorEvaluationRate['rate6BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate6BeforeAndAfterStandardDeviation']
  }
  if (staffEvaluation.rate === '7') {
    avarage = calculatorEvaluationRate['rate7BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate7BeforeAndAfterStandardDeviation']
  }
  if (staffEvaluation.rate === '8') {
    avarage = calculatorEvaluationRate['rate8BeforeAndAfterAverage']
    standardDeviation = calculatorEvaluationRate['rate8BeforeAndAfterStandardDeviation']
  }
  // 基準値を算出（STANDARDIZE(x (該当スタッフの合計得点), 平均, 標準偏差)）
  const _standardValue = _standardize(staffEvaluation.total_point, avarage, standardDeviation)
  // 偏差値
  const _deviationValue = Math.round(_standardValue * 10 + 50)
  let _deviationValueEvaluationBeforeAndAfterGrade = '0'
  if (!_deviationValue) _deviationValueEvaluationBeforeAndAfterGrade = '-'
  // 偏差値が0から33未満の場合、評価は1
  if (_deviationValue < 33) _deviationValueEvaluationBeforeAndAfterGrade = '1'
  // 偏差値が33から42未満の場合、評価は2
  if (_deviationValue >= 33 && _deviationValue < 42) _deviationValueEvaluationBeforeAndAfterGrade = '2'
  // 偏差値が42から58未満の場合、評価は3
  if (_deviationValue >= 42 && _deviationValue < 58) _deviationValueEvaluationBeforeAndAfterGrade = '3'
  // 偏差値が58から66未満の場合、評価は4
  if (_deviationValue >= 58 && _deviationValue < 66) _deviationValueEvaluationBeforeAndAfterGrade = '4'
  // 偏差値が66以上の場合、評価は5
  if (_deviationValue >= 66) _deviationValueEvaluationBeforeAndAfterGrade = '5'
  return {
    deviationValue: _deviationValue ? _deviationValue : '-',
    deviationValueEvaluationEquivalentGrade: _deviationValueEvaluationBeforeAndAfterGrade,
  }
}

// 基準値
export const _standardize = (total, average, standardDeviation) => {
  return (total - average) / standardDeviation
}
