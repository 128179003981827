import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { AccountList } from '@/Components/Pages/Admin/_Accounts/list'
import ReactLoading from 'react-loading'

// apis
import { fetchMasterUsers } from '@/Apis/scopeProjectRecorder'

// components
import { AuthContext } from '@/Contexts/auth'

export const Accounts: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const history = useHistory()
  const [masterUsers, setMasterUsers] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _fetchMasterUsers = async () => {
    await fetchMasterUsers().then(data => {
      // @ts-ignore
      setMasterUsers(data.data.users)
      // @ts-ignore
      setListTotalCount(data.data.total)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      setIsLoading(true)
      _fetchMasterUsers()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="admin" />
        <div css={containerStyle}>
          <PageTitle title={'アカウント一覧'} />
          <div css={mainTopContentsStyle}>
            <div />
          </div>
          <div css={mainContentsStyle}>
            <ListPageCount pageCount={listTotalCount} />
            <AccountList masterUsers={masterUsers} />
          </div>
        </div>
        {isLoading && (
          <div css={loadingOverlayStyle}>
            <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
          </div>
        )}
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
