import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// contexts
import { MasterContext } from '@/Contexts/master'

// libs
import { accountLevel } from '@/libs/account-level'

type Props = {
  projects?: any
}

export const StaffEvaluationDetailList: React.FC<Props> = ({ projects }) => {
  const { masterProjectStatus } = useContext<any>(MasterContext)
  const history = useHistory()

  const goToProjectDetail = (project_id = '') => {
    history.push({
      pathname: `/project/evaluation/detail`,
      search: `?projectId=${project_id}&ref=evaluationDetail`,
    })
  }

  const _evaluator = projectMembers => {
    let _evaluator = projectMembers.find(item => item.role === 'evaluator')
    if (!_evaluator) _evaluator = projectMembers.find(item => item.role === 'evaluatorAndManager')
    return `${_evaluator?.last_name} ${_evaluator?.first_name}`
  }

  const _manager = projectMembers => {
    let _manager = projectMembers.find(item => item.role === 'manager')
    if (!_manager) _manager = projectMembers.find(item => item.role === 'evaluatorAndManager')
    return `${_manager?.last_name} ${_manager?.first_name}`
  }

  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  return (
    <div css={mainListContainerStyle}>
      <ul css={mainListHeaderStyle}>
        <li css={list1Style}>アサイン期間</li>
        <li css={list2Style}>評価年月日</li>
        <li css={list3Style}>クライアント名</li>
        <li css={list4Style}>プロジェクト名称</li>
        <li css={list5Style}>評価者</li>
        <li css={list6Style}>PJ責任者</li>
        <li css={list7Style}>役割</li>
        <li css={list8Style}>個人タスク</li>
        <li css={list9Style}>貢献度</li>
        <li css={list10Style}>合計得点</li>
        <li css={list11Style}>評価登録</li>
        <li css={list12Style}>ステータス</li>
        <li css={list14Style}>評価指標</li>
        <li css={list15Style}>倍率</li>
      </ul>
      <ul css={mainListContentsStyle}>
        {projects.map((project: any) => (
          <li onClick={() => goToProjectDetail(project.project_id)} key={project.project_id}>
            <div css={[listItemStyle, list1Style]}>
              {dayjs(project.begin_date).format('YYYY/MM/DD')}
              <br />
              　{dayjs(project.end_date).format('YYYY/MM/DD')}
            </div>
            <div css={[listItemStyle, list2Style]}>{dayjs(project.evaluation_date).format('YYYY年MM月DD日')}</div>
            <div css={[listItemStyle, list3Style]}>
              <div css={overflowText}>{project.client_name}</div>
            </div>
            <div css={[listItemStyle, list4Style]}>
              <div css={overflowText}>{project.project_name}</div>
            </div>
            <div css={[listItemStyle, list5Style]}>
              <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(project.rate)}.svg`)} />
              {_evaluator(project.project_members)}
            </div>
            <div css={[listItemStyle, list6Style]}>
              <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(project.rate)}.svg`)} />
              {_manager(project.project_members)}
            </div>
            <div css={[listItemStyle, list7Style]}>{project.position_name}</div>
            <div css={[listItemStyle, list8Style]}>{project.task_point}</div>
            <div css={[listItemStyle, list9Style]}>{project.contribution_point}</div>
            <div css={[listItemStyle, list10Style]}>{project.total_point}</div>
            <div css={[listItemStyle, list11Style]}>{project.evaluation_status === 'registered' ? '完了' : '未登録'}</div>
            <div css={[listItemStyle, list12Style]}>
              {project?.status ? masterProjectStatus.find(status => status.id === project?.status)?.name : '未登録'}
            </div>
            <div css={[listItemStyle, list14Style]}>
              <div css={overflowText}>{project.evaluation_index_name}</div>
            </div>
            <div css={[listItemStyle, list15Style]}>{project.evaluation_index_competition_edit !== '0' ? project.evaluation_index_competition_edit : project.evaluation_index_competition}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const mainListContainerStyle = css({
  display: 'grid',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  height: '480px',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  height: '420px',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  li: {
    padding: '20px 16px',
    borderBottom: '1px solid #DDDDDD',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const overflowText = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const list1Style = css({
  width: '150px',
})

const list2Style = css({
  width: '150px',
})

const list3Style = css({
  width: '150px',
})

const list4Style = css({
  width: '150px',
})

const list5Style = css({
  width: '150px',
})

const list6Style = css({
  width: '150px',
  display: 'flex',
  alignItems: 'center',
  span: {
    color: '#F3362D',
  },
})

const list7Style = css({
  width: '100px',
})

const list8Style = css({
  width: '100px',
})

const list9Style = css({
  width: '100px',
})

const list10Style = css({
  width: '100px',
})

const list11Style = css({
  width: '100px',
})

const list12Style = css({
  width: '100px',
})

const list14Style = css({
  width: '150px',
})

const list15Style = css({
  width: '100px',
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})
