import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Global, css } from '@emotion/core'

// contexts
import { AuthProvider } from '@/Contexts/auth'
import { AccountProvider } from '@/Contexts/account'
import { MasterProvider } from '@/Contexts/master'

// components
import { Router } from '@/Components/Apps/router'

export const App: React.FC = () => {
  return (
    <AuthProvider>
      <AccountProvider>
        <MasterProvider>
          <Router />
        </MasterProvider>
      </AccountProvider>
    </AuthProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <link rel="stylesheet" href={require('@/static/styles/reset.css')} />
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css?family=Lato');
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP');
        * {
          box-sizing: border-box;
          font-family: 'Noto Sans CJK JP', sans-serif;
          letter-spacing: 0.9px;
          outline: 0;
          text-decoration: none;
        }
        *::-webkit-scrollbar {
          height: 16px;
          width: 16px;
        }
        *::-webkit-scrollbar-track {
          background: transparent;
        }
        *::-webkit-scrollbar-thumb {
          background: #ccc;
          border-radius: 16px;
        }
        a {
          text-decoration: none;
        }
        li {
          list-style: none;
        }
        input,
        select {
          letter-spacing: 0.9px;
        }
        body {
          font-size: 13px;
          color: #151619;
          line-height: 22px;
        }
        .pagination {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: -40px auto 10px;
          gap: 20px 6px;
        }
        .page-item {
          display: inline-flex;
          align-items: center;
          border-radius: 30px;
          justify-content: center;
          font-size: 14px;
          height: 32px;
          width: 32px;
          cursor: pointer;
          border: 1px solid #004cad;
        }
        .previous-next-item {
          display: inline-flex;
          align-items: center;
          border-radius: 30px;
          justify-content: center;
          font-size: 14px;
          height: 32px;
          width: 32px;
          cursor: pointer;
        }
        .page-item:hover,
        .page-link:hover {
          text-decoration: underline;
        }
        .page-item-active {
          // text-decoration: underline;
          background-color: #004cad;
          color: #ffffff;
        }
      `}
    />
    <link rel="stylesheet" href={require('@/static/styles/datepicker.css')} />
    <link rel="stylesheet" href={require('@/static/styles/datepicker-custom.css')} />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
