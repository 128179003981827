import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { BackButton } from '@/Components/Advanced/backButton'
import { Button } from '@/Components/Advanced/button'
import { ProjectEditCard } from '@/Components/Pages/Project/_Edit/card'
import { StaffEditListContent } from '@/Components/Pages/Project/_Edit/staffEditListContent'
import { ManagerRegisterList } from '@/Components/Pages/Project/_Edit/managerRegisterList'
import ReactLoading from 'react-loading'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// apis
import { fetchProjectDetail, projectEdit, projectDelete } from '@/Apis/scopeProjectRecorder'

// contexts
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

// libs
import { allMemberTotalPointCalculator, allTotalPointCalculator } from '@/libs/calculator-total-point'

const validationErrorInitial = {
  projectName: '',
  clientName: '',
  description: '',
  headquarterId: '',
  departmentId: '',
  evaluator: '',
  manager: '',
  evaluationIndex: '',
  inHouseCost: '',
  competition: '',
  projectStatus: '',
  evaluationDate: '',
}

export const ProjectEdit: React.FC<{}> = () => {
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationIndexs, masterInHouseCosts } = useContext<any>(MasterContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validationError, setValidationError] = useState(validationErrorInitial)
  const [membersValidationError, setMembersValidationError] = useState<string[]>([])
  const [project, setProject] = useState<any>([])
  const [projectMembers, setProjectMembers] = useState<any>([])
  const [projectName, setProjectName] = useState('')
  const [clientName, setClientName] = useState('')
  const [description, setDescription] = useState('')
  const [evaluationIndex, setEvaluationIndex] = useState('')
  const [competition, setCompetition] = useState('0')
  const [competitionEdit, setCompetitionEdit] = useState('0')
  const [inHouseCost, setInHouseCost] = useState('0')
  const [totalPoint, setTotalPoint] = useState('0')
  const [isInHouseCost, setIsInHouseCost] = useState<boolean>(false)
  const [projectStatus, setProjectStatus] = useState('')
  const [evaluationDate, setEvaluationDate] = useState<string>('')
  const [beginDate, setBeginDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(
    dayjs()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
  )
  // プロジェクトに追加しているメンバーIDの除外リスト
  const [excludeUserIds, setExcludeUserIds] = useState<string[]>([])
  const [managerSplitActive, setManagerSplitActive] = useState(Boolean)

  const _setManagerSplitActive = () => {
    let confirmResult = true
    // 設定解除する場合、確認ダイアログを表示
    if (managerSplitActive) {
      confirmResult = window.confirm('プロジェクト責任者の設定が削除されます。よろしいですか？')
    }
    if (confirmResult) {
      const _projectMembers = [...projectMembers]
      // プロジェクト責任者追加の場合
      if (!managerSplitActive) {
        _projectMembers[0].role = 'evaluator'
        _projectMembers[1] = {
          user_id: '',
          headquarter_id: '',
          department_id: '',
          position_id: '',
          member_index: 1,
          begin_date: _projectMembers[0].begin_date,
          end_date: _projectMembers[0].end_date,
          role: 'manager',
        }
      }
      // プロジェクト責任者削除の場合
      if (managerSplitActive) {
        _projectMembers[0].role = 'evaluatorAndManager'
        _projectMembers[1] = []
      }
      setProjectMembers(_projectMembers)
      setManagerSplitActive(!managerSplitActive)
    }
  }

  const _changeRangeDate = (beginDate, endDate) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  const memberHandleChange = (e, index) => {
    const _projectMembers = [...projectMembers]

    if (e.target.name === 'headquarterId') {
      _projectMembers[index].headquarter_id = e.target.value
    }

    if (e.target.name === 'departmentId') {
      _projectMembers[index].department_id = e.target.value
    }

    if (e.target.name === 'positionId') {
      _projectMembers[index].position_id = e.target.value
    }

    if (e.target.name === 'userId') {
      _projectMembers[index].user_id = e.target.value
    }
    setProjectMembers(_projectMembers)
  }

  const _memberChangeRangeDate = (beginDate, endDate, index) => {
    const _projectMembers = [...projectMembers]
    _projectMembers[index].begin_date = beginDate
    _projectMembers[index].end_date = endDate
    setProjectMembers(_projectMembers)
  }

  const _addMembers = () => {
    const _projectMembers = [...projectMembers]
    _projectMembers.push({
      user_id: '',
      headquarter_id: '',
      department_id: '',
      position_id: '',
      member_index: _projectMembers.length,
      begin_date: '',
      end_date: '',
      role: 'other',
    })
    setProjectMembers(_projectMembers)
  }

  const _removeMembers = index => {
    const confirmResult = window.confirm('スタッフを削除しますか？')
    if (confirmResult) {
      const _projectMembers = [...projectMembers]
      const _reIndexMembers = _projectMembers.filter((_member, i) => i !== index)
      setProjectMembers(_reIndexMembers)
    }
  }

  const handleChange = e => {
    if (e.target.name === 'projectName') {
      setProjectName(e.target.value)
    }

    if (e.target.name === 'clientName') {
      setClientName(e.target.value)
    }

    if (e.target.name === 'description') {
      setDescription(e.target.value)
    }

    if (e.target.name === 'projectStatus') {
      setProjectStatus(e.target.value)
    }

    if (e.target.name === 'evaluationIndex') {
      setEvaluationIndex(e.target.value)
      const _projectEvaluationTarget = masterEvaluationIndexs.find(item => item.evaluation_index_id === e.target.value)
      // 自動的に倍率が変更される
      setCompetition(_projectEvaluationTarget.competition)
      // 倍率手動変更はリセット
      setCompetitionEdit('0')
      setIsInHouseCost(false)
      // 内製費ID
      if (e.target.value === '77a48080-b6da-d304-99a9-151564f97373') {
        setIsInHouseCost(true)
      }
    }

    if (e.target.name === 'competition') {
      // 倍率手動変更時
      setCompetitionEdit(e.target.value)
    }

    if (e.target.name === 'inHouseCost') {
      setInHouseCost(e.target.value)
    }
  }

  const setDate = date => {
    setEvaluationDate(dayjs(date).format('YYYY-MM-DD'))
  }

  const goToProjects = projectId => {
    history.push({
      pathname: `/project`,
    })
  }

  const goToProjectDetail = projectId => {
    history.push({
      pathname: `/project/detail`,
      search: `?projectId=${projectId}`,
    })
  }

  const _fetchProjectDetail = async () => {
    await fetchProjectDetail(query.projectId).then(data => {
      // @ts-ignore
      setProject(data.data['projects'][0])
      const _projectMembers:any[] = []
      // index 1~2 はプロジェクト責任者、index 3~ はスタッフ
      // @ts-ignore
      data.data['project_members'].forEach((member) => {
        // 1人目と3人目以降はそのまま追加する
        if (parseInt(member.member_index) !== 1) {
          _projectMembers[member.member_index] = member
        }
        // 2人目
        if (parseInt(member.member_index) === 1) {
          // 2人目が責任者の場合、責任者を追加
          if (member.role === 'manager') {
            _projectMembers[member.member_index] = member
          }

          // 2人目が責任者でない場合、空のままにする
          if (member.role !== 'manager') {
            _projectMembers[1] = []
            _projectMembers[member.member_index] = member
          }
        }
      })
      setProjectMembers(_projectMembers)
      setIsLoading(false)
    })
  }

  const _validate = () => {
    let error = false
    const _validationError = { ...validationError }
    // プロジェクト名
    if (projectName === '') {
      _validationError.projectName = 'プロジェクト名を入力してください'
      error = true
    }
    if (projectName !== '') {
      _validationError.projectName = ''
    }

    // クライアント名
    if (clientName === '') {
      _validationError.clientName = 'クライアント名を入力してください'
      error = true
    }
    if (clientName !== '') {
      _validationError.clientName = ''
    }

    // プロジェクト概要
    if (description === '') {
      _validationError.description = 'プロジェクト概要を入力してください'
      error = true
    }
    if (description !== '') {
      _validationError.description = ''
    }

    // プロジェクト評価指標
    if (evaluationIndex === '') {
      _validationError.evaluationIndex = 'プロジェクト評価指標を入力してください'
      error = true
    }
    if (evaluationIndex !== '') {
      _validationError.evaluationIndex = ''
    }

    // プロジェクト評価指標(金額)
    if (evaluationIndex === '77a48080-b6da-d304-99a9-151564f97373') {
      if (inHouseCost === '' || inHouseCost === '0' || !inHouseCost.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
        _validationError.inHouseCost = '金額に正しい数値を入力してください'
        error = true
      }
      if (inHouseCost !== '' && inHouseCost !== '0' && inHouseCost.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
        _validationError.inHouseCost = ''
      }
    }

    // プロジェクト評価指標(倍率)
    if (competition === '' || !competition.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
      _validationError.competition = '倍率に正しい数値を入力してください'
      error = true
    }
    if (competition !== '' && competition.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
      _validationError.competition = ''
    }

    // プロジェクトステータス
    if (projectStatus === '' || projectStatus === '選択') {
      _validationError.projectStatus = 'ステータスを設定して下さい'
      error = true
    }
    if (projectStatus !== '' && projectStatus !== '選択') {
      _validationError.projectStatus = ''
    }

    // 評価年月日
    if (evaluationDate === '') {
      _validationError.evaluationDate = '評価年月日を設定してください'
      error = true
    }
    if (evaluationDate !== '') {
      _validationError.evaluationDate = ''
    }

    setValidationError(_validationError)
    return error
  }

  const _membersValidate = (projectMembers) => {
    let error = false
    const _validationError: string[] = []

    projectMembers.forEach((member, index) => {
      if (member.user_id === '' || member.position_id === '' || member.begin_date === '' || member.end_date === '') {
        _validationError[index] = 'スタッフ情報を正しく設定してください'
        error = true
      }
      if (member.user_id !== '' && member.position_id !== '' && member.begin_date !== '' && member.end_date !== '') {
        _validationError[index] = ''
      }
    })

    setMembersValidationError(_validationError)
    return error
  }

  const _removeProject = async () => {
    const confirmResult = window.confirm('プロジェクトを削除しますか？')
    if (confirmResult) {
      setIsLoading(true)
      await projectDelete(
        // @ts-ignore
        project?.project_id,
      ).then(e => {
        setIsLoading(false)
        alert('プロジェクトの削除完了しました。')
        // @ts-ignore
        goToProjects()
      })
    }
  }

  const _editProject = async () => {
    // メンバーのインデックスを整理する
    const _projectMembers:any = []
    projectMembers.forEach((member) => {
      if (!member?.user_id) return
      if (!!member.user_id && !!member.position_id && !!member.begin_date && member.end_date) {
        _projectMembers.push(member)
      }
    })
    if (_validate() || _membersValidate(_projectMembers)) {
      alert('入力内容に誤りがあります')
      return
    }
    setIsLoading(true)
    // 評価ステータスチェック
    let _evaluationStatus = 'registered'
    _projectMembers.forEach((member) => {
      if (member.total_point === '0.00' || !member.total_point) {
        _evaluationStatus = 'nonRegistered'
      }
    })

    await projectEdit(
      // @ts-ignore
      project?.project_id,
      // @ts-ignore
      projectName,
      // @ts-ignore
      clientName,
      // @ts-ignore
      description,
      // @ts-ignore
      beginDate,
      // @ts-ignore
      endDate,
      // @ts-ignore
      evaluationIndex,
      // @ts-ignore
      inHouseCost,
      // @ts-ignore
      competition,
      // @ts-ignore
      competitionEdit,
      evaluationDate,
      totalPoint,
      // @ts-ignore
      projectStatus,
      _evaluationStatus,
      _projectMembers,
    ).then(e => {
      // @ts-ignore
      goToProjectDetail(project?.project_id)
    })
  }

  useEffect(() => {
    // 入力した金額に合わせて倍率を変更する
    masterInHouseCosts.forEach(masterInHouseCost => {
      if (parseInt(inHouseCost) > parseInt(masterInHouseCost.begin_price)) {
        setCompetition(masterInHouseCost.competition)
      }
    })
  }, [inHouseCost])

  useEffect(() => {
    if (competition && projectMembers.length !== 0) {
      const _projectMembers = allMemberTotalPointCalculator(competitionEdit !== '0' ? competitionEdit : competition, projectMembers)
      const _totalPoint = allTotalPointCalculator(_projectMembers)
      setTotalPoint(_totalPoint)
      setProjectMembers(_projectMembers)
    }
  }, [competition])

  useEffect(() => {
    if (projectMembers) {
      // メンバーID除外リスト
      const _excludeUserIds:string[] = []

      // 評価者、プロジェクト責任者の設定
      projectMembers?.forEach(member => {
        if (!member?.user_id) return
        if (member?.role === 'manager') {
          // 評価者、プロジェクト責任者が別々に存在する為
          setManagerSplitActive(true)
        }
        // メンバーID除外リスト設定
        _excludeUserIds.push(member.user_id)
      })

      setExcludeUserIds(_excludeUserIds)
    }
  }, [projectMembers])

  useEffect(() => {
    // ログイン済みか判定する
    if (project) {
      setProjectName(project?.project_name)
      setClientName(project?.client_name)
      setDescription(project?.description)
      setBeginDate(project?.begin_date)
      setEndDate(project?.end_date)
      setEvaluationDate(project?.evaluation_date)
      setEvaluationIndex(project?.evaluation_index_id)
      setCompetition(project?.evaluation_index_competition)
      setCompetitionEdit(project?.evaluation_index_competition_edit)
      setProjectStatus(project?.status)
      setTotalPoint(project?.point)
      if (project?.evaluation_index_id === '77a48080-b6da-d304-99a9-151564f97373') {
        setIsInHouseCost(true)
      }
      setInHouseCost(project?.evaluation_index_price)
    }
  }, [project])

  useEffect(() => {
    setIsLoading(true)
    // ログイン済みか判定する
    if (auth) {
      // 3等級以下はリダイレクト
      if (auth.rate <= 3) {
        history.push({
          pathname: `/`,
        })
      }
      _fetchProjectDetail()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="project-register" />
        <div css={containerStyle}>
          <PageTitle title={'プロジェクト詳細'} />
          <div css={mainContentsStyle}>
            <div css={topButtonWrapperStyle}>
              <BackButton onClick={() => goToProjectDetail(project?.project_id)} name={'詳細へ戻る'} />
            </div>
            <ManagerRegisterList
              handleChange={memberHandleChange}
              headquarterId={projectMembers[0]?.headquarter_id}
              departmentId={projectMembers[0]?.department_id}
              userId={projectMembers[0]?.user_id}
              positionId={projectMembers[0]?.position_id}
              responsibleHeadquarterId={projectMembers[1]?.headquarter_id}
              responsibleDepartmentId={projectMembers[1]?.department_id}
              responsibleUserId={projectMembers[1]?.user_id}
              responsiblePositionId={projectMembers[1]?.position_id}
              managerSplitActive={managerSplitActive}
              setManagerSplitActive={_setManagerSplitActive}
              validationError={validationError}
              excludeUserIds={excludeUserIds}
            />
            <ProjectEditCard
              removeProject={_removeProject}
              handleChange={handleChange}
              project={{
                project_id: project?.project_id,
                project_no: project?.project_no,
                projectName,
                clientName,
                description,
                projectStatus,
              }}
              isInHouseCost={isInHouseCost}
              evaluationIndex={evaluationIndex}
              competition={competitionEdit !== '0' ? competitionEdit : competition}
              inHouseCost={inHouseCost}
              beginDate={beginDate}
              endDate={endDate}
              evaluationDate={evaluationDate}
              setDate={date => setDate(date)}
              changeRangeDate={_changeRangeDate}
              validationError={validationError}
            />
            <div css={mainListContainerStyle}>
              <ul css={mainListHeaderStyle}>
                <li css={list1Style}>本部</li>
                <li css={list2Style}>部署</li>
                <li css={list3Style}>スタッフ名</li>
                <li css={list4Style}>役割</li>
                <li css={list5Style}>アサイン期間</li>
              </ul>
              <ul css={mainListContentsStyle}>
                {projectMembers.length !== 0 &&
                  projectMembers.map((member, index) => {
                    if (member?.role !== 'other') return <></>
                    return (
                      <StaffEditListContent
                        member={member}
                        index={index}
                        key={index}
                        handleChange={memberHandleChange}
                        changeRangeDate={_memberChangeRangeDate}
                        removeMembers={_removeMembers}
                        minDate={beginDate}
                        maxDate={endDate}
                        validationError={membersValidationError[index]}
                        excludeUserIds={excludeUserIds}
                      />
                    )
                  })}
              </ul>
            </div>
            <div css={addStaffStyle} onClick={_addMembers}>
              <img css={addIconStyle} src={require('@/static/images/add_blue.svg')} />
              スタッフを追加する
            </div>
            <div css={saveButtonWrapperStyle}>
              <Button label={'保存'} onClick={_editProject} />
            </div>
            {isLoading && (
              <div css={loadingOverlayStyle}>
                <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
  position: 'relative',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '24px 34px',
  backgroundColor: '#EDF2F9',
})

const topButtonWrapperStyle = css({
  marginBottom: '26px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const addStaffStyle = css({
  margin: '30px 0 80px',
  width: '100%',
  color: '#004CAD',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '9px 0',
  backgroundColor: '#FFFFFF',
  border: '1px solid #004CAD',
  borderRadius: '6px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})

const addIconStyle = css({
  marginRight: '4px',
  width: '24px',
})

const mainListContainerStyle = css({
  display: 'grid',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  maxHeight: '480px',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  height: '420px',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  '.list': {
    padding: '20px 16px',
    borderBottom: '1px solid #DDDDDD',
  },
})

const list1Style = css({
  width: '200px',
})

const list2Style = css({
  width: '320px',
})

const list3Style = css({
  width: '200px',
})

const list4Style = css({
  width: '120px',
})

const list5Style = css({
  width: '350px',
  overflow: 'initial',
})

const saveButtonWrapperStyle = css({
  margin: '0 0 80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
