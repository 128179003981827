import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { BackButton } from '@/Components/Advanced/backButton'
import { EditButton } from '@/Components/Advanced/editButton'
import { EvaluationRegisterButton } from '@/Components/Advanced/evaluationRegisterButton'
import { ManagerCard } from '@/Components/Advanced/managerCard'
import { StatusCard } from '@/Components/Advanced/statusCard'
import { ProjectDetailCard } from '@/Components/Pages/Project/_EvaluationDetail/card'
import { EvaluationDetailList } from '@/Components/Pages/Project/_EvaluationDetail/list'
import ReactLoading from 'react-loading'

// apis
import { fetchProjectDetail, fetchProjectsEvaluatorMembers } from '@/Apis/scopeProjectRecorder'

// components
import { AuthContext } from '@/Contexts/auth'

export const ProjectEvaluationDetail: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const [project, setProject] = useState<any>([])
  const [projectMembers, setProjectMembers] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const goToBack = () => {
    if (query.ref === 'evaluationDetail') {
      history.goBack()
    }
    history.push({
      pathname: `/project/evaluation`,
    })
  }

  const goToEvaluationRegister = () => {
    history.push({
      pathname: `/project/evaluation/register`,
      search: `?projectId=${query.projectId}`,
    })
  }

  const goToEdit = () => {
    history.push({
      pathname: `/project/evaluation/edit`,
      search: `?projectId=${query.projectId}`,
    })
  }

  const _fetchProjectDetail = async () => {
    await fetchProjectDetail(query.projectId).then(data => {
      // @ts-ignore
      setProject(data.data['projects'][0])
      // @ts-ignore
      setProjectMembers(data.data['project_members'])
      setIsLoading(false)
    })
  }

  const _visibleEvaluationRegisterMenu = () => {
    if (project.is_evaluation_point === 1) {
      return false
    }
    // メンバー権限（1/2/3等級）のアカウントでのログイン後、「評価登録」ボタンが表示されない
    if (auth.rate === 4 || auth.rate === 5) {
      // 自信が評価しているプロジェクトのみ「評価登録」を表示
      if (projectMembers.length !== 0) {
        if (projectMembers[0].user_id === auth.user_id) {
          return true
        }
        if (projectMembers[1].role !== 'other' && projectMembers[1].user_id === auth.user_id) {
          return true
        }
      }
    }
    if (auth.rate === 6 || auth.rate === 7 || auth.rate === 8) {
      return true
    }
    return false
  }

  const _visibleEditMenu = () => {
    if (project.is_evaluation_point === 1) {
      return false
    }
    if (auth) {
      // 評価者（4/5等級）のアカウントでのログイン時に「プロジェクト編集」が表示される
      // 評価者以外の4/5等級のアカウントでのログイン時には「プロジェクト編集」が表示されない
      if (auth.rate === 4 || auth.rate === 5) {
        // 評価者のIDがprojectsEvaluatorMembersに含まれている場合は「プロジェクト編集」を表示
        if (projectMembers.length !== 0) {
          if (projectMembers[0].user_id === auth.user_id) {
            return true
          }
          if (projectMembers[1].role !== 'other' && projectMembers[1].user_id === auth.user_id) {
            return true
          }
        }
      }

      // 管理者権限（6/7/8等級）のアカウントでのログイン時に「プロジェクト編集」が表示される
      if (auth.rate === 6 || auth.rate === 7 || auth.rate === 8) {
        return true
      }
    }

    // メンバー権限（1/2/3等級）のアカウントでのログイン時に「プロジェクト編集」が表示されない
    return false
  }

  const _fetchProjectsEvaluatorMembers = async () => {
    await fetchProjectsEvaluatorMembers().then(data => {
      const _projectsEvaluatorMembersIds = []
      // @ts-ignore
      data.data.evaluator_members.forEach(evaluator_member => {
        // @ts-ignore
        _projectsEvaluatorMembersIds.push(evaluator_member.user_id)
      })
      // @ts-ignore
      setIsLoading(false)
    })
  }

  useEffect(() => {

    // ローディングをやめる
    setIsLoading(true)

    // ログイン済みか判定する
    if (auth) {
      _fetchProjectDetail()
      _fetchProjectsEvaluatorMembers()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="project-evaluation" />
        <div css={containerStyle}>
          <PageTitle title={'プロジェクト評価詳細'} />
          <div css={mainContentsStyle}>
            <div css={topButtonWrapperStyle}>
              <BackButton onClick={goToBack} />
              <div css={topRightButtonWrapperStyle}>
                {_visibleEvaluationRegisterMenu() && <EvaluationRegisterButton onClick={goToEvaluationRegister} />}
                {_visibleEditMenu() && <EditButton onClick={goToEdit} />}
              </div>
            </div>
            <div css={managerRowStyle}>
              <ManagerCard
                rate={projectMembers.length ? projectMembers[0].rate : 1}
                position={'評価者'}
                name={`${projectMembers.length ? projectMembers[0].last_name + ' ' + projectMembers[0].first_name : ''}`}
              />
              {projectMembers.length !== 0 && (
                <ManagerCard
                  rate={projectMembers[0].role === 'evaluatorAndManager' ? projectMembers[0].rate : projectMembers[1].rate}
                  position={'プロジェクト責任者'}
                  name={`${
                    projectMembers[0].role === 'evaluatorAndManager'
                      ? projectMembers[0].last_name + ' ' + projectMembers[0].first_name
                      : projectMembers[1].last_name + ' ' + projectMembers[1].first_name
                  }`}
                />
              )}
              <StatusCard status={project.evaluation_status === 'registered' ? '完了' : '未登録'} />
            </div>
            <ProjectDetailCard project={project} />
            <EvaluationDetailList projectMembers={projectMembers} />
            {isLoading && (
              <div css={loadingOverlayStyle}>
                <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '24px 34px',
  backgroundColor: '#EDF2F9',
  position: 'relative',
})

const topButtonWrapperStyle = css({
  marginBottom: '26px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const topRightButtonWrapperStyle = css({
  display: 'flex',
  div: {
    marginRight: '12px',
  },
  '&:last-child': {
    marginRight: 0,
  },
})

const managerRowStyle = css({
  marginBottom: '54px',
  minWidth: '582px',
  display: 'flex',
  justifyContent: 'flex-start',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
