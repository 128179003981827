import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { BackButton } from '@/Components/Advanced/backButton'
import { CardPasswordField } from '@/Components/Advanced/cardPasswordField'
import { CardTextDataField } from '@/Components/Advanced/cardTextDataField'
import { Button } from '@/Components/Advanced/button'
import ReactLoading from 'react-loading'

// apis
import { fetchMasterUserDetail, passwordEdit } from '@/Apis/scopeProjectRecorder'

// components
import { AuthContext } from '@/Contexts/auth'

const validationErrorInitial = {
  password: '',
  passwordConfirm: '',
}

export const AccountDetail: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const [masterUsers, setMasterUsers] = useState<any>([])
  const [validationError, setValidationError] = useState(validationErrorInitial)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _fetchMasterUserDetail = async () => {
    await fetchMasterUserDetail(query.user_id).then(data => {
      // @ts-ignore
      setMasterUsers(data.data.users[0])
      setIsLoading(false)
    })
  }

  const handleChange = e => {
    if (e.target.name === 'password') {
      setPassword(e.target.value)
    }
    if (e.target.name === 'passwordConfirm') {
      setPasswordConfirm(e.target.value)
    }
  }

  const _validate = () => {
    let error = false
    const _validationError = { ...validationError }
    if (password === '') {
      _validationError.password = 'パスワードを入力してください'
      error = true
    }
    if (password !== '') {
      _validationError.password = ''
    }

    if (passwordConfirm === '') {
      _validationError.passwordConfirm = 'パスワード(確認)を入力してください'
      error = true
    }
    if (passwordConfirm !== '') {
      _validationError.passwordConfirm = ''
    }

    if (password !== passwordConfirm) {
      _validationError.passwordConfirm = 'パスワードが一致していません'
      error = true
    }
    if (password === passwordConfirm) {
      _validationError.passwordConfirm = ''
    }

    setValidationError(_validationError)
    return error
  }

  const goToAccounts = () => {
    history.push({
      pathname: `/admin/account`,
    })
  }

  const _editPassword = async () => {
    if (_validate()) {
      alert('入力内容に誤りがあります')
      return
    }
    setIsLoading(true)
    await passwordEdit(
      // @ts-ignore
      masterUsers?.user_id,
      password,
    ).then(e => {
      // @ts-ignore
      alert('パスワードの変更完了しました。')
      setIsLoading(false)
    })
  }

  const _userName = () => {
    if (masterUsers.length === 0) return ''
    return `${masterUsers.last_name} ${masterUsers.first_name} (${masterUsers.last_name_kana} ${masterUsers.first_name_kana})`
  }

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      setIsLoading(true)
      _fetchMasterUserDetail()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="admin" />
        <div css={containerStyle}>
          <PageTitle title={'アカウント一覧'} />
          <div css={mainTopContentsStyle}>
            <div />
          </div>
          <div css={mainContentsStyle}>
            <div css={topButtonWrapperStyle}>
              <BackButton onClick={goToAccounts} />
            </div>
            <div css={cardStyle}>
              <div css={cardTitleStyle}>基本情報</div>
              <div css={cardBorderStyle}></div>
              <div css={cardDataRowStyle}>{masterUsers && <CardTextDataField label={'氏名'} value={_userName()} />}</div>
              <div css={cardDataRowStyle}>{masterUsers && <CardTextDataField label={'メールアドレス'} value={masterUsers.email} />}</div>
              <div css={cardDataRowStyle}>
                <CardPasswordField
                  label={'パスワード'}
                  value={password}
                  name={'password'}
                  onChange={handleChange}
                  validation={validationError.password}
                />
              </div>
              <div css={cardDataRowStyle}>
                <CardPasswordField
                  label={'パスワード(確認)'}
                  value={passwordConfirm}
                  name={'passwordConfirm'}
                  onChange={handleChange}
                  validation={validationError.passwordConfirm}
                />
              </div>
            </div>
            <div css={saveButtonWrapperStyle}>
              <Button label={'パスワードを変更'} onClick={_editPassword} />
            </div>
          </div>
        </div>
        {isLoading && (
          <div css={loadingOverlayStyle}>
            <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
          </div>
        )}
      </div>
    </>
  )
}

const cardStyle = css({
  marginBottom: '54px',
  width: '100%',
  padding: '34px 26px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
})

const cardTitleStyle = css({
  color: '#151619',
  fontSize: '16px',
  fontWeight: 'bold',
})

const cardBorderStyle = css({
  margin: '34px 0',
  width: '100%',
  borderBottom: '2.5px solid #DDDDDD',
})

const cardDataRowStyle = css({
  margin: '0 0 35px',
  width: '100%',
  display: 'flex',
  '.field': {
    margin: '0 36px 0px 0',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const topButtonWrapperStyle = css({
  marginBottom: '26px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})

const saveButtonWrapperStyle = css({
  margin: '0 0 80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})
