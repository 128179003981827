import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { CardSelectField } from '@/Components/Advanced/cardSelectField'
import { CardTextField } from '@/Components/Advanced/cardTextField'
import { CardTextDataField } from '@/Components/Advanced/cardTextDataField'
import { CardTextAreaField } from '@/Components/Advanced/cardTextAreaField'
import { CardProjectEvaluationSelectField } from '@/Components/Advanced/cardProjectEvaluationSelectField'
import { CardCalenderField } from '@/Components/Advanced/cardCalenderField'
import { CardSingleCalenderField } from '@/Components/Advanced/cardSingleCalenderField'

// contexts
import { MasterContext } from '@/Contexts/master'

type Props = {
  isInHouseCost: boolean
  evaluationIndex: string
  competition: string
  inHouseCost: string
  project: {
    projectName: string
    clientName: string
    description: string
    projectStatus: string
  }
  beginDate: string
  endDate: string
  evaluationDate: string
  validationError: any
  setDate: (date) => void
  changeRangeDate?: (beginDate: string, endDate: string) => void
  handleChange?: (e) => void
}

export const ProjectRegisterCard: React.FC<Props> = ({
  project,
  isInHouseCost,
  handleChange,
  changeRangeDate,
  evaluationIndex,
  competition,
  inHouseCost,
  beginDate,
  endDate,
  setDate,
  evaluationDate,
  validationError,
}) => {
  const { masterEvaluationIndexs, masterProjectStatus } = useContext<any>(MasterContext)
  return (
    <div css={cardStyle}>
      <div css={cardTitleStyle}>基本情報</div>
      <div css={cardBorderStyle}></div>
      <div css={cardDataRowStyle}>
        <CardTextDataField label={'管理ナンバー'} value={'自動的に生成されます'} width={'400px'} />
        <CardTextField
          label={'クライアント名称'}
          value={project.clientName}
          name={'clientName'}
          onChange={handleChange}
          validation={validationError.clientName}
        />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextField
          label={'プロジェクト名'}
          value={project.projectName}
          name={'projectName'}
          onChange={handleChange}
          validation={validationError.projectName}
        />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextAreaField
          label={'プロジェクト概要'}
          value={project.description}
          name={'description'}
          onChange={handleChange}
          validation={validationError.description}
        />
      </div>
      <div css={cardDataRowStyle}>
        <CardCalenderField label={'プロジェクト期間'} beginDate={beginDate} endDate={endDate} changeRangeDate={changeRangeDate} />
        <CardProjectEvaluationSelectField
          isInHouseCost={isInHouseCost}
          masterEvaluationIndexs={masterEvaluationIndexs}
          handleChange={handleChange}
          evaluationIndex={evaluationIndex}
          competition={competition}
          inHouseCost={inHouseCost}
          validationEvaluationIndex={validationError.evaluationIndex}
          validationCompetition={validationError.competition}
          validationInHouseCost={validationError.inHouseCost}
        />
      </div>
      <div css={cardDataRowStyle}>
        <CardSelectField
          label={'ステータス'}
          name={'projectStatus'}
          onChange={handleChange}
          value={project.projectStatus}
          data={masterProjectStatus}
          validation={validationError.projectStatus}
        />
        <CardSingleCalenderField
          label={'評価年月日'}
          date={evaluationDate}
          setDate={date => setDate(date)}
          validation={validationError.evaluationDate}
        />
      </div>
    </div>
  )
}
const cardStyle = css({
  marginBottom: '54px',
  width: '100%',
  padding: '34px 26px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
})

const cardTitleStyle = css({
  color: '#151619',
  fontSize: '16px',
  fontWeight: 'bold',
})

const cardBorderStyle = css({
  margin: '34px 0',
  width: '100%',
  borderBottom: '2.5px solid #DDDDDD',
})

const cardDataRowStyle = css({
  margin: '0 0 35px',
  width: '100%',
  display: 'flex',
  '.field': {
    margin: '0 36px 0px 0',
    '&:last-child': {
      marginRight: 0,
    },
  },
})
