import React from 'react'
import { css } from '@emotion/core'

type CardTextAreaDataFieldProps = {
  label?: string
  width?: string
  placeholder?: string
  value?: string
}

export const CardTextAreaDataField: React.FC<CardTextAreaDataFieldProps> = ({
  label = '',
  placeholder = '',
  value = '',
  width = '100%',
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle} htmlFor={label}>
        {label}
      </label>
      <div css={inputStyle}>{value}</div>
    </div>
  )
}

const wrapperStyle = css({})

const labelStyle = css({
  width: '100%',
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#004CAD',
})

const inputStyle = css({
  width: '100%',
  height: 'auto',
  border: 'none',
  borderBottom: '1px solid #DDDDDD',
  backgroundColor: '#FFFFFF',
  color: '#151619',
  fontSize: '13px',
  margin: '6px 0 0',
  padding: '6px 6px 12px',
  whiteSpace: 'break-spaces',
  overflow: 'hidden',
  '&::placeholder': {
    color: '#B1B1B1',
  },
})
