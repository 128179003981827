import React, { useState } from 'react'
import { css } from '@emotion/core'
import { SingleDatePicker, CalendarDay, isInclusivelyAfterDay } from 'react-dates'
import moment from 'moment'

type DatePickerProps = {
  label: string
  date: string
  setDate: any
  confirmedDay?: string
  width?: string
  validation?: string
}

export const CardSingleCalenderField: React.FC<DatePickerProps> = ({ label, date, setDate, confirmedDay, width = '340px', validation }) => {
  const [calendarFocused, setCalendarFocused] = useState<boolean>(false)
  return (
    <div css={[validation ? wrapperErrorStyle : wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle}>{label}</label>
      <SingleDatePicker
        date={date ? moment(date) : null}
        onDateChange={date => date && setDate(date)}
        focused={calendarFocused}
        onFocusChange={v => setCalendarFocused(Boolean(v.focused))}
        numberOfMonths={1}
        isOutsideRange={() => false}
        isDayHighlighted={date => !isInclusivelyAfterDay(date, moment(confirmedDay).add(1, 'days'))}
        displayFormat="YYYY年MM月DD日"
        monthFormat={'YYYY年MM月DD日'}
        id="date"
        placeholder="年月日"
        hideKeyboardShortcutsPanel={true}
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day.format('D')}</span>
              </div>
            )}
          />
        )}
      />
      <br />
      <span css={validationErrorMessageStyle}>{validation}</span>
    </div>
  )
}

const wrapperStyle = css({
  width: '340px',
  '.SingleDatePickerInput__withBorder': {
    borderRadius: '6px',
    height: 'auto',
    backgroundColor: '#EDF2F9',
    padding: '14px 22px',
    border: '0px solid #FFFFFF',
  },
})

const wrapperErrorStyle = css(wrapperStyle, {
  '.SingleDatePickerInput__withBorder': {
    border: '1px solid #FF0000',
  },
})

const labelStyle = css({
  width: '100%',
  fontSize: '12px',
  fontWeight: 'bold',
  margin: '0 0 6px 0',
  display: 'block',
  color: '#004CAD',
})

const validationErrorMessageStyle = css({
  color: '#FF0000',
})
