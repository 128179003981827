export const csvDownload = (data, fileName) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const replace = data.map(row =>
    row.map(field => {
      if (typeof field === 'string') {
        return field.replace(/"/g, '""')
      } else {
        return field
      }
    }),
  )

  const csvData = replace.map(row => row.map(field => `"${field}"`)).join('\r\n')
  const blob = new Blob([bom, csvData], { type: 'text/csv' })
  const downloadLink = document.createElement('a')
  const url = URL.createObjectURL(blob)
  downloadLink.href = url
  downloadLink.download = fileName

  downloadLink.click()

  URL.revokeObjectURL(url)
}
