import React from 'react'
import { css } from '@emotion/core'

type CardProjectEvaluationSelectProps = {
  masterEvaluationIndexs?: any
  isInHouseCost: boolean
  evaluationIndex: string
  competition: string
  inHouseCost: string
  validationEvaluationIndex?: string
  validationCompetition?: string
  validationInHouseCost?: string
  handleChange?: (e) => void
}

export const CardProjectEvaluationSelectField: React.FC<CardProjectEvaluationSelectProps> = ({
  masterEvaluationIndexs,
  isInHouseCost,
  evaluationIndex,
  competition,
  inHouseCost,
  validationEvaluationIndex,
  validationCompetition,
  validationInHouseCost,
  handleChange,
}) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <div css={selectWrapperStyle}>
        <label css={labelStyle} htmlFor={'プロジェクト評価指標'}>
          プロジェクト評価指標
        </label>
        <select
          css={validationEvaluationIndex ? selectErrorStyle : selectStyle}
          id={'プロジェクト評価指標'}
          name={'evaluationIndex'}
          onChange={handleChange}
          value={evaluationIndex}
        >
          <option>選択</option>
          {masterEvaluationIndexs.map((item: any) => (
            <option key={item.evaluation_index_id} value={item.evaluation_index_id}>
              {item.name}
            </option>
          ))}
        </select>
        <span css={validationErrorMessageStyle}>{validationEvaluationIndex}</span>
      </div>
      <div css={inputWrapperStyle}>
        <label css={labelStyle} htmlFor={'competition'}>
          倍率
        </label>
        <input
          type={'text'}
          id={'competition'}
          css={validationCompetition ? inputErrorStyle : inputStyle}
          name={'competition'}
          placeholder={'0'}
          onChange={handleChange}
          value={competition}
        />
        <span css={validationErrorMessageStyle}>{validationCompetition}</span>
      </div>
      {isInHouseCost && (
        <div css={inputWrapperStyle}>
          <label css={labelStyle} htmlFor={'inHouseCost'}>
            金額
          </label>
          <input
            type={'text'}
            id={'inHouseCost'}
            css={validationInHouseCost ? inputErrorStyle : inputStyle}
            name={'inHouseCost'}
            placeholder={'0'}
            onChange={handleChange}
            value={inHouseCost}
          />
          <span css={validationErrorMessageStyle}>{validationInHouseCost}</span>
        </div>
      )}
    </div>
  )
}

const wrapperStyle = css({
  display: 'flex',
})

const selectWrapperStyle = css({
  width: '340px',
  marginRight: '36px',
})
const inputWrapperStyle = css({
  marginRight: '36px',
})

const labelStyle = css({
  width: '100%',
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#004CAD',
})

const selectStyle = css({
  appearance: 'none',
  width: '100%',
  border: 'none',
  borderRadius: '6px',
  backgroundColor: '#EDF2F9',
  color: '#151619',
  fontSize: '12px',
  margin: '6px 0 0',
  padding: '14px 30px 14px 22px',
  backgroundImage: `url(${require('@/static/images/arrow_down.svg')})`,
  backgroundPosition: 'right 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const inputStyle = css({
  width: '100%',
  border: 'none',
  borderRadius: '6px',
  backgroundColor: '#EDF2F9',
  color: '#151619',
  fontSize: '13px',
  margin: '6px 0 0',
  padding: '14px 22px',
  '&::placeholder': {
    color: '#B1B1B1',
  },
})

const selectErrorStyle = css(selectStyle, {
  width: '100%',
  border: '1px solid #FF0000',
})

const inputErrorStyle = css(inputStyle, {
  width: '100%',
  border: '1px solid #FF0000',
})

const validationErrorMessageStyle = css({
  color: '#FF0000',
})
