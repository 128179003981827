import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { CardTextDataField } from '@/Components/Advanced/cardTextDataField'
import { CardTextAreaDataField } from '@/Components/Advanced/cardTextAreaDataField'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// contexts
import { MasterContext } from '@/Contexts/master'

type Props = {
  project?: any
}

export const ProjectDetailCard: React.FC<Props> = ({ project }) => {
  const { masterProjectStatus } = useContext<any>(MasterContext)
  return (
    <div css={cardStyle}>
      <div css={cardTitleStyle}>基本情報</div>
      <div css={cardBorderStyle}></div>
      <div css={cardDataRowStyle}>
        <CardTextDataField label={'管理ナンバー'} value={project?.project_no} width={'200px'} />
        <CardTextDataField label={'クライアント名称'} value={project?.client_name} />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextDataField label={'プロジェクト名'} value={project?.project_name} />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextAreaDataField label={'プロジェクト概要'} value={project?.description} />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextDataField
          label={'プロジェクト期間'}
          value={`${dayjs(project?.begin_date).format('YYYY/MM/DD')}　〜　${dayjs(project?.end_date).format('YYYY/MM/DD')} (${dayjs(
            project?.end_date,
          ).diff(dayjs(project?.begin_date), 'day')}日)`}
          width={'320px'}
        />
        <CardTextDataField label={'プロジェクト評価指標'} value={project?.evaluation_index_name} width={'380px'} />
        <CardTextDataField label={'倍率'} value={project?.evaluation_index_competition_edit !== '0' ? project?.evaluation_index_competition_edit : project?.evaluation_index_competition} width={'80px'} />
        {parseInt(project?.evaluation_index_price) > 0 && (
          <CardTextDataField label={'金額'} value={parseInt(project?.evaluation_index_price).toLocaleString()} width={'180px'} />
        )}
      </div>
      <div css={cardDataRowStyle}>
        <CardTextDataField
          label={'ステータス'}
          value={masterProjectStatus.find(status => status.id === project?.status)?.name}
          width={'200px'}
        />
        <CardTextDataField
          label={'評価年月日'}
          value={`${project?.evaluation_date ? dayjs(project?.evaluation_date).format('YYYY年MM月DD日') : ''}`}
          width={'200px'}
        />
      </div>
    </div>
  )
}

const cardStyle = css({
  marginBottom: '54px',
  width: '100%',
  padding: '34px 26px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
})

const cardTitleStyle = css({
  color: '#151619',
  fontSize: '16px',
  fontWeight: 'bold',
})

const cardBorderStyle = css({
  margin: '34px 0',
  width: '100%',
  borderBottom: '2.5px solid #DDDDDD',
})

const cardDataRowStyle = css({
  margin: '0 0 35px',
  width: '100%',
  display: 'flex',
  '.field': {
    margin: '0 36px 0px 0',
    '&:last-child': {
      marginRight: 0,
    },
  },
})
