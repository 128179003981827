import React from 'react'
import { css } from '@emotion/core'

type CardSelectProps = {
  label?: string
  width?: string
  data?: any
  name?: string
  idName?: string
  value?: string
  validation?: string
  onChange?: (e) => void
}

export const CardSelectField: React.FC<CardSelectProps> = ({
  label = '',
  width = '200px',
  name = '',
  idName = 'id',
  data = [],
  value,
  validation = '',
  onChange,
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      {label && (
        <label css={labelStyle} htmlFor={label}>
          {label}
        </label>
      )}
      <select css={validation ? selectErrorStyle : selectStyle} id={label} name={name} onChange={onChange} value={value}>
        <option key={0}>選択</option>
        {data.map((item: any) => (
          <option key={item.id} value={item?.[idName]}>
            {item.name}
          </option>
        ))}
      </select>
      <span css={validationErrorMessageStyle}>{validation}</span>
    </div>
  )
}

const wrapperStyle = css({})

const labelStyle = css({
  width: '100%',
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#004CAD',
})

const selectStyle = css({
  appearance: 'none',
  width: '100%',
  border: 'none',
  borderRadius: '6px',
  backgroundColor: '#EDF2F9',
  color: '#151619',
  fontSize: '12px',
  margin: '6px 0 0',
  padding: '14px 22px',
  backgroundImage: `url(${require('@/static/images/arrow_down.svg')})`,
  backgroundPosition: 'right 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
})

const selectErrorStyle = css(selectStyle, {
  width: '100%',
  border: '1px solid #FF0000',
})

const validationErrorMessageStyle = css({
  color: '#FF0000',
})
