import React from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// libs
import { accountLevel } from '@/libs/account-level'

type Props = {
  projects?: any
  sortKey: string
  sortType: 'ASC' | 'DESC'
  sort: (key: string) => void
}

export const ProjectEvaluationList: React.FC<Props> = ({ projects, sortKey, sortType, sort }) => {
  const history = useHistory()

  const goToProjectEvaluationDetail = (project_id = '') => {
    history.push({
      pathname: `/project/evaluation/detail`,
      search: `?projectId=${project_id}`,
    })
  }

  const _recordStyle = project => {
    if (project.is_evaluation_point === 1) return listEvaluationCompletedStyle
    if (project.evaluation_status === 'registered') return listCompletedStyle
    return
  }

  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  return (
    <div css={mainListContainerStyle}>
      <ul css={mainListHeaderStyle}>
        <li css={list1Style} onClick={() => sort('client_name')}>
          クライアント名称
          {sortKey === 'client_name' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list2Style} onClick={() => sort('project_name')}>
          プロジェクト名
          {sortKey === 'project_name' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list3Style} onClick={() => sort('begin_date')}>
          開始日
          {sortKey === 'begin_date' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list4Style} onClick={() => sort('end_date')}>
          終了日
          {sortKey === 'end_date' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list12Style} onClick={() => sort('project_days')}>
          プロジェクト日数
          {sortKey === 'project_days' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list5Style} onClick={() => sort('evaluation_status')}>
          評価登録
          {sortKey === 'evaluation_status' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list6Style} onClick={() => sort('status')}>
          ステータス
          {sortKey === 'status' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list7Style} onClick={() => sort('last_name')}>
          評価者
          {sortKey === 'last_name' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list8Style} onClick={() => sort('evaluation_index_name')}>
          評価指標
          {sortKey === 'evaluation_index_name' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list9Style} onClick={() => sort('evaluation_index_competition')}>
          倍率
          {sortKey === 'evaluation_index_competition' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list10Style} onClick={() => sort('project_members_count')}>
          スタッフ数
          {sortKey === 'project_members_count' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
        <li css={list11Style} onClick={() => sort('point')}>
          合計点数
          {sortKey === 'point' && (
            <span>
              <img css={accountIconStyle} src={require(`@/static/images/sort-arrow-${sortType}.svg`)} />
            </span>
          )}
        </li>
      </ul>
      <ul css={mainListContentsStyle}>
        {projects.map((project: any) => (
          <li css={_recordStyle(project)} onClick={() => goToProjectEvaluationDetail(project.project_id)} key={project.project_id}>
            <div css={[listItemStyle, list1Style]}>
              <div css={overflowText}>{project.client_name}</div>
            </div>
            <div css={[listItemStyle, list2Style]}>
              <div css={overflowText}>{project.project_name}</div>
            </div>
            <div css={[listItemStyle, list3Style]}>
              <div css={overflowText}>{dayjs(project.begin_date).format('YYYY/MM/DD')}</div>
            </div>
            <div css={[listItemStyle, list4Style]}>
              <div css={overflowText}>{dayjs(project.end_date).format('YYYY/MM/DD')}</div>
            </div>
            <div css={[listItemStyle, list12Style]}>
              <div css={overflowText}>{project.project_days}</div>
            </div>
            <div css={[listItemStyle, list5Style, project.evaluation_status === 'registered' && list5ActiveStyle]}>
              <div css={overflowText}>{project.evaluation_status_csv}</div>
            </div>
            <div css={[listItemStyle, list6Style]}>
              <div css={overflowText}>{project?.status_csv}</div>
            </div>
            <div css={[listItemStyle, list7Style]}>
              <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(project.rate)}.svg`)} />
              {project.last_name} {project.first_name}
            </div>
            <div css={[listItemStyle, list8Style]}>
              <div css={overflowText}>{project.evaluation_index_name}</div>
            </div>
            <div
              css={[
                listItemStyle,
                list9Style,
                project.evaluation_index_competition_edit !== '0' && list9ActiveStyle,
              ]}
            >
              {project.evaluation_index_competition_edit !== '0' ? project.evaluation_index_competition_edit : project.evaluation_index_competition}
            </div>
            <div css={[listItemStyle, list10Style]}>{project.project_members_count}</div>
            <div css={[listItemStyle, list11Style]}>{project.point_csv}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const mainListContainerStyle = css({
  display: 'grid',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  height: '480px',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    img: {
      margin: '6px 0 0 0',
      width: '28px',
    },
  },
})

const mainListContentsStyle = css({
  width: '100%',
  height: '420px',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  li: {
    padding: '20px 16px',
    borderBottom: '1px solid #DDDDDD',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const overflowText = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const listCompletedStyle = css({
  backgroundColor: '#E5F3EE',
  '&:hover': {
    backgroundColor: '#E5F3EE !important',
    opacity: '0.6 !important',
  },
})

const listEvaluationCompletedStyle = css({
  color: '#cccccc',
  '&:hover': {
    backgroundColor: '#FFFFFF !important',
    opacity: '0.6 !important',
  },
})

const list1Style = css({
  width: '180px',
})

const list2Style = css({
  width: '200px',
})

const list3Style = css({
  width: '100px',
})

const list4Style = css({
  width: '100px',
})

const list5Style = css({
  width: '80px',
})

const list5ActiveStyle = css({
  color: '#009B66',
  fontWeight: 'bold',
})

const list6Style = css({
  width: '80px',
})

const list7Style = css({
  width: '150px',
})

const list8Style = css({
  width: '200px',
})

const list9Style = css({
  width: '80px',
})

const list9ActiveStyle = css({
  color: '#FF7E3A',
  fontWeight: 'bold',
})

const list10Style = css({
  width: '80px',
})

const list11Style = css({
  width: '130px',
})

const list12Style = css({
  width: '130px',
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})
