import React from 'react'
import { css } from '@emotion/core'

// libs
import { accountLevel } from '@/libs/account-level'

type managerCardProps = {
  rate?: number
  position?: string
  name?: string
}

export const ManagerCard: React.FC<managerCardProps> = ({ rate = 1, position = '', name = '' }) => {
  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  return (
    <div css={wrapperStyle} className={'field'}>
      <div css={positionStyle}>{position}</div>
      <div css={borderStyle}></div>
      <div css={nameWrapper}>
        <img css={iconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(rate)}.svg`)} />
        <div css={nameStyle}>{name}</div>
      </div>
    </div>
  )
}

const wrapperStyle = css({
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  minWidth: '266px',
  padding: '20px 26px',
  marginRight: '50px',
  '&:last-child': {
    marginRight: 0,
  },
})
const positionStyle = css({
  color: '#004CAD',
  fontSize: '16px',
  fontWeight: 'bold',
})

const borderStyle = css({
  borderBottom: '1px solid #DDDDDD',
  margin: '12px 0',
  width: '100%',
})
const nameWrapper = css({
  display: 'flex',
})

const iconStyle = css({
  marginRight: '8px',
})

const nameStyle = css({
  color: '#151619',
  fontSize: '18px',
  lineHeight: '26px',
})
