import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { SelectField } from '@/Components/Advanced/selectField'
import { LinkButton } from '@/Components/Advanced/linkButton'

import { MasterContext } from '@/Contexts/master'

type Props = {
  searchHeadquarterId?: string
  searchDepartmentId?: string
  searchRate?: string
  searchEvaluationYear: string
  searchEvaluationDate?: string
  handleChange?: (e) => void
  onSearch?: () => void
  onReset?: () => void
  url: string
}

export const StaffEvaluationSearchDownload: React.FC<Props> = ({
  searchHeadquarterId,
  searchDepartmentId,
  searchRate,
  searchEvaluationYear,
  searchEvaluationDate,
  handleChange,
  onSearch,
  onReset,
  url,
}) => {
  const { masterEvaluationYear, masterEvaluationDate2022, masterEvaluationDate2023 } = useContext<any>(MasterContext)
  // const { auth } = useContext<any>(AuthContext)

  return (
    <div css={searchContainerStyle}>
      <div css={searchTitleStyle}>選択</div>
      <div css={searchContentsStyle}>
        <div css={searchRowStyle}>
          <SelectField
            label={'評価対象期間'}
            data={masterEvaluationYear}
            name={'evaluationYear'}
            onChange={handleChange}
            value={searchEvaluationYear}
            isAllSelect={false}
            isRequired={true}
            isEvaluationYear={true}
            width={'170px'}
          />
          <SelectField
            label={'　'}
            data={searchEvaluationYear === '2023' ? masterEvaluationDate2023 : masterEvaluationDate2022}
            name={'evaluationDate'}
            onChange={handleChange}
            value={searchEvaluationDate}
            isAllSelect={false}
            isEvaluationDate={true}
          />
          <LinkButton link={url} />
        </div>
      </div>
    </div>
  )
}

const searchContainerStyle = css({
  display: 'flex',
  '.field': {
    marginRight: '20px',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

const searchTitleStyle = css({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#151619',
  marginRight: '25px',
  whiteSpace: 'nowrap',
})

const searchContentsStyle = css({
  width: '680px',
})

const searchRowStyle = css({
  display: 'flex',
  marginBottom: '23px',
  '&:last-child': {
    marginBottom: 0,
  },
})
