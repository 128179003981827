import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { CardTextDataField } from '@/Components/Advanced/cardTextDataField'
import { CardTextAreaDataField } from '@/Components/Advanced/cardTextAreaDataField'
import { CardSelectField } from '@/Components/Advanced/cardSelectField'
import { CardProjectEvaluationSelectField } from '@/Components/Advanced/cardProjectEvaluationSelectField'
import { CardSingleCalenderField } from '@/Components/Advanced/cardSingleCalenderField'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// contexts
import { MasterContext } from '@/Contexts/master'

type Props = {
  isInHouseCost: boolean
  evaluationIndex: string
  competition: string
  inHouseCost: string
  project: {
    project_id: string
    project_no: string
    projectName: string
    clientName: string
    description: string
    projectStatus: string
  }
  beginDate: string
  endDate: string
  evaluationDate: string
  validationError: any
  setDate: (date) => void
  changeRangeDate?: (beginDate: string, endDate: string) => void
  handleChange?: (e) => void
}

export const EvaluationRegisterCard: React.FC<Props> = ({
  project,
  isInHouseCost,
  handleChange,
  changeRangeDate,
  evaluationIndex,
  competition,
  inHouseCost,
  beginDate,
  endDate,
  setDate,
  evaluationDate,
  validationError,
}) => {
  const { masterEvaluationIndexs, masterProjectStatus } = useContext<any>(MasterContext)
  return (
    <div css={cardStyle}>
      <div css={cardTitleStyle}>基本情報</div>
      <div css={cardBorderStyle}></div>
      <div css={cardDataRowStyle}>
        <CardTextDataField label={'管理ナンバー'} value={project?.project_no} width={'200px'} />
        <CardTextDataField label={'クライアント名称'} value={project?.clientName} />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextDataField label={'プロジェクト名'} value={project?.projectName} />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextAreaDataField label={'プロジェクト概要'} value={project?.description} />
      </div>
      <div css={cardDataRowStyle}>
        <CardTextDataField
          label={'プロジェクト期間'}
          value={`${dayjs(beginDate).format('YYYY/MM/DD')}　〜　${dayjs(endDate).format('YYYY/MM/DD')} (${dayjs(endDate).diff(
            dayjs(beginDate),
            'day',
          )}日)`}
          width={'414px'}
        />
        <CardProjectEvaluationSelectField
          isInHouseCost={isInHouseCost}
          masterEvaluationIndexs={masterEvaluationIndexs}
          handleChange={handleChange}
          evaluationIndex={evaluationIndex}
          competition={competition}
          inHouseCost={inHouseCost}
          validationEvaluationIndex={validationError.evaluationIndex}
          validationCompetition={validationError.competition}
          validationInHouseCost={validationError.inHouseCost}
        />
      </div>
      <div css={cardDataRowStyle}>
        <CardSelectField
          label={'ステータス'}
          name={'projectStatus'}
          onChange={handleChange}
          value={project.projectStatus}
          data={masterProjectStatus}
        />
        <CardSingleCalenderField label={'評価年月日'} date={evaluationDate} setDate={date => setDate(date)} />
      </div>
    </div>
  )
}

const cardStyle = css({
  marginBottom: '54px',
  width: '100%',
  padding: '34px 26px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
})

const cardTitleStyle = css({
  color: '#151619',
  fontSize: '16px',
  fontWeight: 'bold',
})

const cardBorderStyle = css({
  margin: '34px 0',
  width: '100%',
  borderBottom: '2.5px solid #DDDDDD',
})

const cardDataRowStyle = css({
  margin: '0 0 35px',
  width: '100%',
  display: 'flex',
  '.field': {
    margin: '0 36px 0px 0',
    '&:last-child': {
      marginRight: 0,
    },
  },
})
