import React from 'react'
import { css } from '@emotion/core'

type CardTextDataFieldProps = {
  label?: string
  width?: string
  placeholder?: string
  value?: string
}

export const CardTextDataField: React.FC<CardTextDataFieldProps> = ({ label = '', value = '', width = '100%' }) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle} htmlFor={label}>
        {label}
      </label>
      <div css={inputStyle}>{value}</div>
    </div>
  )
}

const wrapperStyle = css({})

const labelStyle = css({
  width: '100%',
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#004CAD',
})

const inputStyle = css({
  width: '100%',
  border: 'none',
  borderBottom: '1px solid #DDDDDD',
  backgroundColor: '#FFFFFF',
  color: '#151619',
  fontSize: '13px',
  margin: '6px 0 0',
  padding: '6px',
  '&::placeholder': {
    color: '#B1B1B1',
  },
})
