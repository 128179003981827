import React from 'react'
import { css } from '@emotion/core'

type CardTextFieldProps = {
  label?: string
  width?: string
  placeholder?: string
  name?: string
  value?: string
  validation?: string
  onChange?: (e) => void
}

export const CardTextAreaField: React.FC<CardTextFieldProps> = ({
  label = '',
  placeholder = '',
  name = '',
  value = '',
  width = '100%',
  validation = '',
  onChange,
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle} htmlFor={label}>
        {label}
      </label>
      <textarea
        id={label}
        css={validation ? inputErrorStyle : inputStyle}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <span css={validationErrorMessageStyle}>{validation}</span>
    </div>
  )
}

const wrapperStyle = css({})

const labelStyle = css({
  width: '100%',
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#004CAD',
})

const inputStyle = css({
  width: '100%',
  minHeight: '140px',
  border: 'none',
  borderRadius: '6px',
  backgroundColor: '#EDF2F9',
  color: '#151619',
  fontSize: '13px',
  margin: '6px 0 0',
  padding: '14px 22px',
  '&::placeholder': {
    color: '#B1B1B1',
  },
})

const inputErrorStyle = css(inputStyle, {
  width: '100%',
  border: '1px solid #FF0000',
})

const validationErrorMessageStyle = css({
  color: '#FF0000',
})
