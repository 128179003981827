import React, { createContext, useState } from 'react'

export const AccountContext = createContext({})

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState<any>()

  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
