import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'

// contexts
import { AuthContext } from '@/Contexts/auth'

// libs
import { accountLevel } from '@/libs/account-level'

type HeaderProps = {
  isLogin?: boolean
}

export const Header: React.FC<HeaderProps> = ({ isLogin = true }) => {
  const history = useHistory()
  const { auth } = useContext<any>(AuthContext)

  const goToTop = () => {
    history.push({
      pathname: `/`,
    })
  }

  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  return (
    <header css={logoWrapperStyle}>
      <div css={logoInnerStyle}>
        <img css={logoStyle} onClick={goToTop} src={require('@/static/images/logo.svg')} alt="SCOPE Project Recorder" />
        {isLogin && (
          <div css={accountMenuStyle}>
            <img
              css={accountIconStyle}
              onClick={goToTop}
              src={require(`@/static/images/account_icon_level${_accountLevel(auth.rate)}.svg`)}
            />
            <div css={accountNameStyle}>{`${auth.last_name} ${auth.first_name}`}</div>
          </div>
        )}
      </div>
    </header>
  )
}

const logoWrapperStyle = css({
  width: '100%',
  height: '62px',
  padding: '20px',
  borderBottom: '1px solid #dddddd',
})

const logoInnerStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const logoStyle = css({
  width: '200px',
  imageRendering: '-webkit-optimize-contrast',
  img: {
    cursor: 'pointer',
  },
})

const accountMenuStyle = css({
  minWidth: '160px',
  display: 'flex',
  alignItems: 'center',
})

const accountIconStyle = css({
  width: '28px',
  marginRight: '6px',
})

const accountNameStyle = css({
  minWidth: '100px',
  fontSize: '14px',
  color: '#151619',
})
