import React from 'react'
import { css } from '@emotion/core'

type LinkButtonProps = {
  link?: string
}

export const LinkButton: React.FC<LinkButtonProps> = ({ link }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <a href={`${link}`} css={buttonStyle} target="_blank" rel="noopener noreferrer">
        ダウンロード
      </a>
    </div>
  )
}

const wrapperStyle = css({
  position: 'relative',
  width: '75px',
})

const buttonStyle = css({
  position: 'absolute',
  bottom: 0,
  appearance: 'none',
  whiteSpace: 'nowrap',
  border: 'none',
  padding: '5px 13px 5px 38px',
  color: '#FFFFFF',
  backgroundColor: '#EBAE17',
  borderRadius: '22px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundImage: `url(${require('@/static/images/download.svg')})`,
  backgroundPosition: 'left 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
  '&:hover': {
    opacity: 0.8,
  },
})