import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'

// components
import { AuthContext } from '@/Contexts/auth'

type SideMenuProps = {
  activeMenuName?: string
}

export const SideMenu: React.FC<SideMenuProps> = ({ activeMenuName }) => {
  const { auth } = useContext<any>(AuthContext)
  const [isAdminMenuActive, setIsAdminMenuActive] = useState(false)
  const history = useHistory()

  const goToTop = () => {
    history.push({
      pathname: `/project`,
    })
  }

  const goToProjectRegister = () => {
    history.push({
      pathname: `/project/register`,
    })
  }

  const goToProjectEvaluation = () => {
    history.push({
      pathname: `/project/evaluation`,
    })
  }

  const goToStaffEvaluation = () => {
    history.push({
      pathname: `/staff-evaluation`,
    })
  }

  const goToStaffEvaluationDetail = user_id => {
    history.push({
      pathname: `/staff-evaluation/detail`,
      search: `?userId=${user_id}`,
    })
  }

  const goToFinalEvaluation = () => {
    history.push({
      pathname: `/final-evaluation`,
    })
  }

  const openAdminMenu = () => {
    setIsAdminMenuActive(!isAdminMenuActive ? true : false)
  }

  const goToAdminAccountss = () => {
    history.push({
      pathname: `/admin/account`,
    })
  }

  const goToAdminMsterHeadquarters = () => {
    history.push({
      pathname: `/admin/master-headquarters`,
    })
  }

  const goToAdminMsterDepartments = () => {
    history.push({
      pathname: `/admin/master-departments`,
    })
  }

  const goToAdminMsterEvaluationIndexs = () => {
    history.push({
      pathname: `/admin/master-evaluation-indexs`,
    })
  }

  const goToAdminMsterinHouseCosts = () => {
    history.push({
      pathname: `/admin/master-in-house-costs`,
    })
  }

  const goToAdminMsterPositions = () => {
    history.push({
      pathname: `/admin/master-positions`,
    })
  }

  const goToAdminMsterUsers = () => {
    history.push({
      pathname: `/admin/master-users`,
    })
  }

  const goToRoleSheets = () => {
    history.push({
      pathname: `/admin/master-role-sheets`,
    })
  }

  const goToDownload = () => {
    history.push({
      pathname: `/staff-evaluation/download`
    })
  }

  const adminMenuArrowStyle = css({
    transform: isAdminMenuActive || activeMenuName === 'admin' ? 'rotate(180deg)' : 'auto',
  })

  const adminMenuListHeightStyle = css({
    height: isAdminMenuActive || activeMenuName === 'admin' ? 'auto' : '0px',
  })

  return (
    <div css={sideMenuWrapperStyle}>
      <ul css={menuListStyle}>
        <li onClick={goToTop}>
          {activeMenuName === 'project' && <img css={menuActiveBarStyle} src={require(`@/static/images/sidemenu/active_bar.svg`)} />}
          <img css={menuIconStyle} src={require(`@/static/images/sidemenu/list${activeMenuName === 'project' ? '_active' : ''}.svg`)} />
          <div css={activeMenuName === 'project' ? menuNameActiveStyle : menuNameStyle}>プロジェクト一覧</div>
        </li>
        {auth && auth.rate >= 4 && (
          <li onClick={goToProjectRegister}>
            {activeMenuName === 'project-register' && (
              <img css={menuActiveBarStyle} src={require(`@/static/images/sidemenu/active_bar.svg`)} />
            )}
            <img
              css={menuIconStyle}
              src={require(`@/static/images/sidemenu/add_box${activeMenuName === 'project-register' ? '_active' : ''}.svg`)}
            />
            <div css={activeMenuName === 'project-register' ? menuNameActiveStyle : menuNameStyle}>プロジェクト登録</div>
          </li>
        )}
        {auth && auth.rate >= 4 && (
          <li onClick={goToProjectEvaluation}>
            {activeMenuName === 'project-evaluation' && (
              <img css={menuActiveBarStyle} src={require(`@/static/images/sidemenu/active_bar.svg`)} />
            )}
            <img
              css={menuIconStyle}
              src={require(`@/static/images/sidemenu/check_box${activeMenuName === 'project-evaluation' ? '_active' : ''}.svg`)}
            />
            <div css={activeMenuName === 'project-evaluation' ? menuNameActiveStyle : menuNameStyle}>プロジェクト評価</div>
          </li>
        )}
        {auth && auth.rate <= 5 && (
          <li onClick={() => goToStaffEvaluationDetail(auth.user_id)}>
            {activeMenuName === 'staff-evaluation' && (
              <img css={menuActiveBarStyle} src={require(`@/static/images/sidemenu/active_bar.svg`)} />
            )}
            <img
              css={menuIconStyle}
              src={require(`@/static/images/sidemenu/insert_comment${activeMenuName === 'staff-evaluation' ? '_active' : ''}.svg`)}
            />
            <div css={activeMenuName === 'staff-evaluation' ? menuNameActiveStyle : menuNameStyle}>スタッフ評価詳細</div>
          </li>
        )}
        {auth && auth.rate >= 6 && (
          <li onClick={goToStaffEvaluation}>
            {activeMenuName === 'staff-evaluation' && (
              <img css={menuActiveBarStyle} src={require(`@/static/images/sidemenu/active_bar.svg`)} />
            )}
            <img
              css={menuIconStyle}
              src={require(`@/static/images/sidemenu/insert_comment${activeMenuName === 'staff-evaluation' ? '_active' : ''}.svg`)}
            />
            <div css={activeMenuName === 'staff-evaluation' ? menuNameActiveStyle : menuNameStyle}>スタッフ評価一覧</div>
          </li>
        )}
        {auth && auth.rate >= 6 && (
          <li onClick={goToFinalEvaluation}>
            {activeMenuName === 'final-evaluation' && (
              <img css={menuActiveBarStyle} src={require(`@/static/images/sidemenu/active_bar.svg`)} />
            )}
            <img
              css={menuIconStyle}
              src={require(`@/static/images/sidemenu/star${activeMenuName === 'final-evaluation' ? '_active' : ''}.svg`)}
            />
            <div css={activeMenuName === 'final-evaluation' ? menuNameActiveStyle : menuNameStyle}>最終評価</div>
          </li>
        )}
        {auth && auth.rate >= 6 && (
          <li onClick={openAdminMenu}>
            {activeMenuName === 'admin' && <img css={menuActiveBarStyle} src={require(`@/static/images/sidemenu/active_bar.svg`)} />}
            <img
              css={menuIconStyle}
              src={require(`@/static/images/sidemenu/verified_user${activeMenuName === 'admin' ? '_active' : ''}.svg`)}
            />
            <div css={activeMenuName === 'admin' ? menuNameActiveStyle : menuNameStyle}>管理者メニュー</div>
            <img css={[adminMenuIconStyle, adminMenuArrowStyle]} src={require('@/static/images/sidemenu/admin_arrow.svg')} />
          </li>
        )}
        <ul css={[adminMenuListStyle, adminMenuListHeightStyle]}>
          <li onClick={goToAdminMsterUsers}>
            <div css={menuNameStyle}>社員マスタ</div>
          </li>
          <li onClick={goToAdminAccountss}>
            <div css={menuNameStyle}>アカウント一覧</div>
          </li>
          <li onClick={goToAdminMsterHeadquarters}>
            <div css={menuNameStyle}>本部マスタ</div>
          </li>
          <li onClick={goToAdminMsterDepartments}>
            <div css={menuNameStyle}>部署マスタ</div>
          </li>
          <li onClick={goToAdminMsterEvaluationIndexs}>
            <div css={menuNameStyle}>評価指標マスタ</div>
          </li>
          <li onClick={goToAdminMsterinHouseCosts}>
            <div css={menuNameStyle}>内制費マスタ</div>
          </li>
          <li onClick={goToAdminMsterPositions}>
            <div css={menuNameStyle}>役割マスタ</div>
          </li>
          <li onClick={goToRoleSheets}>
            <div css={menuNameStyle}>権限一覧（権限表）</div>
          </li>
          <li onClick={goToDownload}>
            <div css={menuNameStyle}>評価一覧ダウンロード</div>
          </li>
        </ul>
      </ul>
    </div>
  )
}

const sideMenuWrapperStyle = css({
  width: '220px',
  height: 'calc(100vh - 62px)',
  padding: '46px 0 0 0',
  borderRight: '1px solid #dddddd',
})

const menuListStyle = css({
  width: '100%',
  li: {
    cursor: 'pointer',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 4px 0',
    padding: '0 20px',
  },
})

const menuActiveBarStyle = css({
  width: '6px',
  height: '31px',
  margin: '0 9px 0 -15px',
})

const menuIconStyle = css({
  width: '24px',
  color: '#151619',
  marginRight: '20px',
  imageRendering: '-webkit-optimize-contrast',
  img: {
    cursor: 'pointer',
  },
  '&:hover': {
    color: '#004CAD',
    fill: '#004CAD',
    stroke: '#004CAD',
  },
})

const menuNameStyle = css({
  width: '160px',
  color: '#151619',
  fontSize: '13px',
  fontWeight: 'bold',
  '&:hover': {
    color: '#004CAD',
  },
})

const menuNameActiveStyle = css(menuNameStyle, {
  color: '#004CAD',
})

const adminMenuIconStyle = css({
  marginLeft: '10px',
  transition: 'transform 0.1s ease',
})

const adminMenuListStyle = css({
  width: '100%',
  overflow: 'hidden',
  li: {
    cursor: 'pointer',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 4px 0',
    padding: '0 20px 0 60px',
  },
})
