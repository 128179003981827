import React from 'react'
import { css } from '@emotion/core'

type ButtonProps = {
  label?: string
  width?: string
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({ label = '保存する', width = '200px', onClick }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <button onClick={onClick} css={[buttonStyle, { width }]}>
        {label}
      </button>
    </div>
  )
}

const wrapperStyle = css({})

const buttonStyle = css({
  border: 'none',
  outoline: 'none',
  appearance: 'none',
  whiteSpace: 'nowrap',
  padding: '20px 0px',
  color: '#FFFFFF',
  borderRadius: '100px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundColor: '#004CAD',
  '&:hover': {
    opacity: 0.8,
  },
})
