import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { UploadButton } from '@/Components/Advanced/uploadButton'
import { DownloadButton } from '@/Components/Advanced/downloadButton'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { MasterUsersList } from '@/Components/Pages/Admin/_MasterUsers/list'
import ReactLoading from 'react-loading'

// apis
import { fetchMasterUsers, updateMasterUsers } from '@/Apis/scopeProjectRecorder'

// libs
import { csvDownload } from '@/libs/csv-download'

// components
import { AuthContext } from '@/Contexts/auth'

export const MasterUsers: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const history = useHistory()
  const [masterUsers, setMasterUsers] = useState<any>([])
  const [masterUsersForCSV, setMasterUsersForCSV] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _fetchMasterUsers = async () => {
    await fetchMasterUsers().then(data => {
      // @ts-ignore
      setMasterUsers(data.data.users)
      // @ts-ignore
      setListTotalCount(data.data.total)
      setIsLoading(false)
    })
  }

  const _onFileLoad = data => {
    setIsLoading(true)
    if (data) {
      updateMasterUsers(data).then(res => {
        // @ts-ignore
        if (!res) {
          alert('CSVアップロードに失敗しました。')
          setIsLoading(false)
          return
        }

        // @ts-ignore
        if (res.success) {
          _fetchMasterUsers()
          alert('CSVアップロード完了しました。')
          return
        } else {
          // @ts-ignore
          alert(res.message)
          setIsLoading(false)
          return
        }
      })
    }
  }

  const _csvDownload = () => {
    csvDownload(masterUsersForCSV, 'master-users.csv')
  }

  useEffect(() => {
    if (masterUsers) {
      const csvHeader = [
        'システムID',
        '社員番号',
        '社員番号4',
        '姓',
        '名',
        '姓カナ',
        '名カナ',
        'メールアドレス',
        '職位',
        '等級',
        '統括本部cd',
        '統括本部名称',
        '統括本部通称',
        '本部cd',
        '本部名称',
        '本部通称',
        '部・室cd',
        '部・室名称',
        '部・室通称',
        '所属コード',
        'パスワード',
      ]
      const csvData = masterUsers.map((masterUser: any) => {
        return [
          masterUser.user_id,
          masterUser.employee_number,
          masterUser.employee_number4,
          masterUser.last_name,
          masterUser.first_name,
          masterUser.last_name_kana,
          masterUser.first_name_kana,
          masterUser.email,
          masterUser.job_title,
          masterUser.rate,
          masterUser.headquarter_master_cd,
          masterUser.headquarter_master_name,
          masterUser.headquarter_master_alias_name,
          masterUser.headquarter_cd,
          masterUser.headquarter_name,
          masterUser.headquarter_alias_name,
          masterUser.department_cd,
          masterUser.department_name,
          masterUser.department_alias_name,
          masterUser.belong_code,
          masterUser.password,
        ]
      })
      setMasterUsersForCSV([csvHeader, ...csvData])
    }
  }, [masterUsers])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      setIsLoading(true)
      _fetchMasterUsers()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="admin" />
        <div css={containerStyle}>
          <PageTitle title={'社員マスタ一覧'} />
          <div css={mainTopContentsStyle}>
            <div />
            <div css={searchSideButtonWrapperStyle}>
              <UploadButton onFileLoad={_onFileLoad} />
              <DownloadButton onClick={_csvDownload} />
            </div>
          </div>
          <div css={mainContentsStyle}>
            <ListPageCount pageCount={listTotalCount} />
            <MasterUsersList masterUsers={masterUsers} />
          </div>
        </div>
        {isLoading && (
          <div css={loadingOverlayStyle}>
            <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
          </div>
        )}
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const searchSideButtonWrapperStyle = css({
  display: 'flex',
  '.field': {
    marginRight: '10px',
  },
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
