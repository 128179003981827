import React from 'react'
import { css } from '@emotion/core'

type Props = {
  staffEvaluation?: {
    project_count: string
    task_point_total: string
    contribution_point_total: string
    total_point: string
  }
}

export const EvaluationCard: React.FC<Props> = ({ staffEvaluation }) => {
  const task_point_total = staffEvaluation?.task_point_total ? parseFloat(staffEvaluation.task_point_total).toFixed(2) : 0
  const contribution_point_total = staffEvaluation?.contribution_point_total ? parseFloat(staffEvaluation.contribution_point_total).toFixed(2) : 0
  const total_point = staffEvaluation?.total_point ? parseFloat(staffEvaluation.total_point).toFixed(2) : 0
  return (
    <div css={cardStyle}>
      <ul css={cardListStyle}>
        <li>
          <span>プロジェクト数：</span>
          {!staffEvaluation?.project_count ? 0 : staffEvaluation?.project_count}
        </li>
        <li>
          <span>個人タスク合計：</span>
          {task_point_total}
        </li>
        <li>
          <span>貢献度合計：</span>
          {contribution_point_total}
        </li>
        <li>
          <span>合計得点：</span>
          {total_point}
        </li>
      </ul>
    </div>
  )
}
const cardStyle = css({
  margin: '40px 0 50px',
  width: '100%',
  padding: '34px 26px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
})

const cardListStyle = css({
  width: '100%',
  display: 'flex',
  li: {
    margin: '0 36px 0px 0',
    fontWeight: 'bold',
    span: {
      color: '#004CAD',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
})
