/*
* プロジェクトの評価後の合計得点の算出
*【評価者の場合】
* 評価者は＜自己評価＞のみの入力となり、
* 表には出ていないが、1.25倍された得点となる。
* 自己評価 × プロジェクト倍率 × 1.25（リーダーボーナス）＝ 合計得点
*
*【スタッフの場合】
* 評価者は＜個人タスク＞と＜貢献度＞を入力
* （個人タスクポイント × 個人タスク% + 貢献度ポイント × 貢献度%）× 倍率 ＝ 合計得点
**/
const LEADER_BONUS = 1
export const totalPointCalculator = (project, member) => {
  let totalPoint = 0
  const evaluation_index_competition = project.evaluation_index_competition_edit !== '0' ? project.evaluation_index_competition_edit : project.evaluation_index_competition

  // 評価者、責任者
  if (member.role === 'evaluator' || member.role === 'manager' || member.role === 'evaluatorAndManager') {
    totalPoint = parseFloat(member.self_assessment_point) * parseFloat(evaluation_index_competition) * LEADER_BONUS
  }
  
  // スタッフ
  if (member.role === 'other') {
    totalPoint =
      (parseFloat(member.task_point) * (parseFloat(member.task_percent) / 100) +
        parseFloat(member.contribution_point) * (parseFloat(member.contribution_percent) / 100)) *
      parseFloat(evaluation_index_competition)
  }

  if (isNaN(totalPoint)) return 0
  return totalPoint.toFixed(2)
}

// 倍率加算前
export const totalPointCompetitionBeforeCalculator = member => {
  let totalPointCompetitionBefore = 0

  // 評価者、責任者
  if (member.role === 'evaluator' || member.role === 'manager' || member.role === 'evaluatorAndManager') {
    totalPointCompetitionBefore = parseFloat(member.self_assessment_point) * 1.25
  }

  // スタッフ
  if (member.role === 'other') {
    totalPointCompetitionBefore =
      parseFloat(member.task_point) * (parseFloat(member.task_percent) / 100) +
      parseFloat(member.contribution_point) * (parseFloat(member.contribution_percent) / 100)
  }

  if (isNaN(totalPointCompetitionBefore)) return 0
  return totalPointCompetitionBefore.toFixed(2)
}

// 全メンバーの評価指標 or 倍率変更時、全てのポイントを再計算
export const allMemberTotalPointCalculator = (evaluation_index_competition, members) => {
  members.forEach((member, index) => {
    // 倍率加算後
    let totalPoint = 0
    // 評価者、責任者
    if (member.role === 'evaluator' || member.role === 'manager' || member.role === 'evaluatorAndManager') {
      totalPoint = parseFloat(member.self_assessment_point) * parseFloat(evaluation_index_competition) * LEADER_BONUS
    }
  
    // スタッフ
    if (member.role === 'other') {
      totalPoint =
        (parseFloat(member.task_point) * (parseFloat(member.task_percent) / 100) +
          parseFloat(member.contribution_point) * (parseFloat(member.contribution_percent) / 100)) *
        parseFloat(evaluation_index_competition)
    }

    if (isNaN(totalPoint)) members[index].total_point = 0
    if (!isNaN(totalPoint)) members[index].total_point = totalPoint.toFixed(2)
  })
  return members
}

// 全メンバーの合計を算出
export const allTotalPointCalculator = (members) => {
  let _totalPoint = 0
  members.forEach(member => {
    if (!isNaN(parseFloat(member.total_point))) {
      _totalPoint += parseFloat(member.total_point)
    }
  })
  return _totalPoint.toFixed(2)
}