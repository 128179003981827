import React from 'react'
import { css } from '@emotion/core'

type PageTitleProps = {
  title: string
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return <div css={pageTitleWrapperStyle}>{title}</div>
}

const pageTitleWrapperStyle = css({
  width: '100%',
  height: '52px',
  padding: '14px 0 14px 34px',
  borderBottom: '1px solid #dddddd',
  backgroundColor: '#EDF2F9',
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#151619',
})
