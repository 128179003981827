import { env } from '@/libs/env'
import axios from 'axios'
import 'dayjs/locale/ja'

function http<T = void>(config: {
  url: string
  method: 'get' | 'post' | 'put' | 'delete'
  headers?: { Authorization: any }
  params?: any
  data?: any
}) {
  const { headers, params, data } = config
  switch (config.method) {
    case 'get':
      return axios.get<T>(config.url, { headers, params, data })
    case 'post':
      return axios.post<T>(config.url, data, { headers, params })
    case 'put':
      return axios.put<T>(config.url, data, { headers, params })
    case 'delete':
      return axios.delete<T>(config.url, { headers, params, data })
    default:
      throw new Error('not reachable')
  }
}

export const fetchMasterUsers = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/users`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchMasterUserDetail = user_id => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/users/detail?user_id=${user_id}`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const passwordForget = (email, employee_number) => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/users/password-forget?email=${email}&employee_number=${employee_number}`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const passwordEdit = (user_id, password) => {
  const data = { user_id, password }

  return http({
    method: 'post',
    url: `${env('BASE_API')}/api/master/users/password-edit`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const updateMasterUsers = master => {
  const data = { master }

  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/master/users`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchMasterInHouseCosts = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/in-house-costs`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const updateMasterInHouseCosts = master => {
  const data = { master }

  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/master/in-house-costs`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchMasterEvaluationIndexs = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/evaluation-indexs`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const updateMasterEvaluationIndexs = master => {
  const data = { master }

  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/master/evaluation-indexs`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchMasterPositions = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/positions`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const updateMasterPositions = master => {
  const data = { master }

  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/master/positions`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchMasterHeadquarters = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/headquarters`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const updateMasterHeadquarters = master => {
  const data = { master }

  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/master/headquarters`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchMasterDepartments = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/master/departments`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const updateMasterDepartments = master => {
  const data = { master }

  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/master/departments`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const login = (email, password) => {
  const data = { email, password }
  return http({
    method: 'post',
    url: `${env('BASE_API')}/api/login`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchProjectDetail = project_id => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/project/detail?project_id=${project_id}`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchProjects = (
  keyword,
  user_id,
  evaluation_id,
  evaluation_status,
  status,
  evaluation_date,
  begin_date,
  end_date,
  evaluation_begin_date,
  evaluation_end_date,
) => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env(
      'BASE_API',
    )}/api/projects?keyword=${keyword}&user_id=${user_id}&evaluation_id=${evaluation_id}&evaluation_status=${evaluation_status}&status=${status}&evaluation_date=${evaluation_date}&begin_date=${begin_date}&end_date=${end_date}&evaluation_begin_date=${evaluation_begin_date}&evaluation_end_date=${evaluation_end_date}`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchCalculatorTargetProjects = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env(
      'BASE_API',
    )}/api/projects/calculator-target-projects`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const updateAllTotalPoint = projects => {
  const data = { projects }

  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/projects/update-all-total-point`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchStaffEvaluations = (
  headquarterId,
  departmentId,
  rate,
  evaluation_year,
  evaluation_date,
  evaluation_begin_date,
  evaluation_end_date,
) => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env(
      'BASE_API',
    )}/api/staff-evaluations?headquarter_id=${headquarterId}&department_id=${departmentId}&rate=${rate}&evaluation_year=${evaluation_year}&evaluation_date=${evaluation_date}&evaluation_begin_date=${evaluation_begin_date}&evaluation_end_date=${evaluation_end_date}`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

// 追加
export const fetchStaffEvaluationsDownload = (
  headquarterId,
  departmentId,
  rate,
  evaluation_year,
  evaluation_date,
  evaluation_begin_date,
  evaluation_end_date,
) => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env(
      'BASE_API',
    )}/api/staff-evaluations/download?evaluation_begin_date=${evaluation_begin_date}&evaluation_end_date=${evaluation_end_date}`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchStaffEvaluationDetail = (user_id, evaluation_year, evaluation_date, evaluation_begin_date, evaluation_end_date) => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env(
      'BASE_API',
    )}/api/staff-evaluation/detail?user_id=${user_id}&evaluation_year=${evaluation_year}&evaluation_date=${evaluation_date}&evaluation_begin_date=${evaluation_begin_date}&evaluation_end_date=${evaluation_end_date}`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const fetchProjectsEvaluatorMembers = () => {
  const headers = { Authorization: null }

  return http({
    method: 'get',
    url: `${env('BASE_API')}/api/projects/evaluator-members`,
    headers,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const projectRegister = (
  project_name,
  client_name,
  description,
  begin_date,
  end_date,
  evaluation_index_id,
  evaluation_index_price,
  evaluation_index_competition,
  evaluation_index_competition_edit,
  evaluation_date,
  point,
  status,
  members,
) => {
  const data = {
    project_name,
    client_name,
    description,
    begin_date,
    end_date,
    evaluation_index_id,
    evaluation_index_price,
    evaluation_index_competition,
    evaluation_index_competition_edit,
    evaluation_date,
    point,
    status,
    members,
  }

  return http({
    method: 'post',
    url: `${env('BASE_API')}/api/project/register`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const projectEdit = (
  project_id,
  project_name,
  client_name,
  description,
  begin_date,
  end_date,
  evaluation_index_id,
  evaluation_index_price,
  evaluation_index_competition,
  evaluation_index_competition_edit,
  evaluation_date,
  point,
  status,
  evaluation_status,
  members,
) => {
  const data = {
    project_name,
    client_name,
    description,
    begin_date,
    end_date,
    evaluation_index_id,
    evaluation_index_price,
    evaluation_index_competition,
    evaluation_index_competition_edit,
    evaluation_date,
    point,
    status,
    evaluation_status,
    members,
  }
  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/project/edit?project_id=${project_id}`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const projectDelete = project_id => {
  const data = {}
  return http({
    method: 'put',
    url: `${env('BASE_API')}/api/project/delete?project_id=${project_id}`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}

export const registerFinalEvaluation = (begin_date, end_date, members) => {
  const data = {
    begin_date,
    end_date,
    members,
  }

  return http({
    method: 'post',
    url: `${env('BASE_API')}/api/staff-evaluation/final-evaluation`,
    data,
  })
    .then(results => {
      return results.data
    })
    .catch(() => {
      console.log('通信に失敗しました。')
    })
}
