import React from 'react'
import { css } from '@emotion/core'

type ResetButtonProps = {
  onReset?: () => void
}

export const ResetButton: React.FC<ResetButtonProps> = ({ onReset }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <button css={buttonStyle} onClick={onReset}>
        リセット
      </button>
    </div>
  )
}

const wrapperStyle = css({
  position: 'relative',
  width: '75px',
})

const buttonStyle = css({
  position: 'absolute',
  bottom: 0,
  appearance: 'none',
  whiteSpace: 'nowrap',
  border: 'none',
  padding: '7px 14px',
  color: '#FFFFFF',
  backgroundColor: '#757575',
  borderRadius: '22px',
  fontSize: '12px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})
