import React from 'react'
import { css } from '@emotion/core'

type SelectProps = {
  label?: string
  width?: string
  data?: any
  name?: string
  idName?: string
  value?: string
  isEvaluationYear?: boolean
  isEvaluationDate?: boolean
  isRequired?: boolean
  isAllSelect?: boolean
  onChange?: (e) => void
}

export const SelectField: React.FC<SelectProps> = ({
  label = '',
  width = '155px',
  name = '',
  idName = 'id',
  data = [],
  value,
  isEvaluationYear = false,
  isEvaluationDate = false,
  isRequired = false,
  isAllSelect = true,
  onChange,
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      <label css={labelStyle} htmlFor={label}>
        {label} {isRequired && <span css={requiredStyle}>必須</span>}
      </label>
      {isEvaluationYear && <div css={labelStyle}>評価年度</div>}
      {isEvaluationDate && <div css={labelStyle}>上期／下期</div>}
      <select css={selectStyle} id={label} name={name} onChange={onChange} value={value}>
        {isAllSelect && (
          <option value={''} key={0}>
            全て
          </option>
        )}
        {data.map((item: any, index: number) => (
          <option key={index} value={item?.[idName]}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  )
}

const wrapperStyle = css({
  width: '150px',
})

const labelStyle = css({
  width: '100%',
  fontSize: '12px',
})

const selectStyle = css({
  appearance: 'none',
  width: '100%',
  border: '1px solid #D9D9D9',
  borderRadius: '22px',
  backgroundColor: '#FFFFFF',
  color: '#151619',
  fontSize: '12px',
  margin: '4px 0 0',
  padding: '6px 14px',
  backgroundImage: `url(${require('@/static/images/arrow_down.svg')})`,
  backgroundPosition: 'right 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
})

const requiredStyle = css(labelStyle, {
  color: '#FF6760',
  fontWeight: 'bold',
})
