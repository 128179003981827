import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { ExportButton } from '@/Components/Advanced/exportButton'
import { CompareButton } from '@/Components/Advanced/compareButton'
import { OutlineButton } from '@/Components/Advanced/outlineButton'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { StaffEvaluationSearch } from '@/Components/Pages/FinalEvaluation/_FinalEvaluation/search'
import { StaffEvaluationList } from '@/Components/Pages/FinalEvaluation/_FinalEvaluation/list'
import ReactLoading from 'react-loading'
import ReactPaginate from 'react-paginate'

// apis
import { fetchStaffEvaluations, registerFinalEvaluation } from '@/Apis/scopeProjectRecorder'

// libs
import { rankingTotalPointByRate } from '@/libs/calculator-evaluation-rate'
import { csvDownload } from '@/libs/csv-download'

// components
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

export const FinalEvaluation: React.FC<{}> = () => {
  const date = new Date()
  const month = date.getMonth() + 1;
  if (month <= 3) {
    date.setFullYear(date.getFullYear() - 1);
  }
  let half = '';
  if (4 <= month && month <= 9) {
    half = 'FirstHalf';
  } else {
    half = 'SecondHalf';
  }
  const period = half;
  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationDate } = useContext<any>(MasterContext)
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const [searchHeadquarterId, setSearchHeadquarterId] = useState<string>('')
  const [searchDepartmentId, setSearchDepartmentId] = useState<string>('')
  const [searchRate, setSearchRate] = useState<string>('')
  const [staffEvaluations, setStaffEvaluations] = useState<any>([])
  const [doneStaffEvaluations, setDoneStaffEvaluations] = useState<any>([])
  const [staffEvaluationsForCSV, setStaffEvaluationsForCSV] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [visibleFinalEvaluationModal, setVisibleFinalEvaluationModal] = useState<boolean>(false)
  const [visibleFinalEvaluationCompareModal, setVisibleFinalEvaluationCompareModal] = useState<boolean>(false)
  const _masterEvaluationDate = masterEvaluationDate.find((_item, index) => index === 0)
  const [searchEvaluationYear, setSearchEvaluationYear] = useState<string>(
    query.searchEvaluationYear ? query.searchEvaluationYear : date.getFullYear(),
  )
  const [searchEvaluationYearLabel, setSearchEvaluationYearLabel] = useState<string>(_masterEvaluationDate.year)
  const [searchEvaluationDate, setSearchEvaluationDate] = useState<string>(
    query.searchEvaluationDate ? query.searchEvaluationDate : `${date.getFullYear()}${period}`,
  )
  const [searchEvaluationDateLabel, setSearchEvaluationDateLabel] = useState<string>(_masterEvaluationDate.name)
  const [evaluationBeginDate, setEvaluationBeginDate] = useState<string>(_masterEvaluationDate.begin_date)
  const [evaluationEndDate, setEvaluationEndDate] = useState<string>(_masterEvaluationDate.end_date)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sortKey, setSortKey] = useState<string>('')
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC')
  // ページング
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 100;

  // 評価対象社員総数
  const [staffEvaluationTotalCount, setStaffEvaluationTotalCount] = useState<number>(0)
  // 評価1の社員総数
  const [staffEvaluationPoint1TotalCount, setStaffEvaluationPoint1TotalCount] = useState<number>(0)
  const [staffEvaluationPoint1ParcentTotalCount, setStaffEvaluationPoint1ParcentTotalCount] = useState<number>(0)
  // 評価2の社員総数
  const [staffEvaluationPoint2TotalCount, setStaffEvaluationPoint2TotalCount] = useState<number>(0)
  const [staffEvaluationPoint2ParcentTotalCount, setStaffEvaluationPoint2ParcentTotalCount] = useState<number>(0)
  // 評価3の社員総数
  const [staffEvaluationPoint3TotalCount, setStaffEvaluationPoint3TotalCount] = useState<number>(0)
  const [staffEvaluationPoint3ParcentTotalCount, setStaffEvaluationPoint3ParcentTotalCount] = useState<number>(0)
  // 評価4の社員総数
  const [staffEvaluationPoint4TotalCount, setStaffEvaluationPoint4TotalCount] = useState<number>(0)
  const [staffEvaluationPoint4ParcentTotalCount, setStaffEvaluationPoint4ParcentTotalCount] = useState<number>(0)
  // 評価5の社員総数
  const [staffEvaluationPoint5TotalCount, setStaffEvaluationPoint5TotalCount] = useState<number>(0)
  const [staffEvaluationPoint5ParcentTotalCount, setStaffEvaluationPoint5ParcentTotalCount] = useState<number>(0)

  // 最終評価判定
  // 最終評価出来るのか
  const [canFinalEvaluation, setCanFinalEvaluation] = useState<boolean>(false)
  // 最終評価済みか
  const [isFinalEvaluation, setIsFinalEvaluation] = useState<boolean>(false)

  const _sortSetting = (key: string) => {
    setSortKey(key)
    // 選択したキーが初回の場合、昇順にする
    if (key !== sortKey) {
      setSortType('ASC')
    }
    if (key === sortKey) {
      setSortType(sortType === 'ASC' ? 'DESC' : 'ASC')
    }
  }

  const _sort = () => {
    const _staffEvaluations = [...staffEvaluations]
    _staffEvaluations.sort((a, b) => {
      if (isNaN(a[sortKey])) {
        if (sortType === 'ASC') {
          return a[sortKey] > b[sortKey] ? 1 : -1
        } else {
          return a[sortKey] < b[sortKey] ? 1 : -1
        }
      } else {
        if (sortType === 'ASC') {
          return a[sortKey] - b[sortKey]
        } else {
          return b[sortKey] - a[sortKey]
        }
      }
    })
    setStaffEvaluations(_staffEvaluations)
  }

  const handleLastEvaluationChange = (e, user_id) => {
    const _staffEvaluations = [...staffEvaluations]
    staffEvaluations.forEach((_item, index) => {
      if (_item.user_id === user_id) {
        staffEvaluations[index].evaluation_point = parseInt(e.target.value)
      }
    })
    _calculatorEvaluationPoint(_staffEvaluations)
    setStaffEvaluations(_staffEvaluations)
  }

  const handleChange = e => {
    if (e.target.name === 'headquarterId') {
      setSearchHeadquarterId(e.target.value)
    }

    if (e.target.name === 'departmentId') {
      setSearchDepartmentId(e.target.value)
    }

    if (e.target.name === 'rate') {
      setSearchRate(e.target.value)
    }

    if (e.target.name === 'evaluationYear') {
      setSearchEvaluationYear(e.target.value)
      setSearchEvaluationYearLabel(e.target.value)
    }

    if (e.target.name === 'evaluationDate') {
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === e.target.value)
      setSearchEvaluationDate(e.target.value)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
      setSearchEvaluationDateLabel(_masterEvaluationDate.name)
    }
  }

  const _onSearch = () => {
    setIsLoading(true)

    // 検索したらソートはリセットされる
    setSortKey('')
    setSortType('ASC')
    setCurrentPage(0)

    // スタッフ最終評価再取得
    _fetchStaffEvaluations()
  }

  const _onReset = () => {
    // 本部
    setSearchHeadquarterId('')
    if (auth.rate === 6) {
      setSearchHeadquarterId(auth.headquarter_id)
    }
    // 部署
    setSearchDepartmentId('')
    // 等級
    setSearchRate('')
    // 評価年月日
    const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === 0)
    // 評価年月日(年度)
    setSearchEvaluationYear(`${date.getFullYear()}`)
    setSearchEvaluationYearLabel(_masterEvaluationDate.year)
    // 評価年月日(期間)
    setSearchEvaluationDate(`${date.getFullYear()}${period}`)
    setSearchEvaluationDateLabel(_masterEvaluationDate.name)
    // 期間を選択した際の開始日時
    setEvaluationBeginDate('')
    // 期間を選択した際の終了日時
    setEvaluationEndDate('')
    // データのリセット
    setStaffEvaluations([])
    setListTotalCount(0)
    setCurrentPage(0)
  }

  const _fetchStaffEvaluations = async () => {
    // 評価計算用のデータを取得
    await fetchStaffEvaluations(
      '',
      '',
      '',
      searchEvaluationYear,
      searchEvaluationDate,
      evaluationBeginDate,
      evaluationEndDate,
      // 絞り込み検索をしたスタッフ一覧
    ).then(async _staffEvaluations => {
      // 評価データの計算
      // @ts-ignore
      const _staffEvaluationsRankingTotalPoint = rankingTotalPointByRate(_staffEvaluations.data.staffEvaluations)
      await fetchStaffEvaluations(
        searchHeadquarterId,
        searchDepartmentId,
        searchRate,
        searchEvaluationYear,
        searchEvaluationDate,
        evaluationBeginDate,
        evaluationEndDate,
      ).then(data => {
        // @ts-ignore
        const _staffEvaluations = data.data.staffEvaluations
        // @ts-ignore
        const _doneStaffEvaluations = data.data.doneStaffEvaluations
        // @ts-ignore
        _staffEvaluations.forEach((staffEvaluation, index) => {
          // 絞り込み検索したデータに評価データをマッピング
          _staffEvaluations[index] = _staffEvaluationsRankingTotalPoint.find(item => item.user_id === staffEvaluation.user_id)
        })
        // @ts-ignore
        _doneStaffEvaluations.forEach((staffEvaluation, index) => {
          // 絞り込み検索したデータに評価データをマッピング
          _doneStaffEvaluations[index] = _staffEvaluationsRankingTotalPoint.find(item => item.user_id === staffEvaluation.user_id)
        })
        // @ts-ignore
        setStaffEvaluations(_staffEvaluations)
        // @ts-ignore
        setDoneStaffEvaluations(_doneStaffEvaluations)
        // @ts-ignore
        setListTotalCount(data.data.total)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      })
    })
  }

  const _closeModal = () => {
    setVisibleFinalEvaluationModal(false)
    setVisibleFinalEvaluationCompareModal(false)
  }

  const _registerFinalEvaluation = async () => {
    setIsLoading(true)
    await registerFinalEvaluation(evaluationBeginDate, evaluationEndDate, staffEvaluations).then(e => {
      alert('最終評価完了しました。')
      setVisibleFinalEvaluationModal(false)
      // スタッフ最終評価再取得
      _fetchStaffEvaluations()
    })
  }

  const _visibleFinalEvaluationModal = () => {
    if (staffEvaluations.length === 0) {
      alert('該当データが存在しません。')
      return
    }
    if (!canFinalEvaluation) {
      alert('評価未評価のユーザーが存在します。')
      return
    }
    if (isFinalEvaluation) {
      alert('既に最終評価済みです。')
      return
    }
    setVisibleFinalEvaluationModal(true)
  }

  const _calculatorEvaluationPoint = staffEvaluations => {
    if (staffEvaluations) {
      // 評価対象者の人数をカウント
      let _staffEvaluationTotalCount = 0
      let _staffEvaluationPoint1TotalCount = 0
      let _staffEvaluationPoint2TotalCount = 0
      let _staffEvaluationPoint3TotalCount = 0
      let _staffEvaluationPoint4TotalCount = 0
      let _staffEvaluationPoint5TotalCount = 0
      staffEvaluations.forEach((staffEvaluation: any) => {
        _staffEvaluationTotalCount++
        if (staffEvaluation.evaluation_point === '1' || staffEvaluation.evaluation_point === 1) _staffEvaluationPoint1TotalCount++
        if (staffEvaluation.evaluation_point === '2' || staffEvaluation.evaluation_point === 2) _staffEvaluationPoint2TotalCount++
        if (staffEvaluation.evaluation_point === '3' || staffEvaluation.evaluation_point === 3) _staffEvaluationPoint3TotalCount++
        if (staffEvaluation.evaluation_point === '4' || staffEvaluation.evaluation_point === 4) _staffEvaluationPoint4TotalCount++
        if (staffEvaluation.evaluation_point === '5' || staffEvaluation.evaluation_point === 5) _staffEvaluationPoint5TotalCount++
      })
      const _staffEvaluationPoint1ParcentTotalCount = Math.round((_staffEvaluationPoint1TotalCount / _staffEvaluationTotalCount) * 100)
      const _staffEvaluationPoint2ParcentTotalCount = Math.round((_staffEvaluationPoint2TotalCount / _staffEvaluationTotalCount) * 100)
      const _staffEvaluationPoint3ParcentTotalCount = Math.round((_staffEvaluationPoint3TotalCount / _staffEvaluationTotalCount) * 100)
      const _staffEvaluationPoint4ParcentTotalCount = Math.round((_staffEvaluationPoint4TotalCount / _staffEvaluationTotalCount) * 100)
      const _staffEvaluationPoint5ParcentTotalCount = Math.round((_staffEvaluationPoint5TotalCount / _staffEvaluationTotalCount) * 100)
      setStaffEvaluationTotalCount(_staffEvaluationTotalCount)
      setStaffEvaluationPoint1TotalCount(_staffEvaluationPoint1TotalCount)
      setStaffEvaluationPoint1ParcentTotalCount(
        isNaN(_staffEvaluationPoint1ParcentTotalCount) ? 0 : _staffEvaluationPoint1ParcentTotalCount,
      )
      setStaffEvaluationPoint2TotalCount(_staffEvaluationPoint2TotalCount)
      setStaffEvaluationPoint2ParcentTotalCount(
        isNaN(_staffEvaluationPoint2ParcentTotalCount) ? 0 : _staffEvaluationPoint2ParcentTotalCount,
      )
      setStaffEvaluationPoint3TotalCount(_staffEvaluationPoint3TotalCount)
      setStaffEvaluationPoint3ParcentTotalCount(
        isNaN(_staffEvaluationPoint3ParcentTotalCount) ? 0 : _staffEvaluationPoint3ParcentTotalCount,
      )
      setStaffEvaluationPoint4TotalCount(_staffEvaluationPoint4TotalCount)
      setStaffEvaluationPoint4ParcentTotalCount(
        isNaN(_staffEvaluationPoint4ParcentTotalCount) ? 0 : _staffEvaluationPoint4ParcentTotalCount,
      )
      setStaffEvaluationPoint5TotalCount(_staffEvaluationPoint5TotalCount)
      setStaffEvaluationPoint5ParcentTotalCount(
        isNaN(_staffEvaluationPoint5ParcentTotalCount) ? 0 : _staffEvaluationPoint5ParcentTotalCount,
      )
    }
  }

  useEffect(() => {
    if (sortKey) {
      _sort()
    }
  }, [sortKey, sortType])

  const _csvDownload = () => {
    csvDownload(staffEvaluationsForCSV, 'final-staff-evaluations.csv')
  }

  useEffect(() => {
    const _searchEvaluationYear = searchEvaluationYear ? searchEvaluationYear : date.getFullYear()
    setSearchEvaluationDate(`${_searchEvaluationYear}${period}`)
    if (masterEvaluationDate) {
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === `${_searchEvaluationYear}${period}`)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }, [searchEvaluationYear])

  useEffect(() => {
    const _staffEvaluations = [...staffEvaluations]
    if (staffEvaluations.length && doneStaffEvaluations.length) {
      let _isFinalEvaluation = false
      _staffEvaluations.forEach((staffEvaluation, index) => {
        const _doneStaffEvaluation = doneStaffEvaluations.find(
          doneStaffEvaluation => doneStaffEvaluation?.user_id === staffEvaluation?.user_id,
        )
        // 評価済みか
        if (_doneStaffEvaluation?.isEvaluation) {
          _isFinalEvaluation = true
        }
        if (_doneStaffEvaluation?.evaluation_point) {
          _staffEvaluations[index].isEvaluation = true
        }
        if (!_doneStaffEvaluation?.evaluation_point) {
          _staffEvaluations[index].isEvaluation = false
        }
      })
      setStaffEvaluations(_staffEvaluations)
      setIsFinalEvaluation(_isFinalEvaluation)
    }
  }, [doneStaffEvaluations])

  useEffect(() => {
    if (staffEvaluations) {
      _calculatorEvaluationPoint(staffEvaluations)
      let _canFinalEvaluation = true
      const csvHeader = [
        '本部',
        '部署',
        '等級',
        'スタッフ名',
        'プロジェクト数',
        'プロジェクト数(評価済み)',
        'プロジェクト数(未登録)',
        '個人タスク合計',
        '個人タスク平均',
        '貢献度合計',
        '貢献度平均',
        '合計点数',
        '合計平均',
        '倍率加算前平均',
        '倍率加算前平均×PJ数',
        '順位評価（同等級）',
        '順位評価（前後等級）',
        '偏差値評価（偏差値）',
        '偏差値評価（同等級）',
        '偏差値評価（偏差値）',
        '偏差値評価（前後等級）',
        '最終評価',
      ]
      const csvData = staffEvaluations.map((staffEvaluation: any) => {
        // 最終評価判定
        if (parseInt(staffEvaluation.project_count_nonRegistered)) {
          _canFinalEvaluation = false
        }
        return [
          staffEvaluation.headquarter_name,
          staffEvaluation.department_name,
          staffEvaluation.rate,
          `${staffEvaluation.last_name} ${staffEvaluation.first_name}`,
          staffEvaluation.project_count,
          staffEvaluation.project_count_registered,
          staffEvaluation.project_count_nonRegistered,
          staffEvaluation.task_point_total_csv,
          staffEvaluation.task_point_total_avg_csv,
          staffEvaluation.contribution_point_total_csv,
          staffEvaluation.contribution_point_total_avg_csv,
          staffEvaluation.total_point_csv,
          staffEvaluation.total_point_avg_csv,
          staffEvaluation.averagebeforeMagnificationAddition,
          staffEvaluation.averagebeforeMagnificationAdditionPj,
          staffEvaluation.rankingEvaluationEquivalentGrade,
          staffEvaluation.rankingEvaluationBeforeAndAfterGrade,
          staffEvaluation.deviationValueEvaluationEquivalentGrade,
          staffEvaluation.deviationValueEvaluationEquivalentGradePoint,
          staffEvaluation.deviationValueEvaluationBeforeAndAfterGrade,
          staffEvaluation.deviationValueEvaluationBeforeAndAfterGradePoint,
          staffEvaluation.evaluation_point,
        ]
      })
      setCanFinalEvaluation(_canFinalEvaluation)
      setStaffEvaluationsForCSV([csvHeader, ...csvData])
    }
  }, [staffEvaluations])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      // 6等級は所属本部のみ
      if (auth.rate === 6) {
        setSearchHeadquarterId(auth.headquarter_id)
      }
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected);
  };

  const displayedStaffEvaluations = staffEvaluations.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="final-evaluation" />
        <div css={containerStyle}>
          <PageTitle title={'最終評価'} />
          <div css={mainTopContentsStyle}>
            <StaffEvaluationSearch
              searchHeadquarterId={searchHeadquarterId}
              searchDepartmentId={searchDepartmentId}
              searchRate={searchRate}
              searchEvaluationYear={searchEvaluationYear}
              searchEvaluationDate={searchEvaluationDate}
              handleChange={handleChange}
              onSearch={_onSearch}
              onReset={_onReset}
            />
            <div css={searchSideButtonWrapperStyle}>
              <ExportButton onClick={_csvDownload} />
              <CompareButton onClick={() => _visibleFinalEvaluationModal()} />
              <OutlineButton onClick={() => setVisibleFinalEvaluationCompareModal(true)} />
            </div>
          </div>
          <div css={mainContentsStyle}>
            <ListPageCount pageCount={listTotalCount} />
            {staffEvaluations.length !== 0 && (<ReactPaginate
              pageCount={Math.ceil(staffEvaluations.length / itemsPerPage)} // ページ数
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-center" // ul(pagination本体)
              pageClassName="page-item" // li
              pageLinkClassName="page-link rounded-full" // a
              activeClassName="page-item-active" // active.li
              activeLinkClassName="page-item-active" // active.li < a

              // 戻る・進む関連
              previousClassName="previous-next-item" // li
              nextClassName="previous-next-item" // li
              previousLabel={'<'} // a
              previousLinkClassName="previous-link"
              nextLabel={'>'} // a
              nextLinkClassName="next-link"

              // 先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくする
              disabledClassName="disabled-button d-none"

              // 中間ページの省略表記関連
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />)}
            <StaffEvaluationList
              staffEvaluations={displayedStaffEvaluations}
              sort={_sortSetting}
              sortKey={sortKey}
              sortType={sortType}
              handleLastEvaluationChange={handleLastEvaluationChange}
            />
          </div>
          {isLoading && (
            <div css={loadingOverlayStyle}>
              <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
            </div>
          )}
          {visibleFinalEvaluationModal && (
            <div css={loadingOverlayStyle}>
              <div css={modalStyle}>
                <div css={modalTitleStyle}>最終評価登録</div>
                <div css={modalTextStyle}>
                  <span>
                    {searchEvaluationYearLabel}年度（{searchEvaluationDateLabel}）
                  </span>
                  の最終評価登録を行います※登録後の修正はシステム管理者へご連絡ください
                </div>
                <div css={modalButtonWrapperStyle}>
                  <button css={modalCancelButtonStyle} onClick={() => setVisibleFinalEvaluationModal(false)}>
                    やり直す
                  </button>
                  <button css={modalRegisterButtonStyle} onClick={() => _registerFinalEvaluation()}>
                    登録
                  </button>
                </div>
              </div>
            </div>
          )}
          {visibleFinalEvaluationCompareModal && (
            <div css={loadingOverlayStyle}>
              <div css={modalStyle}>
                <div css={modalCloseStyle} onClick={() => _closeModal()}>
                  <img src={require('@/static/images/close.svg')} />
                </div>
                <div css={modalTitleStyle}>
                  {searchEvaluationYearLabel}年度（{searchEvaluationDateLabel}）　評価比率
                </div>
                <div css={modalTextStyle}>
                  <ul css={finalEvaluationGuideStyle}>
                    <li css={finalEvaluationGuideLabelStyle}>最終評価</li>
                    <li css={finalEvaluationGuideStyleList}>
                      <div css={finalEvaluationGuideColor1Style} />
                      <div css={finalEvaluationGuidePointStyle}>1</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>{staffEvaluationPoint1ParcentTotalCount}％</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>
                        ({staffEvaluationPoint1TotalCount}/{staffEvaluationTotalCount})
                      </div>
                    </li>
                    <li css={finalEvaluationGuideStyleList}>
                      <div css={finalEvaluationGuideColor2Style} />
                      <div css={finalEvaluationGuidePointStyle}>2</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>{staffEvaluationPoint2ParcentTotalCount}％</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>
                        ({staffEvaluationPoint2TotalCount}/{staffEvaluationTotalCount})
                      </div>
                    </li>
                    <li css={finalEvaluationGuideStyleList}>
                      <div css={finalEvaluationGuideColor3Style} />
                      <div css={finalEvaluationGuidePointStyle}>3</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>{staffEvaluationPoint3ParcentTotalCount}％</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>
                        ({staffEvaluationPoint3TotalCount}/{staffEvaluationTotalCount})
                      </div>
                    </li>
                    <li css={finalEvaluationGuideStyleList}>
                      <div css={finalEvaluationGuideColor4Style} />
                      <div css={finalEvaluationGuidePointStyle}>4</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>{staffEvaluationPoint4ParcentTotalCount}％</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>
                        ({staffEvaluationPoint4TotalCount}/{staffEvaluationTotalCount})
                      </div>
                    </li>
                    <li css={finalEvaluationGuideStyleList}>
                      <div css={finalEvaluationGuideColor5Style} />
                      <div css={finalEvaluationGuidePointStyle}>5</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>{staffEvaluationPoint5ParcentTotalCount}％</div>
                      <div css={finalEvaluationGuidePointStyleParcent}>
                        ({staffEvaluationPoint5TotalCount}/{staffEvaluationTotalCount})
                      </div>
                    </li>
                  </ul>
                  <div css={finalEvaluationGuideLineWrapperStyle}>
                    <ul css={finalEvaluationGuideLineStyle}>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <ul css={finalEvaluationPointStyle}>
                      <li css={[finalEvaluationGuideColor1Style, { width: `${staffEvaluationPoint1ParcentTotalCount}%` }]}>
                        {/* {staffEvaluationPoint1ParcentTotalCount}％ ({staffEvaluationPoint1TotalCount}/{staffEvaluationTotalCount}) */}
                      </li>
                      <li css={[finalEvaluationGuideColor2Style, { width: `${staffEvaluationPoint2ParcentTotalCount}%` }]}>
                        {/* {staffEvaluationPoint2ParcentTotalCount}％ ({staffEvaluationPoint2TotalCount}/{staffEvaluationTotalCount}) */}
                      </li>
                      <li css={[finalEvaluationGuideColor3Style, { width: `${staffEvaluationPoint3ParcentTotalCount}%` }]}>
                        {/* {staffEvaluationPoint3ParcentTotalCount}％ ({staffEvaluationPoint3TotalCount}/{staffEvaluationTotalCount}) */}
                      </li>
                      <li css={[finalEvaluationGuideColor4Style, { width: `${staffEvaluationPoint4ParcentTotalCount}%` }]}>
                        {/* {staffEvaluationPoint4ParcentTotalCount}％ ({staffEvaluationPoint4TotalCount}/{staffEvaluationTotalCount}) */}
                      </li>
                      <li css={[finalEvaluationGuideColor5Style, { width: `${staffEvaluationPoint5ParcentTotalCount}%` }]}>
                        {/* {staffEvaluationPoint5ParcentTotalCount}％ ({staffEvaluationPoint5TotalCount}/{staffEvaluationTotalCount}) */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading && (
        <div css={loadingOverlayStyle}>
          <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
        </div>
      )}
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const searchSideButtonWrapperStyle = css({
  '.field': {
    marginBottom: '10px',
  },
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: 1000,
})

const modalStyle = css({
  width: '600px',
  position: 'fixed',
  top: '30%',
  margin: '0 auto',
  backgroundColor: '#fff',
  borderRadius: '20px',
  overflow: 'hidden',
  border: '1px solid #004CAD',
})

const modalCloseStyle = css({
  position: 'absolute',
  top: '20px',
  right: '20px',
  zIndex: 100,
  cursor: 'pointer',
  '&:hover': {
    opacity: '0.8',
  },
})

const modalTitleStyle = css({
  backgroundColor: '#004CAD',
  color: '#fff',
  padding: '20px',
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
})

const modalTextStyle = css({
  padding: '20px',
  fontSize: '16px',
  lineHeight: '32px',
  color: '#151619',
  textAlign: 'center',
  marginTop: '20px',
  marginBottom: '10px',
  '& span': {
    fontSize: '20px',
    fontWeight: 'bold',
  },
})

const modalButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 20px',
  marginBottom: '40px',
})

const modalCancelButtonStyle = css({
  border: '1px solid #004CAD',
  backgroundColor: '#ffffff',
  color: '#004CAD',
  padding: '10px',
  borderRadius: '20px',
  width: '46%',
  cursor: 'pointer',
})

const modalRegisterButtonStyle = css({
  border: 'none',
  backgroundColor: '#004CAD',
  color: '#fff',
  padding: '10px',
  borderRadius: '20px',
  width: '46%',
  cursor: 'pointer',
})

const finalEvaluationGuideStyle = css({
  display: 'flex',
  margin: '0 auto',
  width: '90%',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  alignItems: 'center',
})

const finalEvaluationGuideLabelStyle = css({
  marginRight: '20px',
})

const finalEvaluationGuideColorStyle = css({
  margin: '0 10px 0 0px',
  width: '20px',
  height: '20px',
})

const finalEvaluationGuideColor1Style = css(finalEvaluationGuideColorStyle, {
  backgroundColor: '#DAE3F3',
})

const finalEvaluationGuideColor2Style = css(finalEvaluationGuideColorStyle, {
  backgroundColor: '#B4C7E7',
})

const finalEvaluationGuideColor3Style = css(finalEvaluationGuideColorStyle, {
  backgroundColor: '#6094DE',
  color: '#FFF',
})

const finalEvaluationGuideColor4Style = css(finalEvaluationGuideColorStyle, {
  backgroundColor: '#4472C4',
  color: '#FFF',
})

const finalEvaluationGuideColor5Style = css(finalEvaluationGuideColorStyle, {
  backgroundColor: '#2F5597',
  color: '#FFF',
})

const finalEvaluationGuidePointStyle = css({})

const finalEvaluationGuideLineWrapperStyle = css({
  margin: '30px auto 10px',
  position: 'relative',
  height: '160px',
  display: 'flex',
  alignItems: 'center',
})

const finalEvaluationGuideLineStyle = css({
  position: 'absolute',
  left: 0,
  top: 0,
  border: '1px solid #333',
  width: '100%',
  height: '160px',
  display: 'flex',
  li: {
    width: '20%',
    height: '160px',
    border: '1px solid #333',
    borderLeft: 'none',
    borderTop: 'none',
    borderBottom: 'none',
    ':last-child': {
      borderRight: 'none',
    },
  },
})

const finalEvaluationPointStyle = css({
  display: 'flex',
  position: 'absolute',
  width: 'calc(100% - 1px)',
  marginLeft: '1px',
  li: {
    margin: 0,
    lineHeight: '18px',
    height: '60px',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
})

const finalEvaluationGuideStyleList = css({
  width: '20%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const finalEvaluationGuidePointStyleParcent = css({
  width: '100%',
  height: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
