import React from 'react'
import { css } from '@emotion/core'

type CardSelectProps = {
  label?: string
  width?: string
  isResponsible?: boolean
  data?: any
  value?: string
  validation?: string
  onChange?: (e) => void
}

export const CardHeadquartersSelectField: React.FC<CardSelectProps> = ({
  label = '',
  width = '195px',
  isResponsible = false,
  data = [],
  value,
  validation,
  onChange,
}) => {
  return (
    <div css={[wrapperStyle, { width }]} className={'field'}>
      {label && (
        <label css={labelStyle} htmlFor={label}>
          {label}
        </label>
      )}
      <select
        css={validation ? selectErrorStyle : selectStyle}
        id={label}
        name={isResponsible ? 'responsibleHeadquarterId' : 'headquarterId'}
        onChange={onChange}
        value={value}
      >
        <option>選択</option>
        {data.map((item: any) => (
          <option key={item.headquarter_id} value={item.headquarter_id}>
            {item.alias_name}
          </option>
        ))}
      </select>
    </div>
  )
}

const wrapperStyle = css({})

const labelStyle = css({
  width: '100%',
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#004CAD',
})

const selectStyle = css({
  appearance: 'none',
  width: '100%',
  border: 'none',
  borderRadius: '6px',
  backgroundColor: '#EDF2F9',
  color: '#151619',
  fontSize: '12px',
  margin: '6px 0 0',
  padding: '14px 30px 14px 22px',
  backgroundImage: `url(${require('@/static/images/arrow_down.svg')})`,
  backgroundPosition: 'right 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const selectErrorStyle = css(selectStyle, {
  width: '100%',
  border: '1px solid #FF0000',
})
