import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { CardHeadquartersSelectField } from '@/Components/Advanced/cardHeadquartersSelectField'
import { CardDepartmentsSelectField } from '@/Components/Advanced/cardDepartmentsSelectField'
import { CardPositionsSelectField } from '@/Components/Advanced/cardPositionsSelectField'
import { CardUsersSelectField } from '@/Components/Advanced/cardUsersSelectField'

// contexts
import { MasterContext } from '@/Contexts/master'

// libs
import { accountLevel } from '@/libs/account-level'

type Props = {
  masterHeadquarters?: any
  masterDepartments?: any
  headquarterId?: string
  departmentId?: string
  userId?: string
  positionId?: string
  responsibleHeadquarterId?: string
  responsibleDepartmentId?: string
  responsibleUserId?: string
  responsiblePositionId?: string
  managerSplitActive?: boolean
  users?: any
  positions?: any
  validationError?: any
  excludeUserIds: string[]
  handleChange?: (e) => void
  setManagerSplitActive?: (e) => void
}

export const ManagerRegisterList: React.FC<Props> = ({
  headquarterId,
  departmentId,
  userId,
  positionId,
  responsibleHeadquarterId,
  responsibleDepartmentId,
  responsibleUserId,
  responsiblePositionId,
  managerSplitActive,
  validationError,
  excludeUserIds,
  handleChange,
  setManagerSplitActive,
}) => {
  const { masterHeadquarters, masterDepartments, masterUsers, masterPositions } = useContext<any>(MasterContext)

  //ユーザーIDからrateを取得
  const _accountLevel = userId => {
    if (masterUsers.length === 0) {
      return 1
    }
    const rate = masterUsers.find(user => user.user_id === userId)?.rate
    return accountLevel(rate ? rate : 1)
  }

  return (
    <div css={mainListContainerStyle}>
      <ul css={mainListHeaderStyle}>
        <li css={list1Style}>本部</li>
        <li css={list2Style}>部署</li>
        <li css={list3Style}>スタッフ名</li>
        <li css={list4Style}>役割</li>
      </ul>
      <ul css={mainListContentsStyle}>
        <li>
          <div css={listStyle}>
            {managerSplitActive && <div css={listTitleStyle}>評価者</div>}
            <div css={[listItemStyle, list1Style]}>
              <CardHeadquartersSelectField
                width={'200px'}
                data={masterHeadquarters}
                onChange={handleChange}
                value={headquarterId}
                validation={validationError.evaluator}
              />
            </div>
            <div css={[listItemStyle, list2Style]}>
              <CardDepartmentsSelectField
                width={'320px'}
                data={masterDepartments}
                headquarterId={headquarterId}
                onChange={handleChange}
                value={departmentId}
                validation={validationError.evaluator}
              />
            </div>
            <div css={[listItemStyle, list3Style]}>
              <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(userId)}.svg`)} />
              <CardUsersSelectField
                width={'300px'}
                data={masterUsers}
                isManager={true}
                onChange={handleChange}
                departmentId={departmentId}
                value={userId}
                validation={validationError.evaluator}
                excludeUserIds={excludeUserIds}
              />
            </div>
            <div css={[listItemStyle, list4Style]}>
              <CardPositionsSelectField
                width={'200px'}
                data={masterPositions}
                onChange={handleChange}
                value={positionId}
                validation={validationError.evaluator}
              />
            </div>
          </div>
          <span css={validationErrorMessageStyle}>{validationError.evaluator}</span>
        </li>
        {managerSplitActive && (
          <li>
            <div css={listStyle}>
              <div css={listTitleStyle}>プロジェクト責任者</div>
              <div css={[listItemStyle, list1Style]}>
                <CardHeadquartersSelectField
                  width={'200px'}
                  isResponsible={true}
                  data={masterHeadquarters}
                  onChange={handleChange}
                  value={responsibleHeadquarterId}
                  validation={validationError.manager}
                />
              </div>
              <div css={[listItemStyle, list2Style]}>
                <CardDepartmentsSelectField
                  width={'320px'}
                  isResponsible={true}
                  data={masterDepartments}
                  headquarterId={responsibleHeadquarterId}
                  onChange={handleChange}
                  value={responsibleDepartmentId}
                  validation={validationError.manager}
                />
              </div>
              <div css={[listItemStyle, list3Style]}>
                <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(responsibleUserId)}.svg`)} />
                <CardUsersSelectField
                  width={'300px'}
                  isResponsible={true}
                  data={masterUsers}
                  onChange={handleChange}
                  departmentId={responsibleDepartmentId}
                  value={responsibleUserId}
                  validation={validationError.manager}
                  excludeUserIds={excludeUserIds}
                />
              </div>
              <div css={[listItemStyle, list4Style]}>
                <CardPositionsSelectField
                  width={'200px'}
                  isResponsible={true}
                  data={masterPositions}
                  onChange={handleChange}
                  value={responsiblePositionId}
                  validation={validationError.manager}
                />
              </div>
            </div>
            <span css={validationErrorMessageStyle}>{validationError.manager}</span>
          </li>
        )}
      </ul>
      <div css={managerSplitWrapperStyle}>
        <img
          css={checkboxIconStyle}
          src={require(managerSplitActive ? '@/static/images/checkbox_check.svg' : '@/static/images/checkbox.svg')}
          onClick={setManagerSplitActive}
        />
        <div css={managerSplitTextStyle} onClick={setManagerSplitActive}>
          評価者と責任者が異なる場合、選択してください
        </div>
      </div>
    </div>
  )
}

const mainListContainerStyle = css({
  marginBottom: '50px',
  width: 'calc(100vw - 220px - 68px)',
  height: 'auto',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  height: 'auto',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  li: {
    borderBottom: '1px solid #DDDDDD',
    padding: '20px 16px',
  },
})

const listStyle = css({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  fontSize: '13px',
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const listTitleStyle = css({
  width: '160px',
})

const list1Style = css({
  width: '200px',
})

const list2Style = css({
  width: '320px',
})

const list3Style = css({
  width: '280px',
})

const list4Style = css({
  width: '200px',
})

const managerSplitWrapperStyle = css({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  padding: '20px 16px',
  color: '#757575',
})

const managerSplitTextStyle = css({
  cursor: 'pointer',
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})

const checkboxIconStyle = css({
  width: '24px',
  marginRight: '10px',
  cursor: 'pointer',
})

const validationErrorMessageStyle = css({
  color: '#FF0000',
})
