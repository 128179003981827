import React from 'react'
import { css } from '@emotion/core'

type OutlineButtonProps = {
  onClick?: () => void
}

export const OutlineButton: React.FC<OutlineButtonProps> = ({ onClick }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <button onClick={onClick} css={buttonStyle}>
        評価比率
      </button>
    </div>
  )
}

const wrapperStyle = css({})

const buttonStyle = css({
  width: '75px',
  border: '1px solid #004CAD',
  outoline: 'none',
  appearance: 'none',
  whiteSpace: 'nowrap',
  padding: '9px 13px',
  color: '#004CAD',
  borderRadius: '22px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  '&:hover': {
    opacity: 0.8,
  },
})
