import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { CardCalenderField } from '@/Components/Advanced/cardCalenderField'
import { CardHeadquartersSelectField } from '@/Components/Advanced/cardHeadquartersSelectField'
import { CardDepartmentsSelectField } from '@/Components/Advanced/cardDepartmentsSelectField'
import { CardPositionsSelectField } from '@/Components/Advanced/cardPositionsSelectField'
import { CardUsersSelectField } from '@/Components/Advanced/cardUsersSelectField'

// contexts
import { MasterContext } from '@/Contexts/master'

// libs
import { accountLevel } from '@/libs/account-level'

type Props = {
  member: {
    user_id: string
    last_name: string
    first_name: string
    headquarter_id: string
    department_id: string
    headquarter_name: string
    department_name: string
    position_id: string
    begin_date: string
    end_date: string
    rate: number
    role: string
  }
  index: number
  minDate: string
  maxDate: string
  validationError: string
  excludeUserIds: string[]
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void
  changeRangeDate: (begin_date: string, end_date: string, index: number) => void
  removeMembers: (index: number) => void
}

export const StaffRegisterListContent: React.FC<Props> = ({
  member,
  index,
  minDate,
  maxDate,
  handleChange,
  changeRangeDate,
  removeMembers,
  excludeUserIds = [],
  validationError = '',
}) => {
  const { masterHeadquarters, masterDepartments, masterUsers, masterPositions } = useContext<any>(MasterContext)

  const _changeRangeDate = (beginDate, endDate) => {
    changeRangeDate(beginDate, endDate, index)
  }

  //ユーザーIDからrateを取得
  const _accountLevel = userId => {
    if (masterUsers.length === 0) {
      return 1
    }
    const rate = masterUsers.find(user => user.user_id === userId)?.rate
    return accountLevel(rate ? rate : 1)
  }

  return (
    <li className="list" key={index}>
      <div css={listItemWrapperStyle}>
        <div css={[listItemStyle, list1Style]}>
          {member.role !== 'other' &&
            masterHeadquarters.find(headquarter => headquarter.headquarter_id === member.headquarter_id)?.alias_name}
          {member.role === 'other' && (
            <CardHeadquartersSelectField
              width={'120px'}
              data={masterHeadquarters}
              onChange={e => handleChange(e, index)}
              value={member.headquarter_id}
              validation={validationError}
            />
          )}
        </div>
        <div css={[listItemStyle, list2Style]}>
          {member.role !== 'other' && masterDepartments.find(department => department.department_id === member.department_id)?.alias_name}
          {member.role === 'other' && (
            <CardDepartmentsSelectField
              width={'170px'}
              data={masterDepartments}
              headquarterId={member.headquarter_id}
              onChange={e => handleChange(e, index)}
              value={member.department_id}
              validation={validationError}
            />
          )}
        </div>
        <div css={[listItemStyle, list3Style]}>
          <img css={accountIconStyle} src={require(`@/static/images/account_icon_level${_accountLevel(member.user_id)}.svg`)} />
          {member.role !== 'other' &&
            `${masterUsers.find(user => user.user_id === member.user_id)?.last_name}　${
              masterUsers.find(user => user.user_id === member.user_id)?.first_name
            }`}
          {member.role === 'other' && (
            <CardUsersSelectField
              width={'300px'}
              data={masterUsers}
              onChange={e => handleChange(e, index)}
              departmentId={member.department_id}
              value={member.user_id}
              validation={validationError}
              excludeUserIds={excludeUserIds}
            />
          )}
        </div>
        <div css={[listItemStyle, list4Style]}>
          {member.role !== 'other' && masterPositions.find(position => position.position_id === member.position_id)?.name}
          {member.role === 'other' && (
            <CardPositionsSelectField
              width={'200px'}
              data={masterPositions}
              onChange={e => handleChange(e, index)}
              value={member.position_id}
              validation={validationError}
            />
          )}
        </div>
        <div css={[listItemStyle, list5Style]}>
          {member.role !== 'other' && `${member.begin_date}　〜　${member.end_date}`}
          {member.role === 'other' && (
            <CardCalenderField
              minDate={minDate}
              maxDate={maxDate}
              beginDate={member.begin_date}
              endDate={member.end_date}
              changeRangeDate={_changeRangeDate}
              validation={validationError}
            />
          )}
        </div>
        {member.role === 'other' && (
          <div css={[listItemStyle, list6Style]} onClick={() => removeMembers(index)}>
            <img css={removeIconStyle} src={require('@/static/images/remove.svg')} />
          </div>
        )}
      </div>
      <span css={validationErrorMessageStyle}>{validationError}</span>
    </li>
  )
}

const listItemWrapperStyle = css({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  fontSize: '13px',
})

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const list1Style = css({
  width: '120px',
})

const list2Style = css({
  width: '170px',
})

const list3Style = css({
  width: '200px',
})

const list4Style = css({
  width: '150px',
})

const list5Style = css({
  width: '350px',
  overflow: 'initial',
})

const accountIconStyle = css({
  width: '32px',
  marginRight: '6px',
})

const list6Style = css({
  width: '60px',
  justifyContent: 'center',
})

const removeIconStyle = css({
  width: '24px',
  '&:hover': {
    opacity: 0.6,
  },
})

const validationErrorMessageStyle = css({
  color: '#FF0000',
})
