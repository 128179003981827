import React from 'react'
import { css } from '@emotion/core'
import { CSVReader } from '@/Components/Advanced/csv-reader'

type UploadButtonProps = {
  onFileLoad?: (data: any) => void
}

const parseOptions = {
  header: true,
  skipEmptyLines: true,
}

export const UploadButton: React.FC<UploadButtonProps> = ({ onFileLoad }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <button css={buttonStyle}>
        <CSVReader
          inputStyle={{ display: 'none', cursor: 'pointer' }}
          label={'データのアップロード'}
          parserOptions={parseOptions}
          onFileLoaded={data => {
            onFileLoad ? onFileLoad(data) : null
          }}
        />
      </button>
    </div>
  )
}

const wrapperStyle = css({})

const buttonStyle = css({
  appearance: 'none',
  whiteSpace: 'nowrap',
  border: 'none',
  padding: '9px 13px 9px 38px',
  color: '#FFFFFF',
  backgroundColor: '#004CAD',
  borderRadius: '22px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundImage: `url(${require('@/static/images/upload.svg')})`,
  backgroundPosition: 'left 10px center',
  backgroundSize: '24px',
  backgroundRepeat: 'no-repeat',
  '&:hover': {
    opacity: 0.8,
  },
})
