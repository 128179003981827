import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { BackButton } from '@/Components/Advanced/backButton'
import { EvaluationCard } from '@/Components/Pages/StaffEvaluation/_StaffEvaluationDetail/evaluationCard'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { StaffEvaluationDetailSearch } from '@/Components/Pages/StaffEvaluation/_StaffEvaluationDetail/search'
import { StaffEvaluationDetailList } from '@/Components/Pages/StaffEvaluation/_StaffEvaluationDetail/list'
import ReactLoading from 'react-loading'

// apis
import { fetchStaffEvaluationDetail } from '@/Apis/scopeProjectRecorder'

// components
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

// libs
import { accountLevel } from '@/libs/account-level'

export const StaffEvaluationDetail: React.FC<{}> = () => {
  const date = new Date()
  const month = date.getMonth() + 1;
  if (month <= 3) {
    date.setFullYear(date.getFullYear() - 1);
  }
  let half = '';
  if (4 <= month && month <= 9) {
    half = 'FirstHalf';
  } else {
    half = 'SecondHalf';
  }
  const period = half;
  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationDate } = useContext<any>(MasterContext)
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const [staffEvaluation, setStaffEvaluation] = useState<any>()
  const [projects, setProjects] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [searchEvaluationYear, setSearchEvaluationYear] = useState<string>(
    query.searchEvaluationYear ? query.searchEvaluationYear : date.getFullYear(),
  )
  const [searchEvaluationDate, setSearchEvaluationDate] = useState<string>(
    query.searchEvaluationDate ? query.searchEvaluationDate : `${date.getFullYear()}${period}`,
  )
  const _masterEvaluationDate = masterEvaluationDate.find((_item, index) => _item.id === searchEvaluationDate);
  const [evaluationBeginDate, setEvaluationBeginDate] = useState<string>(_masterEvaluationDate.begin_date)
  const [evaluationEndDate, setEvaluationEndDate] = useState<string>(_masterEvaluationDate.end_date)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleChange = e => {
    if (e.target.name === 'evaluationYear') {
      setSearchEvaluationYear(e.target.value)
    }

    if (e.target.name === 'evaluationDate') {
      setSearchEvaluationDate(e.target.value)
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === e.target.value)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }

  const goToStaffEvaluation = () => {
    if (query.ref === 'staffEvaluation') {
      history.goBack()
    }
  }

  const _onSearch = () => {
    setIsLoading(true)
    _fetchStaffEvaluationDetail()
    history.replace({
      pathname: `/staff-evaluation/detail`,
      search: `?userId=${query.userId}&searchEvaluationYear=${searchEvaluationYear}&searchEvaluationDate=${searchEvaluationDate}&ref=${query.ref}`,
    })
  }

  const _accountLevel = rate => {
    return accountLevel(rate)
  }

  const _onReset = () => {
    // 評価年月日
    // 評価年月日(年度)
    setSearchEvaluationYear(`${date.getFullYear()}`)
    // 評価年月日(期間)
    setSearchEvaluationDate(`${date.getFullYear()}${period}`)
    // 期間を選択した際の開始日時
    setEvaluationBeginDate('')
    // 期間を選択した際の終了日時
    setEvaluationEndDate('')
    // データのリセット
    setProjects([])
    setListTotalCount(0)
  }

  const _fetchStaffEvaluationDetail = async () => {
    await fetchStaffEvaluationDetail(query.userId, searchEvaluationYear, searchEvaluationDate, evaluationBeginDate, evaluationEndDate).then(
      data => {
        // @ts-ignore
        const _staffEvaluation = data.data.staffEvaluation[0]

        setStaffEvaluation(_staffEvaluation)
        // @ts-ignore
        setProjects(data.data.projects)
        // @ts-ignore
        setListTotalCount(data.data.total)
        setIsLoading(false)
        if (!staffEvaluation) {
          return
        }
        // staffEvaluationのuser_idがnullの場合は情報セットできていない
        if (!staffEvaluation?.user_id) {
          return
        }
        // 6等級未満で自分以外の場合はリダイレクト
        if (auth.rate <= 5 && staffEvaluation?.user_id !== auth.user_id) {
          history.push({
            pathname: `/`,
          })
        }
        // 6等級未満で自分の部署以外の場合はリダイレクト
        if (auth.rate === 6 && auth.headquarter_id !== _staffEvaluation.headquarter_id) {
          history.push({
            pathname: `/staff-evaluation`,
          })
        }
      },
    )
  }

  useEffect(() => {
    const _searchEvaluationYear = searchEvaluationYear ? searchEvaluationYear : date.getFullYear()
    setSearchEvaluationDate(`${_searchEvaluationYear}${period}`)
    if (masterEvaluationDate) {
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === `${_searchEvaluationYear}${period}`)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }, [searchEvaluationYear])

  useEffect(() => {
    setIsLoading(true)
    // ログイン済みか判定する
    if (auth) {
      _fetchStaffEvaluationDetail()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="staff-evaluation" />
        <div css={containerStyle}>
          <PageTitle title={'スタッフ評価詳細'} />
          <div css={mainContentsStyle}>
            {auth && auth.rate >= 4 && (
              <div css={topButtonWrapperStyle}>
                <BackButton onClick={goToStaffEvaluation} />
              </div>
            )}
            <div css={nameRowStyle}>
              <div css={nameWrapperStyle}>
                {staffEvaluation && staffEvaluation.last_name && (
                  <img
                    css={accountIconStyle}
                    src={require(`@/static/images/account_icon_level${_accountLevel(staffEvaluation.rate)}.svg`)}
                  />
                )}
                <div css={nameStyle}>
                  {staffEvaluation && staffEvaluation.last_name
                    ? `${staffEvaluation.last_name} ${staffEvaluation.first_name}さんの評価詳細`
                    : '-'}
                </div>
              </div>
            </div>
            <ul css={departmentRowStyle}>
              <li>
                <span>本部：</span>
                {staffEvaluation?.headquarter_name ? staffEvaluation.headquarter_name : '-'}
              </li>
              <li>
                <span>部署：</span>
                {staffEvaluation?.department_name ? staffEvaluation.department_name : '-'}
              </li>
              <li>
                <span>等級：</span>
                {staffEvaluation?.rate ? staffEvaluation.rate : '-'}
              </li>
            </ul>
            <StaffEvaluationDetailSearch
              searchEvaluationYear={searchEvaluationYear}
              searchEvaluationDate={searchEvaluationDate}
              handleChange={handleChange}
              onSearch={_onSearch}
              onReset={_onReset}
            />
            <EvaluationCard staffEvaluation={staffEvaluation} />
            <ListPageCount pageCount={listTotalCount} />
            <StaffEvaluationDetailList projects={projects} />
            {isLoading && (
              <div css={loadingOverlayStyle}>
                <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '24px 34px',
  backgroundColor: '#EDF2F9',
})

const topButtonWrapperStyle = css({
  marginBottom: '26px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const nameRowStyle = css({
  marginBottom: '24px',
})

const nameWrapperStyle = css({
  display: 'flex',
})

const nameStyle = css({
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '40px',
})

const accountIconStyle = css({
  marginRight: '10px',
  width: '42px',
})

const departmentRowStyle = css({
  margin: '0 0 39px 52px',
  display: 'flex',
  span: {
    fontWeight: 'bold',
  },
  li: {
    marginRight: '30px',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
