import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { SelectField } from '@/Components/Advanced/selectField'
import { ResetButton } from '@/Components/Advanced/resetButton'
import { RefineButton } from '@/Components/Advanced/refineButton'

import { MasterContext } from '@/Contexts/master'

type Props = {
  searchEvaluationYear: string
  searchEvaluationDate?: string
  handleChange?: (e) => void
  onSearch?: () => void
  onReset?: () => void
}

export const StaffEvaluationDetailSearch: React.FC<Props> = ({
  searchEvaluationYear,
  searchEvaluationDate,
  handleChange,
  onSearch,
  onReset,
}) => {
  const { masterEvaluationDate2022, masterEvaluationDate2023, masterEvaluationDate2024, masterEvaluationYear } = useContext<any>(MasterContext)
  let evaluationDate;
  if (searchEvaluationYear == '2022') {
    evaluationDate = masterEvaluationDate2022;
  } else if (searchEvaluationYear == '2023') {
    evaluationDate = masterEvaluationDate2023;
  } else if (searchEvaluationYear == '2024') {
    evaluationDate = masterEvaluationDate2024;
  }

  return (
    <div css={searchContainerStyle}>
      <div css={searchTitleStyle}>絞り込み</div>
      <div css={searchContentsStyle}>
        <div css={searchRowStyle}>
          <SelectField
            label={'評価対象期間'}
            data={masterEvaluationYear}
            name={'evaluationYear'}
            onChange={handleChange}
            value={searchEvaluationYear}
            isAllSelect={false}
          />
          <SelectField
            label={'　'}
            data={evaluationDate}
            name={'evaluationDate'}
            onChange={handleChange}
            value={searchEvaluationDate}
            isAllSelect={false}
          />
          <ResetButton onReset={onReset} />
          <RefineButton onSearch={onSearch} />
        </div>
      </div>
    </div>
  )
}

const searchContainerStyle = css({
  display: 'flex',
  '.field': {
    marginRight: '20px',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

const searchTitleStyle = css({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#151619',
  marginRight: '25px',
  whiteSpace: 'nowrap',
})

const searchContentsStyle = css({
  width: '680px',
})

const searchRowStyle = css({
  display: 'flex',
  marginBottom: '23px',
  '&:last-child': {
    marginBottom: 0,
  },
})
