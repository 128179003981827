import React from 'react'
import { css } from '@emotion/core'

type ListPageCountProps = {
  pageCount: number
}

export const ListPageCount: React.FC<ListPageCountProps> = ({ pageCount }) => {
  return <div css={listPageCountStyle}>全{pageCount}件を表示</div>
}

const listPageCountStyle = css({
  width: '100%',
  fontSize: '11px',
  color: '#757575',
  marginBottom: '14px',
})
