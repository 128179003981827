import React from 'react'
import { css } from '@emotion/core'

type EditButtonProps = {
  label?: string
  onClick?: () => void
}

export const EditButton: React.FC<EditButtonProps> = ({ label = 'プロジェクト編集', onClick }) => {
  return (
    <div css={wrapperStyle} className={'field'}>
      <button onClick={onClick} css={buttonStyle}>
        {label}
      </button>
    </div>
  )
}

const wrapperStyle = css({})

const buttonStyle = css({
  border: 'none',
  outoline: 'none',
  appearance: 'none',
  whiteSpace: 'nowrap',
  padding: '9px 13px 9px 30px',
  color: '#FFFFFF',
  borderRadius: '22px',
  fontSize: '12px',
  cursor: 'pointer',
  backgroundColor: '#757575',
  backgroundImage: `url(${require('@/static/images/edit.svg')})`,
  backgroundPosition: 'left 10px center',
  backgroundSize: '14px',
  backgroundRepeat: 'no-repeat',
  '&:hover': {
    opacity: 0.8,
  },
})
